import { memo } from 'react';
import { useTheme } from 'styled-components';
import { useLocale } from '../../../contexts/LocalizationContext';
import { MaskIcon } from '../../Icons/Icons';
import LoaderCircle from '../../LoaderDots/LoaderCircle';
import Button from '../../NewButton/Button';
import {
  NotifyMeContainer,
  NotifyMeText,
  PdpNotifyMeActiveButton,
} from '../PrimarySellerCardv3Styles';

interface NotifyMeBoxProps {
  notifyMeStatus: boolean;
  notifyMeLoading: boolean;
  handleClickNotifyButton: (action: 'add' | 'remove') => void;
}

function NotifyMeBox({
  notifyMeStatus,
  notifyMeLoading,
  handleClickNotifyButton,
}: NotifyMeBoxProps) {
  const theme = useTheme();
  const { messages } = useLocale();
  const {
    product_is_sold_msg,
    notify_me_when_available_msg,
    notify_me_msg,
    notification_on_msg,
  } = messages || {};

  return (
    <NotifyMeContainer>
      <h2>{product_is_sold_msg}</h2>
      {/* {isWishlisted ? (
      <NotifyMeText>{product_already_in_wishlist_msg}</NotifyMeText>
    ) : (
      <> */}
      <NotifyMeText>{notify_me_when_available_msg}</NotifyMeText>
      {notifyMeStatus ? (
        <PdpNotifyMeActiveButton
          aria-label="You will be notified"
          onClick={(e) => {
            e.preventDefault();
            handleClickNotifyButton('remove');
          }}
        >
          {notifyMeLoading ? (
            <LoaderCircle />
          ) : (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
              }}
            >
              {' '}
              <MaskIcon
                url="/icons/notifications-active.svg"
                width={'24px'}
                height="24px"
                color={'#000'}
                selected={true}
                margin="0 2px 0 0"
              />
              {notification_on_msg}
            </span>
          )}
        </PdpNotifyMeActiveButton>
      ) : (
        <Button
          buttonTheme="grey"
          buttonType="solid"
          onClick={(e) => {
            e.preventDefault();
            handleClickNotifyButton('add');
          }}
          ariaLabel="notify me"
          width="100%"
          height="48px"
        >
          {notifyMeLoading ? (
            <LoaderCircle />
          ) : (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
              }}
            >
              {' '}
              <MaskIcon
                url="/icons/notify-24.svg"
                width={'24px'}
                height="24px"
                color={theme.palette.text.t1}
                selected={true}
                margin="0"
              />{' '}
              {notify_me_msg}
            </span>
          )}
        </Button>
      )}
    </NotifyMeContainer>
  );
}

export default memo(NotifyMeBox);
