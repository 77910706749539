import React, { memo } from 'react';

import { ProductPills, ProductPillsContainer } from './ProductGenresStyles';

import Link from 'next/dist/client/link';
import { useRouter } from 'next/router';
import { convertToSlug } from '../../helper/helper';
import { saveEventV3 } from '../../utils/eventTracking';

interface ProductGenresPropsI {
  genres: string[];
}

const ProductGenres: React.FC<ProductGenresPropsI> = ({ genres }) => {
  const router = useRouter();
  return (
    <ProductPillsContainer>
      {genres.map((genre, index) => (
        <Link
          passHref
          href={`/store/genre/${convertToSlug(genre.toLowerCase())}`}
          key={index}
          prefetch={false}
        >
          <a>
            <ProductPills
              onClick={() => {
                saveEventV3({
                  category: 'pdp_info',
                  action: 'click',
                  label: 'product_description_category_pill',
                  properties: router.query.slug as string,
                  value: [`/store/genre/${convertToSlug(genre.toLowerCase())}`],
                  from: router,
                });
                // router.push(
                //   `/store/genre/${convertToSlug(genre.toLowerCase())}`
                // );
              }}
            >
              {genre}
            </ProductPills>
          </a>
        </Link>
      ))}
    </ProductPillsContainer>
  );
};

export default memo(ProductGenres);
