import React, { memo, useState } from 'react';
import { usePdpContext } from '../../../contexts/PdpPageContext';
import ProductImage from '../../ProductImage/ProductImage';

const ProductImagesMuiModal = dynamic(
  () => import('../../ProductImagesModal/ProductImagesMuiModal'),
  { ssr: false }
);

import dynamic from 'next/dynamic';
import ProductTitle from '../../ProductTitle/ProductTitle';
import {
  LineDivider,
  PDPHeadContainer,
  PDPHeadContent,
} from '../PDPPageStyles';
import NewProductImpDetails from './NewProductImpDetails/NewProductImpDetails';

const NewPDPHead: React.FC = () => {
  const { product } = usePdpContext();
  const [productImageModal, setProductImageModal] = useState<boolean>(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  return (
    <>
      <PDPHeadContainer>
        <ProductImage
          image={product.data.coverImage || product.data.coverImageOriginal}
          alt={product.data.name}
          productImageModal={productImageModal}
          setProductImageModal={setProductImageModal}
        />
        <PDPHeadContent>
          <ProductTitle />
          <LineDivider />
          <NewProductImpDetails />
        </PDPHeadContent>
      </PDPHeadContainer>
      {productImageModal && product.data.screenshots.length > 0 ? (
        <ProductImagesMuiModal
          product={product.data}
          videos={product.data.videos}
          images={product.data.screenshots}
          productImageModal={productImageModal}
          setProductImageModal={setProductImageModal}
          index={selectedImageIndex}
          setIndex={setSelectedImageIndex}
        />
      ) : null}
    </>
  );
};

export default memo(NewPDPHead);
