import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import {
  Header2Container,
  PreRelease,
  ProductTitleContainer,
  Title,
  VerticalDivider,
} from './ProductTitleStyle';

import { IconButton } from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { useTheme } from 'styled-components';
import { RequestObject } from '../../Interface/RequestInterface';
import { syncRequest } from '../../api/RestAPI';
import { apiConstants } from '../../constants/apiConstants';
import { COLORS } from '../../constants/colors';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useLocale } from '../../contexts/LocalizationContext';
import { usePdpContext } from '../../contexts/PdpPageContext';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { formatDateToLongFormat } from '../../helper/helper';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { openLoginModalRedux } from '../../redux/modalSlice';
import { GWMobileWrapper, GWWebWrapper } from '../../styles/window.style';
import { saveEventV3 } from '../../utils/eventTracking';
import { sendRecombeeBookmarkEvent } from '../../utils/recombee';
import { StartVibration } from '../../utils/vibrate';
import {
  WE_USER_EVENT_WISHLIST_ITEM_ADDED,
  WE_USER_EVENT_WISHLIST_ITEM_REMOVED,
} from '../../utils/we';
import { MaskIcon } from '../Icons/Icons';
import DigitalKeyTile from '../PDPPage/components/DigitalKeyTile/DigitalKeyTile';
import ProductRating from '../ProductRatings/ProductRatings';
import ProductTypeTag from '../ProductTypeTag/ProductTypeTag';
import TooltipWithArrow from '../ToolTipWithArrow/TooltipWithArrow';

const ProductTitle = () => {
  const router = useRouter();

  const { product } = usePdpContext();
  const theme = useTheme();

  const [showFullTitle, setShowFullTitle] = useState<boolean>(false);

  const { messages } = useLocale();
  const { something_went_wrong_msg, pre_order_msg } = messages || {};

  const { cleverTap } = useAnalytics();

  const { isWishlisted, setIsWishlisted } = usePdpContext();
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const { dispatchSnackbar } = useContext(SnackbarContext);
  const [wishlistLoading, setWishlistLoading] = useState<boolean>(false);

  const addWishlist = () => {
    const onSuccess = (response: any) => {
      setIsWishlisted(true);
      setWishlistLoading(false);
      StartVibration(200);
    };

    const onFailure = (err: any) => {
      setWishlistLoading(false);
      dispatchSnackbar({
        type: 'failure',
        payload: 'Error adding to wishlist',
      });
    };

    const postData = {
      pid: product.data.productId,
    };

    const config: AxiosRequestConfig = {
      withCredentials: true,
    };

    const requestObject: RequestObject = {
      method: 'POST',
      baseURL: apiConstants.server,
      path: '/private/wishlist/add',
      onSuccess: onSuccess,
      onError: onFailure,
      postData: postData,
      config: config,
      dispatch: dispatch,
      router: router,
    };

    syncRequest(requestObject);
  };

  const removeWishlist = () => {
    const onSuccess = (response: any) => {
      setIsWishlisted(false);
      setWishlistLoading(false);
    };

    const onFailure = (err: any) => {
      setWishlistLoading(false);
      dispatchSnackbar({
        type: 'failure',
        payload: something_went_wrong_msg ?? '',
      });
    };

    const postData = {
      pid: product.data.productId,
    };

    const config: AxiosRequestConfig = {
      withCredentials: true,
    };

    const requestObject: RequestObject = {
      method: 'POST',
      baseURL: apiConstants.server,
      path: '/private/wishlist/remove',
      onSuccess: onSuccess,
      onError: onFailure,
      postData: postData,
      config: config,
      dispatch: dispatch,
      router: router,
    };

    syncRequest(requestObject);
  };

  const wishlistClick = () => {
    setWishlistLoading(true);
    if (user) {
      if (isWishlisted) {
        removeWishlist();
        sendRecombeeBookmarkEvent(product.data.productSku, 'remove');
        WE_USER_EVENT_WISHLIST_ITEM_REMOVED(
          {
            categories: product.data.genres.toString(),
            imageUrl: product.data.coverImageOriginal,
            platform: product.data.platform,
            productId: product.data.productId.toString(),
            productName: product.data.name,
            productType: product.data.productTypes.toString(),
            productUrl: product.data.slug,
          },
          cleverTap
        );
        saveEventV3({
          category: 'pdp',
          action: 'click',
          label: 'wishlist_product',
          properties: 'wishlist_remove',
          value: [product.data.slug],
          from: router,
        });
      } else {
        addWishlist();
        sendRecombeeBookmarkEvent(product.data.productSku, 'add');
        WE_USER_EVENT_WISHLIST_ITEM_ADDED(
          {
            categories: product.data.genres.toString(),
            imageUrl: product.data.coverImageOriginal,
            platform: product.data.platform,
            productId: product.data.productId.toString(),
            productName: product.data.name,
            productType: product.data.productTypes.toString(),
            productUrl: product.data.slug,
          },
          cleverTap
        );
        saveEventV3({
          category: 'pdp',
          action: 'click',
          label: 'wishlist_product',
          properties: 'wishlist_add',
          value: [product.data.slug],
          from: router,
        });
      }
    } else {
      setWishlistLoading(false);
      dispatch(openLoginModalRedux());
    }
  };

  useEffect(() => {
    setIsWishlisted(isWishlisted);
  }, [isWishlisted]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ProductTitleContainer>
        <Title
          showFull={showFullTitle}
          onClick={() => setShowFullTitle((prev) => !prev)}
          title={product.data.name}
          message={product.data.name}
        >
          {product.data.name}
        </Title>
        <IconButton
          onClick={wishlistClick}
          aria-label="add-to-favorites"
          style={{ height: '40px', width: '40px' }}
          // wishlistLoading={wishlistLoading}
          // wishlisted={isWishlisted}
        >
          <MaskIcon
            url="/icons/heart.svg"
            height="20px"
            width="20px"
            selected={true}
            color={isWishlisted ? COLORS.red : '#8F8F8F'}
            margin="0"
          />
        </IconButton>
      </ProductTitleContainer>

      <GWWebWrapper>
        <Header2Container>
          <ProductTypeTag productTypes={product.data.productTypes} />
          <DigitalKeyTile fontSize={0.75} lineHeight={1} />
          {product.data.isPreReleased ? (
            <TooltipWithArrow title={<>{pre_order_msg}</>}>
              <PreRelease>
                <MaskIcon
                  width={'20px'}
                  height={'20px'}
                  selected
                  url="/icons/event_upcoming.svg"
                  margin="0"
                  color={theme.palette.warning.main}
                />
                <p>
                  Release date:{' '}
                  <span>
                    {formatDateToLongFormat(product.data.releaseDate)}
                  </span>
                </p>
              </PreRelease>
            </TooltipWithArrow>
          ) : null}
          <VerticalDivider />
          <ProductRating />
        </Header2Container>
      </GWWebWrapper>
      <GWMobileWrapper>
        <Header2Container>
          <ProductTypeTag
            productTypes={product.data.productTypes}
            fontSize={0.625}
            lineHeight={0.625}
          />
          <DigitalKeyTile fontSize={0.625} lineHeight={0.625} />
          {product.data.isPreReleased ? (
            <TooltipWithArrow title={<>{pre_order_msg}</>}>
              <PreRelease>
                <MaskIcon
                  width={'20px'}
                  height={'20px'}
                  selected
                  url="/icons/event_upcoming.svg"
                  margin="0"
                  color={theme.palette.warning.main}
                />
                <p>
                  Release date:{' '}
                  <span>
                    {formatDateToLongFormat(product.data.releaseDate)}
                  </span>
                </p>
              </PreRelease>
            </TooltipWithArrow>
          ) : null}
          <ProductRating />
        </Header2Container>
      </GWMobileWrapper>
    </div>
  );
};

export default ProductTitle;
