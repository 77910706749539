import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const BodyWrapper = styled.div`
  display: flex;
  background: ${(props) => props.theme.palette.background.bg2};
  flex-direction: column;
`;

export const BodyContainer = styled.div`
  display: flex;
  max-width: 1310px;
  margin: auto;
  background: ${(props) => props.theme.palette.background.bg2};
  width: 100%;
  padding: 22px 16px 40px;
  padding-left: 0px;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 0;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    // padding: 0 0 16px;
  }
`;

export const ProductFlexFirst = styled.div`
  /* flex: 5; */
  flex: 1;
  max-width: inherit;
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-flow: column;
  }
`;
export const SkeletonsContainer = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 25% 40% 25%;
  align-content: space-between;
  justify-content: space-between;
`;

export const MiddleSkeletonsContainer = styled.div`
  margin-top: 40px;
`;

export const PrimarySellerContainer = styled.div`
  // @media (max-width: ${WIDTH.tabletMax}) {
  //   display: none;
  // }
  position: sticky;
  top: 124px;
  margin-left: 0px;
  margin-bottom: 25px;
`;

export const SeeOtherOffers = styled.div`
  color: black;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 10px;
  text-decoration: underline;
`;

export const SelectTypeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  padding: 44px 0 24px;
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 16px;
  }
`;
interface SelectTypeOptionI {
  active?: boolean;
  isOos?: boolean;
}

export const SelectTypeOption = styled.div<SelectTypeOptionI>`
  display: flex;
  justify-content: space-between;
  background: ${(props) =>
    props.isOos
      ? props.theme.palette.background.bg1
      : props.active
      ? props.theme.palette.primary.light
      : props.theme.palette.background.bg1};
  border: 2px solid
    ${(props) => (props.active ? '#416AFF' : 'rgba(255, 255, 255, 0.12)')};
  box-shadow: ${(props) => props.theme.shadows.s1};
  border-radius: 12px;
  padding: 24px;
  cursor: pointer;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 16px;
    flex-direction: column;
  }
`;
export const CheckIconContainer = styled.div<SelectTypeOptionI>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: ${(props) =>
    props.active
      ? props.theme.palette.primary.main
      : props.theme.palette.background.bg3};
  @media (max-width: ${WIDTH.mobileMax}) {
    display: none;
  }
`;
export const SelectOptionName = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  font: 1rem/1.25rem Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const SelectOptionPrice = styled.div<{ isSoldOut: boolean }>`
  font: 1rem/1.25rem Onest-Bold;
  color: ${(props) =>
    props.isSoldOut ? '#909090' : props.theme.palette.text.primary};
`;
