import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const DetailsSectionContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 0;
    margin-top: 0;
  }
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  top: -45px;
  display: flex;
  align-items: center;
  /* width: 100%; */
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  margin: 0 16px;
`;
