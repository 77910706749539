import { memo } from 'react';
import { useLocale } from '../../../../contexts/LocalizationContext';
import TooltipWithArrow from '../../../ToolTipWithArrow/TooltipWithArrow';
import {
  DigitalKeyTileText,
  DigitalKeyTileWrapper,
} from './DigitalKeyTileStyle';

interface Props {
  fontSize: number;
  lineHeight: number;
}

function DigitalKeyTile({ fontSize, lineHeight }: Props) {
  const { messages } = useLocale();
  const { digital_key_msg, this_is_digital_edition_msg } = messages || {};
  return (
    <TooltipWithArrow title={<>{this_is_digital_edition_msg}</>}>
      <DigitalKeyTileWrapper padding={'4px 8px'}>
        <DigitalKeyTileText fontSize={fontSize} lineHeight={lineHeight}>
          {digital_key_msg}
        </DigitalKeyTileText>
      </DigitalKeyTileWrapper>
    </TooltipWithArrow>
  );
}

export default memo(DigitalKeyTile);
