import { memo, useMemo } from 'react';
import { usePdpContext } from '../../../contexts/PdpPageContext';
import { displayCurrencyValue } from '../../../helper/helper';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ProductOfferInterface } from '../../../Interface/ProductDetailsInterface';
import { useAppSelector } from '../../../redux/hooks';
import { BackgroundIcon } from '../../Icons/Icons';
import { MrpTag } from '../../OtherOffersRow/OtherOffersRowStyle';
import {
  BuyBoxPlusTag,
  DiscountPercent,
  PriceContainer,
  PriceSelectInputRadio,
  PriceValueActions,
  PriceValueContainer,
  PriceValueV2,
} from '../PrimarySellerCardv3Styles';

export type PriceTypeInterface = 'regular' | 'membership';

interface PriceBoxProps {
  discount: number | null;
  selectedPriceType: PriceTypeInterface;
  priceType: PriceTypeInterface;
  handleClick: (variant: PriceTypeInterface) => void;
  hasOfferSubscriptionDiscount: boolean;
  subscriptionDiscount: {
    plusDiscount: number;
    plusPrice: number;
    plusSaving: number;
    plusExtraSaving: number;
    totalPlusDiscount: number;
  };
  offer: ProductOfferInterface;
}

function PriceBox({
  discount,
  selectedPriceType,
  priceType,
  handleClick,
  subscriptionDiscount,
  hasOfferSubscriptionDiscount,
  offer,
}: PriceBoxProps) {
  const { width } = useWindowDimensions();
  const user = useAppSelector((state) => state.user);
  const {
    product,
    valuesToBeUsed,
    primarySeller: seller,
    handleSelectRadioOffer,
    selectedRadioOffer,
  } = usePdpContext();

  const priceValueLength = useMemo(() => {
    if (seller) {
      return displayCurrencyValue(
        seller.price,
        valuesToBeUsed.currencySymbol,
        valuesToBeUsed.multiplier
      ).length;
    }
    return 0;
  }, [seller, valuesToBeUsed.currencySymbol, valuesToBeUsed.multiplier]);

  return (
    seller && (
      <PriceContainer
        selectedPriceType={
          selectedPriceType === 'membership' &&
          offer.offerId === selectedRadioOffer?.offerId
            ? 'membership'
            : 'regular'
        }
        selectedRadioOffer={selectedRadioOffer?.offerId === offer.offerId}
        priceType={priceType}
        onClick={() => {
          handleSelectRadioOffer(offer);
          handleClick(priceType);
        }}
        isActiveSubscription={
          user.subscription === 'active' || !hasOfferSubscriptionDiscount
        }
      >
        <PriceValueContainer>
          <PriceValueV2
            isLong={priceValueLength}
            isLowest={false}
            isActiveSubscription={
              user.subscription === 'active' || !hasOfferSubscriptionDiscount
            }
          >
            {displayCurrencyValue(
              priceType === 'membership'
                ? subscriptionDiscount.plusPrice
                : seller.price,
              valuesToBeUsed.currencySymbol,
              valuesToBeUsed.multiplier
            )}
          </PriceValueV2>
          {discount !== null ? (
            <>
              <MrpTag isLong={priceValueLength}>
                <s>
                  {displayCurrencyValue(
                    product.data.mrp,
                    valuesToBeUsed.currencySymbol,
                    valuesToBeUsed.multiplier
                  )}
                </s>
                <DiscountPercent
                  isLong={priceValueLength}
                  style={{
                    color: priceType === 'membership' ? '#9D60EA' : '#01b401',
                  }}
                >
                  {priceType === 'membership' ? (
                    <>
                      {subscriptionDiscount?.totalPlusDiscount &&
                      Number(subscriptionDiscount.totalPlusDiscount) > 99
                        ? '~ 99'
                        : `~ ${Math.round(
                            subscriptionDiscount.totalPlusDiscount
                          )}`}
                      % off
                    </>
                  ) : (
                    <>
                      {discount && discount > 99 ? '~ 99' : `~ ${discount}`}%
                      off
                    </>
                  )}
                </DiscountPercent>
              </MrpTag>
            </>
          ) : null}
        </PriceValueContainer>

        <PriceValueActions>
          {priceType === 'membership' ? (
            <BuyBoxPlusTag>
              <BackgroundIcon
                url="/logo/plus-logo.svg"
                width="24px"
                height="12px"
                margin="0"
              />
            </BuyBoxPlusTag>
          ) : null}
          {user.subscription === 'active' ||
          user.subscription === 'no' ||
          !hasOfferSubscriptionDiscount ? null : (
            <PriceSelectInputRadio
              type="radio"
              checked={
                selectedPriceType === priceType &&
                selectedRadioOffer?.offerId === offer.offerId
              }
              fillColor={priceType === 'membership' ? '#9D60EA' : null}
              onChange={() => {}}
            />
          )}
        </PriceValueActions>
      </PriceContainer>
    )
  );
}

export default memo(PriceBox);
