import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 24px;
  @media (max-width: ${WIDTH.laptopMin}) {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const ProductRatingsContainer = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;
`;

export const StarsContainer = styled.div`
  display: flex;
  margin-left: -2px;
  margin-top: -2px;
`;

export const RatingPill = styled.div`
  border-radius: 16px;
  font-size: 16px;
  font-family: Onest-Bold;
  color: ${COLORS.purple};
  background: ${COLORS.purple2};
  width: fit-content;
  padding: 4px 8px;
  display: flex;
  place-content: center;
  align-content: center;
  line-height: 16px;
  @media (max-width: ${WIDTH.laptopMin}) {
    font-size: 12px;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 4px 12px;
  }
`;

export const TotalRatings = styled.div`
  color: ${(props) => props.theme.palette.text.t1};
  font: 0.875rem/1.125rem Onest-Medium;
  cursor: pointer;
  text-wrap: wrap;
  display: flex;
  align-items: center;
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 0.875rem/1.125rem Onest-Medium;
  }
`;
