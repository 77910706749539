import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { ellipseString } from '../../utils/sellerStatsUtils';
import { Error } from '../CartPromo/CartPromoModalStyle';
import { MaskIcon } from '../Icons/Icons';
import {
  SelectDropdown,
  SelectInput,
  SelectInputContainer,
  SelectOptions,
  SelectOptionsContainer,
} from './InputComponentsStyles';
import SelectDrawer from './SelectDrawer';

interface SelectPropsI {
  options:
    | { value: string; name: string; disabled?: boolean; icon?: any }[]
    | undefined;
  placeholder?: string;
  handleChange: (option: { value: string; name: string }, i?: number) => void;
  value?: { value: string; name: string; disabled?: boolean; icon?: any };
  width?: string;
  height?: string;
  style?: any;
  disabled?: boolean;
  hideInputInDrawer?: boolean;
  containerStyle?: any;
  dropDownStyle?: any;
  dropDownOptionStyle?: any;
  dropDownOptionStyleDisabled?: any;
  dropDownOptionStyleActive?: any;
  valueStyle?: any;
  showDivider?: boolean;
  error?: string;
  name?: string;
}

const Select: React.FC<SelectPropsI> = ({
  options,
  placeholder,
  value,
  handleChange,
  disabled,
  hideInputInDrawer,
  containerStyle,
  dropDownStyle,
  dropDownOptionStyle,
  valueStyle,
  dropDownOptionStyleDisabled,
  dropDownOptionStyleActive,
  showDivider,
  error,
  name,
  ...props
}) => {
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const dropdownRef = useRef<null | HTMLDivElement>(null);
  const dropdownContainerRef = useRef<null | HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<'bottom' | 'top'>('bottom');

  const toggleDropdown = useCallback(() => {
    if (!disabled) {
      setShowDropdown((prev) => !prev);
    }
  }, [disabled]);

  const handleSelectOption = useCallback(
    (option: { value: string; name: string }, i: number) => {
      handleChange(option, i);
      setShowDropdown(false);
    },
    [handleChange]
  );

  const handleClickOutside = useCallback(
    (e: any) => {
      const { target } = e;
      if (dropdownRef.current) {
        if (!dropdownRef.current.contains(target)) {
          setShowDropdown(false);
        }
      }
    },
    [dropdownRef]
  );
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dropdownContainerRef.current) {
      const nextEl = document.getElementById('__next');
      if (nextEl) {
        const diff =
          nextEl?.getBoundingClientRect().height -
          dropdownContainerRef.current.getBoundingClientRect().y;
        if (diff <= 400) {
          setAnchor('top');
        } else {
          setAnchor('bottom');
        }
      }
    }
  }, [dropdownContainerRef]);

  return (
    <SelectInputContainer ref={dropdownRef} style={containerStyle ?? {}}>
      <SelectInput
        style={
          props.style
            ? !value
              ? { ...props.style, color: theme.palette.text.t3 }
              : props.style
            : {}
        }
        onClick={toggleDropdown}
        width={props.width}
        disabled={disabled}
        height={props.height}
      >
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {value && value.icon && value.icon}
          <p
            style={
              valueStyle
                ? !value
                  ? { ...valueStyle, color: theme.palette.text.t3 }
                  : valueStyle
                : !value
                ? { ...valueStyle, color: theme.palette.text.t3 }
                : {}
            }
          >
            {value?.name ? ellipseString(String(value?.name), 20) : placeholder}
          </p>
        </span>
        <div>
          <MaskIcon
            url="/icons/arrow-down-chevron-24.svg"
            width="24px"
            height="24px"
            margin="0"
            selected
            color={theme.palette.text.t1}
          />
        </div>
      </SelectInput>
      {!!error && <Error>{error}</Error>}
      {options && showDropdown ? (
        width && width > getNumFromResolutionConstant(WIDTH.mobileMax) ? (
          <SelectDropdown
            anchor={anchor}
            ref={dropdownContainerRef}
            open={showDropdown}
            style={dropDownStyle ?? {}}
          >
            <SelectOptionsContainer>
              {options
                // ?.filter((cur) => cur.value !== value?.value)
                .map((cur, i) => {
                  return (
                    <div key={i}>
                      <SelectOptions
                        style={
                          cur.disabled
                            ? dropDownOptionStyleDisabled
                            : cur.value === value?.value
                            ? dropDownOptionStyleActive
                            : dropDownOptionStyle ?? {}
                        }
                        onClick={() => handleSelectOption(cur, i)}
                      >
                        <span
                          style={{
                            display: 'flex',
                            gap: '4px',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={
                              cur.disabled ? { filter: 'grayscale(1)' } : {}
                            }
                          >
                            {cur && cur.icon && cur.icon}
                          </div>
                          {cur.name}
                        </span>

                        <div>
                          <MaskIcon
                            url="/icons/tick.svg"
                            width="24px"
                            height="24px"
                            margin="0"
                            selected
                            color={theme.palette.text.primary}
                            style={{
                              display:
                                cur.value === value?.value ? 'block' : 'none',
                            }}
                          />
                        </div>
                      </SelectOptions>
                      {showDivider && i !== options.length - 1 && (
                        <div
                          style={{
                            height: '1px',
                            width: '100%',
                            background: theme.palette.divider.d1,
                            margin: '2px 0',
                          }}
                        />
                      )}
                    </div>
                  );
                })}
            </SelectOptionsContainer>
          </SelectDropdown>
        ) : (
          <SelectDrawer
            options={options}
            open={showDropdown}
            setOpen={setShowDropdown}
            value={value}
            title={placeholder || 'Select country code'}
            handleOptionClick={handleSelectOption}
            hideInputInDrawer={hideInputInDrawer}
            containerStyle={containerStyle}
            dropDownStyle={dropDownStyle}
            dropDownOptionStyle={dropDownOptionStyle}
            valueStyle={valueStyle}
            dropDownOptionStyleDisabled={dropDownOptionStyleDisabled}
            dropDownOptionStyleActive={dropDownOptionStyleActive}
            showDivider={showDivider}
          />
        )
      ) : null}
    </SelectInputContainer>
  );
};

export default Select;
