import styled from 'styled-components';

export const PDPHeadContainer = styled.div`
  display: flex;
  gap: 16px;
  position: relative;
  margin-bottom: 16px;
`;

export const PDPHeadContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const LineDivider = styled.div`
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.palette.divider.d1};
`;

export const ImportantDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 24px 8px;
`;

export const IconContainer = styled.div`
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.palette.background.bg1};
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.s1};
  border: 1px solid ${(props) => props.theme.palette.border.b1};
`;

export const ImportantDetailCard = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: start;
  height: 56px;
`;

export const ImportantDetailCardInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 2px;
  width: 100%;
  p {
    color: ${(props) => props.theme.palette.text.t3};
    font: 0.875rem/1.125rem Onest-Medium;
    span {
      font: 0.875rem/1.125rem Onest-SemiBold;
      color: ${(props) => props.theme.palette.text.t1};
    }
  }
`;

export const CheckTextButton = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font: 0.75rem/1rem Onest-SemiBold;
  cursor: pointer;
`;

export const RestrictionInfoText = styled.p`
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t3};
  & span {
    cursor: pointer !important;
    font-family: Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
  }
`;
