import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const NewsletterPdpContainer = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.palette.background.bg1};
  border: 1px solid ${(props) => props.theme.palette.border.b1};
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  height: fit-content;
  margin-bottom: 8px;
  @media (max-width: 1024px) {
    min-width: 100;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-bottom: 16px;
    width: 100%;
    padding: 16px;
  }
`;

export const NewsletterPdpWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const NewsletterPdpTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NewsLetterPdpText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    font: 0.75rem/1.25rem Onest-Medium;
    color: ${(props) => props.theme.palette.text.t2};
    white-space: wrap;
  }
`;
