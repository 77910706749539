import dynamic from 'next/dynamic';
import { useState } from 'react';
import { useTheme } from 'styled-components';
import { MaskIcon } from '../Icons/Icons';
import Button from '../NewButton/Button';
import { OtherOfferFilterContainer } from './OtherOffersSectionStyle';

const OtherOfferFilterMenu = dynamic(() => import('./OtherOfferFilterMenu'), {
  ssr: false,
});

const filterOptions = ['Best Price', 'Best Rating'];

interface Props {
  handleOptionClick: (value: string) => void;
  selectedOption: string;
}

function OtherOfferFilter({ handleOptionClick, selectedOption }: Props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <OtherOfferFilterContainer>
      <p>Sort by:</p>
      <Button
        height="32px"
        style={{
          background: '#00000000',
          fontFamily: 'Onest-SemiBold',
          color: theme.palette.text.t1,
          fontSize: '0.875rem',
          textTransform: 'capitalize',
          padding: '0 4px 0 2px',
        }}
        onClick={handleClick}
      >
        {selectedOption}{' '}
        <MaskIcon
          url="/icons/arrow-down-chevron-24.svg"
          width={'20px'}
          height={'20px'}
          margin="0 0 0 -8px"
          selected
          color={theme.palette.text.t1}
        />
      </Button>

      {open && (
        <OtherOfferFilterMenu
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          handleOptionClick={handleOptionClick}
          selectedOption={selectedOption}
          filterOptions={filterOptions}
        />
      )}
    </OtherOfferFilterContainer>
  );
}

export default OtherOfferFilter;
