import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useLocale } from '../../contexts/LocalizationContext';
import { ProductRequirement } from '../../Interface/ProductDetailsInterface';
import { MaskIcon } from '../Icons/Icons';
import { SystemRequirementsContainer } from '../ProductInformation/ProductInformationStyle';
import {
  NotAvailable,
  ProductRequirementTitle,
  ProductRequirementsList,
  RequirementIconContainer,
  RequirementsContainer,
  Tab,
  TabsContainer,
  TitleContainer,
  Wrapper,
} from './ProductRequirementsStyle';
import RequirementItem from './RequirementItem';

interface Props {
  systemRequirements: ProductRequirement | null;
  id: string;
}

interface RequirementDataI {
  os?: string;
  memory?: string;
  graphics?: string;
  processor?: string;
  storage?: string;
  additional?: string;
  sound?: string;
}

interface RequirementsI {
  minimum?: RequirementDataI;
  recommended?: RequirementDataI;
}

export const RequirementIcon = ({
  iconWidth,
  iconHeight,
  url,
}: {
  iconWidth: string;
  iconHeight: string;
  url: string;
}) => {
  const theme = useContext(ThemeContext);
  return (
    <RequirementIconContainer>
      <MaskIcon
        width={iconWidth}
        height={iconHeight}
        url={url}
        selected={true}
        margin="0"
        color={theme.palette.primary.main}
      />
    </RequirementIconContainer>
  );
};

const getReqKeyArray = (reqObj: RequirementDataI) => {
  const keysArr = Object.keys(reqObj)
    .filter((key) => reqObj![key as keyof RequirementDataI])
    .sort((a, b) => {
      return (
        reqObj![a as keyof RequirementDataI]!.length -
        reqObj![b as keyof RequirementDataI]!.length
      );
    });

  const index = keysArr.indexOf('os');
  if (index !== -1) {
    keysArr.splice(index, 1);
    keysArr.unshift('os');
    return keysArr;
  }
  return keysArr;
};

const ProductRequirements = (props: Props) => {
  const theme = useContext(ThemeContext);

  const { messages } = useLocale();
  const {
    system_requirements_msg,
    system_requirements_not_available_msg,
    minimum_system_requirements_msg,
    recommended_system_req_msg,
    system_msg,
  } = messages || {};

  const [tabs, setTabs] = useState<string[]>([]);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [currentRequirements, setCurrentRequirements] =
    useState<RequirementsI | null>(null);

  useEffect(() => {
    if (props.systemRequirements) {
      const keys = Object.keys(props.systemRequirements);
      const systems = keys.filter(
        (key) => key !== 'system' && key !== 'requirement'
      );
      setTabs(systems);
    }
  }, [props.systemRequirements]);

  useEffect(() => {
    if (props.systemRequirements) {
      if (tabs.length > 0) {
        setCurrentRequirements(
          props.systemRequirements[initialLoad ? 'windows' : tabs[currentTab]]
        );
      }
    }
  }, [tabs, currentTab, props.systemRequirements, initialLoad]);

  const hasNoRequirements = useCallback(() => {
    const isObjectEmpty = (obj: Object) => {
      if (!obj) {
        return true;
      }

      return !(
        typeof obj === 'object' &&
        Object.keys(obj).length > 0 &&
        Object.values(obj).some((cur) => !!cur)
      );
    };

    return (
      tabs.length === 0 ||
      !currentRequirements ||
      ((!currentRequirements.minimum ||
        isObjectEmpty(currentRequirements.minimum)) &&
        (!currentRequirements.recommended ||
          isObjectEmpty(currentRequirements.recommended)))
    );
  }, [tabs, currentRequirements]);

  return (
    <>
      {!hasNoRequirements() && (
        <SystemRequirementsContainer id={props.id}>
          <RequirementsContainer>
            <div>
              <TitleContainer>
                <h3>{system_requirements_msg}</h3>
              </TitleContainer>
              <Wrapper>
                {!hasNoRequirements() && (
                  <TabsContainer>
                    <span>{system_msg} :</span>
                    {tabs
                      .sort()
                      .reverse()
                      .map((cur, i) => (
                        <Tab
                          key={i}
                          active={currentTab === i}
                          onClick={() => {
                            setInitialLoad(false); //*
                            setCurrentTab(i);
                          }}
                        >
                          {cur}
                        </Tab>
                      ))}
                  </TabsContainer>
                )}
                {hasNoRequirements() ? (
                  <ProductRequirementTitle>
                    <NotAvailable>
                      {' '}
                      <MaskIcon
                        url="/icons/exclamation-mark-24.svg"
                        width="24px"
                        height="24px"
                        margin="0"
                        color={theme.palette.text.t1}
                        selected={true}
                        style={{ minWidth: 24 }}
                      />{' '}
                      <p>{system_requirements_not_available_msg}</p>
                    </NotAvailable>
                  </ProductRequirementTitle>
                ) : (
                  <>
                    {currentRequirements &&
                      currentRequirements.minimum &&
                      Object.keys(currentRequirements.minimum).length > 0 && (
                        <div style={{ marginBottom: 8 }}>
                          <ProductRequirementTitle>
                            {minimum_system_requirements_msg}
                          </ProductRequirementTitle>
                          <ProductRequirementsList>
                            {getReqKeyArray(currentRequirements.minimum).map(
                              (key, i) => {
                                return (
                                  <RequirementItem
                                    key={i}
                                    title={key === 'os' ? 'OS' : key}
                                    detail={
                                      currentRequirements.minimum![
                                        key as keyof RequirementDataI
                                      ]
                                    }
                                    itemKey={key}
                                  />
                                );
                              }
                            )}
                          </ProductRequirementsList>
                        </div>
                      )}
                    {currentRequirements &&
                      currentRequirements.recommended &&
                      Object.keys(currentRequirements.recommended).length >
                        0 && (
                        <div style={{ marginTop: '40px' }}>
                          <ProductRequirementTitle>
                            {recommended_system_req_msg}
                          </ProductRequirementTitle>
                          <ProductRequirementsList>
                            {getReqKeyArray(
                              currentRequirements.recommended
                            ).map((key, i) => (
                              <RequirementItem
                                title={key == 'os' ? 'OS' : key}
                                detail={
                                  currentRequirements.recommended![
                                    key as keyof RequirementDataI
                                  ]
                                }
                                itemKey={key}
                                key={i}
                              />
                            ))}
                          </ProductRequirementsList>
                        </div>
                      )}
                  </>
                )}
              </Wrapper>
            </div>
          </RequirementsContainer>
        </SystemRequirementsContainer>
      )}
    </>
  );
};

export default memo(ProductRequirements);
