import { Skeleton } from '@mui/material';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { memo, useState } from 'react';
import { useTheme } from 'styled-components';
import { usePdpContext } from '../../../../contexts/PdpPageContext';
import { useAppSelector } from '../../../../redux/hooks';
import { saveEventV3 } from '../../../../utils/eventTracking';
import { MaskIcon } from '../../../Icons/Icons';

import { useLocale } from '../../../../contexts/LocalizationContext';
import {
  CheckTextButton,
  IconContainer,
  ImportantDetailCard,
  ImportantDetailCardInfo,
  RestrictionInfoText,
} from '../../PDPPageStyles';

const CheckRestriction = dynamic(
  () => import('../../../CheckRestriction/CheckRestriction'),
  { ssr: false }
);

const LocationDetail: React.FC = () => {
  const theme = useTheme();
  const { product, allowedCountries, validRegion } = usePdpContext();
  const router = useRouter();
  const { geoLocation } = useAppSelector((state) => state.global);
  const [isRestrictionModalOpen, setIsRestrictionModalOpen] =
    useState<boolean>(false);

  const { messages } = useLocale();
  const {
    check_restriction_msg,
    can_be_activated_in_msg,
    cant_be_activated_in_msg,
    region_msg,
  } = messages || {};

  const handleRestrictionModalOpen = () => {
    saveEventV3({
      category: 'pdp_info',
      action: 'click',
      label: 'check_restriction_pill',
      properties: 'modal open',
      value: [product.data.slug],
      from: router,
    });

    setIsRestrictionModalOpen(true);
  };

  const handleRestrictionModalClose = () => {
    setIsRestrictionModalOpen(false);
  };

  return (
    <>
      <ImportantDetailCard>
        <IconContainer>
          {validRegion.loading ? (
            <Skeleton
              variant="circular"
              style={{ minWidth: '32px' }}
              width={'32px'}
              height="32px"
            />
          ) : validRegion.status === 1 ? null : validRegion.status === 2 ? (
            <MaskIcon
              width="32px"
              height="32px"
              color={theme.palette.success.main}
              url="/icons/check_circle_outlined.svg"
              style={{ minWidth: '32px' }}
              selected={true}
              margin="0"
            />
          ) : (
            <MaskIcon
              width="32px"
              height="32px"
              color="#CF3333"
              url="/icons/cancel_circle.svg"
              selected={true}
              style={{ minWidth: '32px' }}
              margin="0"
            />
          )}
        </IconContainer>
        <ImportantDetailCardInfo>
          <RestrictionInfoText onClick={handleRestrictionModalOpen}>
            {geoLocation?.country_name ? (
              validRegion.loading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '14px', width: '100px' }}
                />
              ) : validRegion.status === 1 ? null : validRegion.status === 2 ? (
                <p>
                  {can_be_activated_in_msg}{' '}
                  <span>{geoLocation?.country_name}</span>
                </p>
              ) : (
                <p>
                  {cant_be_activated_in_msg}{' '}
                  <span>{geoLocation?.country_name}</span>
                </p>
              )
            ) : (
              <p>
                <span>{check_restriction_msg}</span>
              </p>
            )}
          </RestrictionInfoText>

          <CheckTextButton onClick={handleRestrictionModalOpen}>
            {check_restriction_msg}
          </CheckTextButton>
        </ImportantDetailCardInfo>
      </ImportantDetailCard>
      {isRestrictionModalOpen && (
        <CheckRestriction
          isModalOpen={isRestrictionModalOpen}
          handleCloseModal={handleRestrictionModalClose}
          isAllowed={validRegion.status === 2}
          allowedCountries={allowedCountries}
          productRegions={product.data.regions}
        />
      )}
    </>
  );
};

export default memo(LocationDetail);
