import React from 'react';
import { usePdpContext } from '../../../../contexts/PdpPageContext';
import { ImportantDetailsContainer } from '../../PDPPageStyles';
import LocationDetail from './LocationDetail';
import OSDetail from './OSDetail';
import PlatformDetail from './PlatformDetail';
import RegionDetail from './RegionDetail';

const NewProductImpDetails: React.FC = () => {
  const { product } = usePdpContext();

  return (
    <ImportantDetailsContainer>
      <LocationDetail />
      <RegionDetail />

      <PlatformDetail />

      {product.data.systemRequirements &&
        Object.keys(product.data.systemRequirements).filter(
          (cur) => cur !== 'system' && cur !== 'requirement'
        ).length > 0 && <OSDetail />}
    </ImportantDetailsContainer>
  );
};

export default NewProductImpDetails;
