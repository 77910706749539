import axios from 'axios';
import { useRouter } from 'next/router';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';

import { apiConstants } from '../../constants/apiConstants';
import { WIDTH } from '../../constants/screenResolution';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAppSelector } from '../../redux/hooks';
import { saveEventV3 } from '../../utils/eventTracking';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { ifStringIsUrl } from '../../utils/ifStringIsUrl';

const ActivationDetailsPopup = dynamic(
  () =>
    import('../ActivationDetails/ActivationDetailsPopup').then(
      (mod) => mod.ActivationDetailsPopup
    ),
  { ssr: false }
);

const ActivationDetailDrawer = dynamic(
  () =>
    import('../ActivationDetails/ActivationDetailDrawer').then(
      (mod) => mod.ActivationDetailDrawer
    ),
  { ssr: false }
);

import CheckRestriction from '../CheckRestriction/CheckRestriction';
import { MaskIcon } from '../Icons/Icons';

import { Skeleton } from '@mui/material';
import { capitalize } from 'lodash';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { getPlatformPageLinkByPlatform } from '../../constants/platforms';
import { useLocale } from '../../contexts/LocalizationContext';
import { usePdpContext } from '../../contexts/PdpPageContext';
import { formatFilterTitle } from '../../helper/helper';
import { exportPlatformIcon } from '../Icons/PlatformIcons';
import {
  DetailsWrapper,
  DetailTitleContainer,
  DetailTitleValue,
  DetailWrapperRow,
} from './ProductImportantDetailsStylesV2';

export interface AllowedCountryI {
  countryName: string;
  cc: string;
}
interface RequirementsI {
  minimum?: RequirementDataI;
  recommended?: RequirementDataI;
}
interface RequirementDataI {
  os?: string;
  memory?: string;
  graphics?: string;
  processor?: string;
  storage?: string;
  additional?: string;
  sound?: string;
}

const ProductImportantDetailsV2 = () => {
  const router = useRouter();

  const { messages } = useLocale();
  const {
    cant_be_activated_in_msg,
    check_restriction_msg,
    can_be_activated_in_msg,
    activation_guide_msg,
    check_requirements_msg,
    server_error_msg,
    about_the_product_msg,
    can_activate_msg,
    region_msg,
    platform_msg,
    works_on_msg,
  } = messages || {};

  const { product, allowedCountries, validRegion } = usePdpContext();
  const theme = useContext(ThemeContext);
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const { geoLocation } = useAppSelector((state) => state.global);
  const { width } = useWindowDimensions();

  const [activationDetailsData, setActivationDetailsData] = useState<any>(null);
  const [isRestrictionModalOpen, setIsRestrictionModalOpen] =
    useState<boolean>(false);
  const [activationDetailsTitle, setActivationDetailsTitle] =
    useState<string>('');
  const [showActivationDetails, setShowActivationDetails] =
    useState<boolean>(false);

  const handleShowActivationDetail = useCallback(() => {
    setShowActivationDetails(!showActivationDetails);
  }, [showActivationDetails]);

  const fetchActivationDetails = useCallback(
    async (pid: number) => {
      try {
        const { data, status } = await axios.get(
          `${apiConstants.server}/public/catalog/products/${pid}/activation-details`
        );
        if (status === 200) {
          if (data) {
            if (data?.type == 'link') {
              return window.open(data.content, '_blank');
            }
            setShowActivationDetails(true);
            setActivationDetailsTitle(
              data.title ? data?.title : 'Activation Guide'
            );
            setActivationDetailsData(data.content);
          }
        }
      } catch (error) {
        setShowActivationDetails(false);
        dispatchSnackbar({
          type: 'failure',
          payload: server_error_msg ?? '',
        });
      }
    },
    [dispatchSnackbar, server_error_msg]
  );

  const handleRestrictionModalOpen = () => {
    saveEventV3({
      category: 'pdp_info',
      action: 'click',
      label: 'check_restriction_pill',
      properties: 'modal open',
      value: [product.data.slug],
      from: router,
    });

    setIsRestrictionModalOpen(true);
  };

  const handleRestrictionModalClose = () => {
    setIsRestrictionModalOpen(false);
  };

  const scrollToSystemReq = useCallback(() => {
    saveEventV3({
      category: 'pdp_info',
      action: 'click',
      label: 'system_requirements_pill',
      properties: 'scroll to system requirements',
      value: [product.data.slug],
      from: router,
    });

    if (!width) {
      return;
    }

    const systemReqEl =
      width && width > getNumFromResolutionConstant(WIDTH.mobileMax)
        ? document.getElementById('product-system-requirements')
        : document.getElementById('product-system-requirements-mobile');

    if (systemReqEl) {
      const rect = systemReqEl.getBoundingClientRect();

      // console.log(rect);

      window.scroll(
        0,
        width <= getNumFromResolutionConstant(WIDTH.mobileMax)
          ? window.scrollY + rect.top - 79
          : window.scrollY + rect.top - 93
      );
    }
  }, [router, width]);

  useEffect(() => {
    if (
      router.query.ag === '1' &&
      product.data.activationDetails &&
      !ifStringIsUrl(product.data.activationDetails)
    ) {
      fetchActivationDetails(parseInt(product.data.activationDetails));
    }
  }, [fetchActivationDetails, product.data.activationDetails, router.query.ag]);
  const [tabs, setTabs] = useState<string[]>([]);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [currentRequirements, setCurrentRequirements] =
    useState<RequirementsI | null>(null);

  useEffect(() => {
    if (product.data.systemRequirements) {
      const keys = Object.keys(product.data.systemRequirements);
      const systems = keys.filter(
        (key) => key !== 'system' && key !== 'requirement'
      );
      setTabs(systems);
    }
  }, [product.data.systemRequirements]);

  useEffect(() => {
    if (product.data.systemRequirements) {
      if (tabs.length > 0) {
        setCurrentRequirements(
          product.data.systemRequirements[
            initialLoad ? 'windows' : tabs[currentTab]
          ]
        );
      }
    }
  }, [tabs, currentTab, product.data.systemRequirements, initialLoad]);

  const hasNoRequirements = useCallback(() => {
    const isObjectEmpty = (obj: Object) => {
      if (!obj) {
        return true;
      }

      return !(
        typeof obj === 'object' &&
        Object.keys(obj).length > 0 &&
        Object.values(obj).some((cur) => !!cur)
      );
    };

    return (
      tabs.length === 0 ||
      !currentRequirements ||
      ((!currentRequirements.minimum ||
        isObjectEmpty(currentRequirements.minimum)) &&
        (!currentRequirements.recommended ||
          isObjectEmpty(currentRequirements.recommended)))
    );
  }, [tabs, currentRequirements]);

  return (
    <>
      <DetailsWrapper>
        <DetailWrapperRow>
          <DetailTitleContainer>
            <div>
              {validRegion.loading ? (
                <Skeleton
                  variant="circular"
                  style={{ minWidth: '32px' }}
                  width={'24px'}
                  height="24px"
                />
              ) : validRegion.status === 1 ? null : validRegion.status === 2 ? (
                <MaskIcon
                  width="24px"
                  height="24px"
                  color={theme.palette.success.main}
                  url="/icons/check_circle_outlined.svg"
                  style={{ minWidth: '24px' }}
                  selected={true}
                  margin="0"
                />
              ) : (
                <MaskIcon
                  width="24px"
                  height="24px"
                  color="#CF3333"
                  url="/icons/cancel_circle.svg"
                  selected={true}
                  style={{ minWidth: '24px' }}
                  margin="0"
                />
              )}
            </div>
            <h6>
              {geoLocation?.country_name ? (
                validRegion.loading ? (
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '14px', width: '100px' }}
                  />
                ) : validRegion.status === 1 ? null : validRegion.status ===
                  2 ? (
                  <p>{can_be_activated_in_msg} </p>
                ) : (
                  <p>{cant_be_activated_in_msg} </p>
                )
              ) : (
                <p>
                  <span>{check_restriction_msg}</span>
                </p>
              )}
            </h6>
          </DetailTitleContainer>
          <DetailTitleValue>
            {geoLocation?.country_name}
            <MaskIcon
              url="/icons/small-info-24.svg"
              height="16px"
              width="16px"
              margin="0 0 0 4px"
              onClick={handleRestrictionModalOpen}
              selected
              color={theme.palette.primary.main}
            />
          </DetailTitleValue>
        </DetailWrapperRow>
        <DetailWrapperRow>
          <DetailTitleContainer>
            <div>
              <MaskIcon
                width="24px"
                height="24px"
                color={theme.palette.text.primary}
                url="/icons/new-globe-24.svg"
                selected={true}
                margin="0"
              />
            </div>
            <h6>{capitalize(region_msg)}</h6>
          </DetailTitleContainer>
          <DetailTitleValue>
            {product.data.regions && product.data.regions.length > 0
              ? product.data.regions.map((cur) => cur.name).join(', ')
              : '-'}
          </DetailTitleValue>
        </DetailWrapperRow>
        <DetailWrapperRow>
          <DetailTitleContainer>
            <div>
              {exportPlatformIcon(
                product.data.platform,
                '20px',
                '20px',
                '0 2px 0'
              )}
            </div>
            <h6>{capitalize(platform_msg)}</h6>
          </DetailTitleContainer>
          <DetailTitleValue>
            {getPlatformPageLinkByPlatform(product.data.platform) ===
            '/platform/other' ? (
              <span>{product.data.platform || 'Other'}</span>
            ) : (
              <Link
                href={`${getPlatformPageLinkByPlatform(product.data.platform)}`}
                passHref
              >
                <a>
                  <span>{product.data.platform || 'Other'}</span>
                </a>
              </Link>
            )}
            <MaskIcon
              url="/icons/small-info-24.svg"
              height="16px"
              width="16px"
              margin="0 0 0 4px"
              onClick={
                product.data.activationDetails === '' ||
                product.data.activationDetails === null
                  ? () => {}
                  : () => {
                      saveEventV3({
                        category: 'pdp_info',
                        action: 'click',
                        label: 'check_activation_pill',
                        properties: 'modal open',
                        value: [],
                        from: router,
                      });

                      ifStringIsUrl(product.data.activationDetails)
                        ? window.open(product.data.activationDetails, '_blank')
                        : fetchActivationDetails(
                            parseInt(product.data.activationDetails)
                          );
                    }
              }
              selected
              color={theme.palette.primary.main}
            />
          </DetailTitleValue>
        </DetailWrapperRow>
        {!product.data.productTypes.includes('ingame') &&
          !product.data.productTypes.includes('giftcard') &&
          !product.data.productTypes.includes('game point') &&
          !product.data.productTypes.includes('subscription') &&
          product.data.systemRequirements && (
            <DetailWrapperRow>
              <DetailTitleContainer>
                <div>
                  <MaskIcon
                    url="/icons/sync_saved_locally.svg"
                    width="24px"
                    height="24px"
                    color={theme.palette.text.primary}
                    style={{ minWidth: '24px' }}
                    margin="0"
                    selected
                  />
                </div>
                <h6>{capitalize(works_on_msg)}</h6>
              </DetailTitleContainer>
              <DetailTitleValue>
                {product.data.systemRequirements &&
                Object.keys(product.data.systemRequirements).filter(
                  (cur) => cur !== 'system' && cur !== 'requirement'
                ).length > 0
                  ? Object.keys(product.data.systemRequirements)
                      .filter(
                        (cur) => cur !== 'system' && cur !== 'requirement'
                      )
                      .map((el, index) =>
                        index !== 0
                          ? ', ' + formatFilterTitle(el)
                          : formatFilterTitle(el)
                      )
                  : '-'}
                {hasNoRequirements() ? null : (
                  <MaskIcon
                    url="/icons/small-info-24.svg"
                    height="16px"
                    width="16px"
                    margin="0 0 0 4px"
                    onClick={scrollToSystemReq}
                    selected
                    color={theme.palette.primary.main}
                  />
                )}
              </DetailTitleValue>
            </DetailWrapperRow>
          )}
      </DetailsWrapper>

      <CheckRestriction
        isModalOpen={isRestrictionModalOpen}
        handleCloseModal={handleRestrictionModalClose}
        isAllowed={validRegion.status === 2}
        allowedCountries={allowedCountries}
        productRegions={product.data.regions}
      />
      {width && width > getNumFromResolutionConstant(WIDTH.mobileMax) ? (
        <ActivationDetailsPopup
          handleModal={handleShowActivationDetail}
          show={showActivationDetails}
          data={activationDetailsData}
          title={activationDetailsTitle}
        />
      ) : (
        <ActivationDetailDrawer
          drawerState={showActivationDetails}
          data={activationDetailsData}
          toggleDrawer={handleShowActivationDetail}
          title={activationDetailsTitle}
        />
      )}
    </>
  );
};

export default memo(ProductImportantDetailsV2);
