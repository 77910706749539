import Skeleton from '@mui/material/Skeleton';
import { useRouter } from 'next/router';
import { useCallback, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';

import { useLocale } from '../../contexts/LocalizationContext';
import { PdpPageContext } from '../../contexts/PdpPageContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { MaskIcon } from '../Icons/Icons';
import {
  Container,
  ProductRatingsContainer,
  StarsContainer,
  TotalRatings,
} from './ProductRatingsStyle';

import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { WIDTH } from '../../constants/screenResolution';

const ProductRatings = () => {
  const router = useRouter();
  const theme = useContext(ThemeContext);
  const { width } = useWindowDimensions();

  const { messages } = useLocale();
  const { ratings_msg, rating_msg } = messages || {};

  const [iconHeight, setIconHeight] = useState<string>('20px');
  const [iconWidth, setIconWidth] = useState<string>('20px');
  const { isRatingLoading, productRating } = useContext(PdpPageContext);


  const scrollToProductReviews = useCallback(() => {

    if (!width) {
      return;
    }

    const systemReqEl =
      width && width > getNumFromResolutionConstant(WIDTH.mobileMax)
        ? document.getElementById('product-reviews')
        : document.getElementById('product-reviews');

    if (systemReqEl) {
      const rect = systemReqEl.getBoundingClientRect();

      window.scroll(
        0,
        width <= getNumFromResolutionConstant(WIDTH.mobileMax)
          ? window.scrollY + rect.top - 79
          : window.scrollY + rect.top - 93
      );
    }
  }, [router, width]);

  // useEffect(() => {
  //   if (width) {
  //     setIconHeight('24px');
  //     setIconWidth('24px');
  //   }
  // }, [width]);

  return (
    <Container >
      {!isRatingLoading ? (
        <>
          <ProductRatingsContainer onClick={scrollToProductReviews}>
            <StarsContainer>
              {[1, 2, 3, 4, 5].map((cur,id) => (
                <MaskIcon
                  width={iconWidth}
                  height={iconHeight}
                  color={
                    productRating.overall
                      ? cur <= Math.round(productRating.overall)
                        ? theme.palette.primary.main
                        : '#353535'
                      : cur <= 4
                      ? theme.palette.primary.main
                      : theme.palette.divider.d1
                  }
                  url="/icons/yellow-star.svg"
                  selected={true}
                  key={id}
                  margin="0"
                />
              ))}
            </StarsContainer>

            {/* <RatingPill>
              {productRating.rating
                ? (Math.round(productRating.rating * 100) / 100)
                    .toFixed(1)
                    .toString()
                : '4.0'}
            </RatingPill> */}
          </ProductRatingsContainer>
          {productRating.count > 0 && (
            <TotalRatings onClick={scrollToProductReviews}>
              {productRating.count}{' '}
              {productRating.count > 1 ? ratings_msg : rating_msg}
            </TotalRatings>
          )}
        </>
      ) : (
        <>
          <Skeleton variant="text" width="171px" />
          <Skeleton variant="text" width="100px" />
        </>
      )}
    </Container>
  );
};

export default ProductRatings;
