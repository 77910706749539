import DOMPurify from 'dompurify';
import React from 'react';

import { RequirementIcon } from './ProductRequirements';
import {
  RequirementListItem,
  RequirementTitle,
  RequirementDetail,
} from './ProductRequirementsStyle';

const iconUrl = (req: string) => {
  switch (req.toLowerCase()) {
    case 'os':
      return '/icons/platform-24.svg';
    case 'processor':
      return '/icons/cpu-24.svg';
    case 'memory':
      return '/icons/memory-24.svg';
    case 'graphics':
      return '/icons/gpu-24.svg';
    case 'sound card':
      return '/icons/sound-24.svg';
    case 'storage':
      return '/icons/storage-24.svg';
    case 'other requirements':
      return '/icons/notes-24.svg';
    default:
      return '/icons/setting-24.svg';
  }
};

interface RequirementItemProps {
  itemKey: string;
  title: string;
  detail: string | undefined;
}

const RequirementItem: React.FC<RequirementItemProps> = ({
  itemKey,
  title,
  detail,
}) => {
  return (
    <RequirementListItem>
      <RequirementIcon
        iconHeight="24px"
        iconWidth="24px"
        url={iconUrl(itemKey)}
      />
      <div>
        <RequirementTitle>{title}:</RequirementTitle>
        <RequirementDetail>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(detail || ''),
            }}
          />
          {/* {detail || ''} */}
        </RequirementDetail>
      </div>
    </RequirementListItem>
  );
};

export default RequirementItem;
