import { useRouter } from 'next/router';
import { memo, useCallback } from 'react';
import { WIDTH } from '../../../constants/screenResolution';
import { usePdpContext } from '../../../contexts/PdpPageContext';
import { displayCurrencyValue } from '../../../helper/helper';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { saveEventV3 } from '../../../utils/eventTracking';
import { getNumFromResolutionConstant } from '../../../utils/getNumberFromResolutionConstant';
import { OtherOffersContainer } from '../PrimarySellerCardv3Styles';

function OtherOfferStrip() {
  const { width } = useWindowDimensions();
  const router = useRouter();

  const { valuesToBeUsed, primarySeller: seller } = usePdpContext();

  const scrollToOtherOffers = useCallback(() => {
    if (!width) {
      return;
    }
    let otherOfferEl = document.getElementById('product-other-offers');
    if (width <= getNumFromResolutionConstant(WIDTH.mobileMax)) {
      otherOfferEl = document.getElementById('product-other-offers-mobile');
    }
    if (otherOfferEl) {
      saveEventV3({
        category: 'primary_seller_box',
        action: 'click',
        label: 'view_other_offers',
        properties: '',
        value: [],
        from: router,
      });
      const rect = otherOfferEl.getBoundingClientRect();
      window.scroll(
        0,
        width <= getNumFromResolutionConstant(WIDTH.mobileMax)
          ? window.scrollY + rect.top - 79
          : window.scrollY + rect.top - 93
      );
    }
  }, [router, width]);

  return valuesToBeUsed?.offers?.length > 1 ? (
    <>
      <OtherOffersContainer onClick={scrollToOtherOffers}>
        <div>
          <span>
            +{valuesToBeUsed?.offers?.length - 1}{' '}
            {valuesToBeUsed?.offers?.length > 2 ? 'offers ' : 'offer '}
          </span>
          {valuesToBeUsed?.offers?.length > 2 && 'starting '}
          at{' '}
          {displayCurrencyValue(
            valuesToBeUsed?.offers?.findIndex((cur: any) => !!cur.isFeatured) >=
              0
              ? valuesToBeUsed?.offers
                  .filter((cur: any) => !cur.isFeatured)
                  .sort(
                    (a: any, b: any) => Number(a?.price) - Number(b?.price)
                  )[0]?.price
              : valuesToBeUsed?.offers
                  .slice(1)
                  .sort(
                    (a: any, b: any) => Number(a?.price) - Number(b?.price)
                  )[0]?.price,
            valuesToBeUsed?.currencySymbol,
            valuesToBeUsed?.multiplier
          )}
        </div>
      </OtherOffersContainer>
    </>
  ) : null;
}

export default memo(OtherOfferStrip);
