import { Skeleton } from '@mui/material';
import { useRouter } from 'next/router';
import React, { memo, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { ProductOfferInterface } from '../../Interface/ProductDetailsInterface';
import { usePdpContext } from '../../contexts/PdpPageContext';
import { displayCurrencyValue } from '../../helper/helper';
import { useAppSelector } from '../../redux/hooks';
import {
  GWMobileWrapper,
  GWOnlyMobileWrapper,
  GWTabletWrapper,
  GWWebWrapper,
} from '../../styles/window.style';

import { useLocale } from '../../contexts/LocalizationContext';
import { checkForFBTPromoBoost } from '../../utils/fbt';
import { MaskIcon } from '../Icons/Icons';
import LoaderCircle from '../LoaderDots/LoaderCircle';
import Button from '../NewButton/Button';
import {
  ScrollButton,
  ScrollButtonsContainer,
} from '../SimilarProducts/SimilarProductsStyles';
import {
  Card,
  CardGrid,
  CardRow,
  Container,
  DisplayDiscountContentContainer,
  FBTBundleContainer,
  FBTBundleNumber,
  FBTHeaderContainer,
  FBTHeaderTitle,
  MainContainer,
  MoreActionDetails,
  MoreDetails,
  MoreDetailsItem,
  MoreDetailsTitle,
  MoreDetailsValue,
} from './PdpFBTStyles';
import FBTCard from './components/FBTCard';

export interface FBTBundleContainerInterface {
  image: string;
  mrp: number | null;
  offerId: number;
  platform: string;
  price: number;
  productId: number;
  regionId: number;
  regionName: string;
  slug: string;
  title: string;
  productTypes: string[];
  offersList: ProductOfferInterface[];
}
const PdpFBT: React.FC = () => {
  const router = useRouter();
  const theme = useTheme();
  const { fbtHook } = usePdpContext();

  const { messages } = useLocale();
  const { frequently_bought_together_msg, unlock_extra_msg, buy_now_msg } =
    messages || {};

  const { query } = router;
  const { locationDetails } = useAppSelector((state) => state.global);

  const {
    bundleCounter,
    cartLoading,
    discountedPrice,
    handleAddToCart,
    handleScrollClick,
    loading,
    productsData,
    recommendedProductList,
    textLength,
    totalPrice,
    currencyDetails,
  } = fbtHook;

  const displayDiscountContent = useMemo(() => {
    return (
      <>
        <DisplayDiscountContentContainer>
          {discountedPrice ? (
            <span>
              {' '}
              You save{' '}
              {query.currency
                ? displayCurrencyValue(
                    discountedPrice,
                    currencyDetails?.currency_symbol ??
                      locationDetails?.currency_symbol,
                    currencyDetails?.multiplier ?? locationDetails?.multiplier
                  )
                : displayCurrencyValue(
                    discountedPrice,
                    locationDetails?.currency_symbol,
                    locationDetails?.multiplier
                  )}
            </span>
          ) : null}
          {!(productsData && !checkForFBTPromoBoost(productsData)) ? (
            <span style={{ color: theme.palette.text.t2 }}>
              + Get an extra discount upto 5%{' '}
              <span style={{ color: '#9e60ec' }}>(Use Code FBT05)</span>
            </span>
          ) : null}
        </DisplayDiscountContentContainer>
      </>
    );
  }, [
    currencyDetails?.currency_symbol,
    currencyDetails?.multiplier,
    discountedPrice,
    locationDetails?.currency_symbol,
    locationDetails?.multiplier,
    productsData,
    query.currency,
    theme.palette.text.t2,
  ]);

  return (
    <div>
      {loading ? (
        <>
          <GWMobileWrapper>
            <div style={{ padding: '0 16px' }}>
              <Skeleton
                variant="rectangular"
                width="250px"
                height="16px"
                sx={{
                  borderRadius: '12px',
                  background:
                    theme.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.1)'
                      : 'rgba(0, 0, 0, 0.1)',
                  mb: '16px',
                }}
              />
              <Skeleton
                variant="rectangular"
                width="100%"
                height="300px"
                sx={{
                  borderRadius: '12px',
                  mb: '16px',
                  background:
                    theme.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.1)'
                      : 'rgba(0, 0, 0, 0.1)',
                  padding: '16px',
                }}
              />
            </div>
          </GWMobileWrapper>
          <GWWebWrapper>
            <div>
              <Skeleton
                variant="rectangular"
                width="250px"
                height="16px"
                sx={{
                  borderRadius: '12px',
                  background:
                    theme.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.1)'
                      : 'rgba(0, 0, 0, 0.1)',
                  mb: '16px',
                }}
              />
              <Skeleton
                variant="rectangular"
                width="100%"
                height="300px"
                sx={{
                  borderRadius: '12px',
                  mb: '16px',
                  background:
                    theme.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.1)'
                      : 'rgba(0, 0, 0, 0.1)',
                  padding: '16px',
                }}
              />
            </div>
          </GWWebWrapper>
        </>
      ) : recommendedProductList !== null ? (
        <Container>
          <FBTHeaderContainer>
            <FBTHeaderTitle>
              <h3>{frequently_bought_together_msg}</h3>
            </FBTHeaderTitle>
            {recommendedProductList && recommendedProductList.length > 1 && (
              <>
                <ScrollButtonsContainer>
                  <FBTBundleNumber>
                    <GWWebWrapper>
                      {/* {bundle_of_items_msg?.replace(
                        '[--NUMBER1--]',
                        String(bundleCounter).replace(
                          '[--NUMBER2--]',
                          String(
                            recommendedProductList.length > 3
                              ? 3
                              : recommendedProductList.length
                          )
                        )
                      )} */}
                      Bundle {bundleCounter} of{' '}
                      {recommendedProductList.length > 3
                        ? 3
                        : recommendedProductList.length}
                    </GWWebWrapper>
                  </FBTBundleNumber>
                  <GWWebWrapper>
                    <FBTBundleContainer>
                      <ScrollButton
                        disabled={bundleCounter === 1}
                        onClick={() => {
                          bundleCounter === 1
                            ? () => {}
                            : handleScrollClick('previous');
                        }}
                      >
                        <MaskIcon
                          url="/icons/arrow-right-chevron-24.svg"
                          height="24px"
                          color={theme.palette.text.t1}
                          width="24px"
                          margin="0"
                          style={{ transform: 'rotate(180deg)' }}
                          selected={true}
                        />
                      </ScrollButton>
                      <ScrollButton
                        disabled={
                          bundleCounter ===
                          (recommendedProductList.length > 3
                            ? 3
                            : recommendedProductList.length)
                        }
                        onClick={() => {
                          bundleCounter ===
                          (recommendedProductList.length > 3
                            ? 3
                            : recommendedProductList.length)
                            ? () => {}
                            : handleScrollClick('next');
                        }}
                      >
                        <MaskIcon
                          url="/icons/arrow-right-chevron-24.svg"
                          height="24px"
                          color={theme.palette.text.t1}
                          width="24px"
                          margin="0"
                          selected={true}
                        />
                      </ScrollButton>
                    </FBTBundleContainer>
                  </GWWebWrapper>
                  <GWMobileWrapper>
                    <FBTBundleContainer>
                      <ScrollButton
                        changeCss={true}
                        disabled={bundleCounter === 1}
                        onClick={() => {
                          bundleCounter === 1
                            ? () => {}
                            : handleScrollClick('previous');
                        }}
                      >
                        <MaskIcon
                          url="/icons/arrow-right-chevron-24.svg"
                          height="24px"
                          color={theme.palette.text.t1}
                          width="24px"
                          margin="0"
                          style={{ transform: 'rotate(180deg)' }}
                          selected={true}
                        />
                      </ScrollButton>
                      <ScrollButton
                        changeCss={true}
                        disabled={
                          bundleCounter ===
                          (recommendedProductList.length > 3
                            ? 3
                            : recommendedProductList.length)
                        }
                        onClick={() => {
                          bundleCounter ===
                          (recommendedProductList.length > 3
                            ? 3
                            : recommendedProductList.length)
                            ? () => {}
                            : handleScrollClick('next');
                        }}
                      >
                        <MaskIcon
                          url="/icons/arrow-right-chevron-24.svg"
                          height="24px"
                          color={theme.palette.text.t1}
                          width="24px"
                          margin="0"
                          selected={true}
                        />
                      </ScrollButton>
                    </FBTBundleContainer>
                  </GWMobileWrapper>
                </ScrollButtonsContainer>
              </>
            )}
          </FBTHeaderContainer>
          <MainContainer>
            <CardGrid>
              {productsData?.map((cur, i) => (
                <FBTCard
                  priceLength={textLength(cur.price)}
                  product={cur}
                  key={i}
                  index={i}
                />
              ))}
            </CardGrid>
            <Card
              isBoosted={
                !(productsData && !checkForFBTPromoBoost(productsData))
              }
            >
              <CardRow>
                <MoreDetails>
                  <MoreDetailsItem>
                    <MoreDetailsTitle>Get this bundle for</MoreDetailsTitle>
                    <MoreDetailsValue>
                      {query.currency
                        ? displayCurrencyValue(
                            totalPrice,
                            currencyDetails?.currency_symbol ??
                              locationDetails?.currency_symbol,
                            currencyDetails?.multiplier ??
                              locationDetails?.multiplier
                          )
                        : displayCurrencyValue(
                            totalPrice,
                            locationDetails?.currency_symbol,
                            locationDetails?.multiplier
                          )}{' '}
                      <GWTabletWrapper>
                        {displayDiscountContent}
                      </GWTabletWrapper>
                    </MoreDetailsValue>
                  </MoreDetailsItem>
                </MoreDetails>

                <MoreActionDetails>
                  {/* <Button
                  buttonTheme="grey"
                  height="40px"
                  width="40px"
                  style={{ padding: 0 }}
                >
                  <MaskIcon
                    url="/icons/add-to-cart-24.svg"
                    width={'20px'}
                    height="20px"
                    color={theme.palette.text.t1}
                    selected={true}
                    margin="0"
                  />
                </Button> */}
                  {!(productsData && !checkForFBTPromoBoost(productsData)) ? (
                    <Button
                      ariaLabel="Buy now"
                      onClick={handleAddToCart}
                      disabled={cartLoading}
                      height="40px"
                      width="fit-content"
                      style={{
                        background: '#9E60EC',
                        color: '#fff',
                        minWidth: '100px',
                        font: '.875rem/1.125rem Onest-SemiBold',
                      }}
                    >
                      {cartLoading ? <LoaderCircle /> : <>{buy_now_msg}</>}
                    </Button>
                  ) : (
                    <Button
                      ariaLabel="Buy now"
                      buttonTheme="primary"
                      height="40px"
                      width="fit-content"
                      onClick={handleAddToCart}
                      style={{ font: '.875rem/1.125rem Onest-SemiBold' }}
                    >
                      {cartLoading ? (
                        <LoaderCircle />
                      ) : (
                        <>
                          <MaskIcon
                            url="/icons/cart-24.svg"
                            width={'20px'}
                            height="20px"
                            color={'#fff'}
                            selected={true}
                            margin="0"
                          />
                          {buy_now_msg}
                        </>
                      )}
                    </Button>
                  )}
                </MoreActionDetails>
              </CardRow>
              {!(productsData && !checkForFBTPromoBoost(productsData)) ? (
                <GWOnlyMobileWrapper>
                  <CardRow>
                    <MoreDetailsValue
                      style={{
                        borderTop: `1px solid ${theme.palette.border.b2}`,
                        paddingTop: '8px',
                        width: '100%',
                      }}
                    >
                      {displayDiscountContent}
                    </MoreDetailsValue>
                  </CardRow>
                </GWOnlyMobileWrapper>
              ) : null}

              {/* {productsData && !checkForFBTPromoBoost(productsData) ? null : (
                <PromoCodeText>
                  Use promo <span>{getFBTPromoValue()?.promoCode}</span> to get
                  upto <span>{getFBTPromoValue()?.promoValue}% off</span>
                </PromoCodeText>
              )} */}
            </Card>
          </MainContainer>
        </Container>
      ) : null}
    </div>
  );
};

export default memo(PdpFBT);
