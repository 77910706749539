import styled from 'styled-components';

interface DigitalKeyTileWrapperProps {
  padding: string;
}

export const DigitalKeyTileWrapper = styled.div<DigitalKeyTileWrapperProps>`
  display: flex;
  max-height: 24px;
  align-items: center;
  height: 100%;
  width: max-content;
  padding: ${(props) => props.padding};
  border: 1px solid ${(props) => props.theme.palette.border.b2};
  background: ${(props) => props.theme.palette.background.bg4};
  color: ${(props) => props.theme.palette.text.t1};
  border-radius: 6px;
  box-shadow: ${(props) => props.theme.shadows.s2};
`;

export const DigitalKeyTileText = styled.div<{
  fontSize: number;
  lineHeight: number;
}>`
  font: ${(props) => props.fontSize}rem / ${(props) => props.lineHeight}rem
    Onest-SemiBold;
  color: ${(props) => props.theme.palette.text.t1};
  text-transform: uppercase;
`;
