import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const ProductPillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`;

export const ProductPills = styled.div`
  background: ${(props) => props.theme.palette.background.bg3};
  border: none;
  border-radius: 16px;
  padding: 8px 12px;
  color: ${(props) => (props.theme.mode === 'light' ? '#6e6e6e' : '#FAF9F6')};
  font-size: 14px;
  line-height: 14px;
  ${(props) =>
    props.theme.mode === 'light'
      ? 'font-family: Onest-SemiBold;'
      : 'font-family: Onest-SemiBold;'}
  width: fit-content;
  margin-right: 10px;
  max-height: 30px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: ${(props) =>
      props.theme.mode === 'dark' ? '#4f4f4f' : COLORS.grey};
  }
  &:active {
    transform: scale(0.95);
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    color: #6e6e6e;
    font-size: 12px;
    line-height: 12px;
    font-family: Onest-SemiBold;
    height: 30px;
    min-width: fit-content;
  }
`;
