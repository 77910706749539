import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';
import { PriceTypeInterface } from '../../PrimarySellerCardV3/components/PriceBox';

export const MembershipPromotionStripContainer = styled.div<{
  selectedPriceType: PriceTypeInterface;
  position: 'top' | 'bottom';
}>`
  display: flex;
  gap: 6px;
  height: 32px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  border-radius: ${(props) =>
    props.position === 'top' ? '8px 8px 0 0' : '0 0 8px 8px'};
  border: 1px solid ${(props) => props.theme.palette.border.b1};
  background: ${(props) =>
    props.selectedPriceType === 'membership'
      ? props.theme.mode === 'dark'
        ? 'radial-gradient(100% 100% at 0% 0%, #B67EFDdd 0%, #7626DCdd 68.08%)'
        : 'radial-gradient(100% 100% at 0% 0%, #B67EFDdd 0%, #7626DCdd 68.08%)'
      : props.theme.palette.background.bg1};
  p {
    font-size: 12px;
    line-height: 16px;
    font-family: Onest-SemiBold;
    color: ${(props) =>
      props.selectedPriceType === 'membership'
        ? '#fff'
        : COLORS.drifflePlusText};
    letter-spacing: 0.3px;
  }
`;

export const MembershipTag = styled.div`
  height: 16px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff33;
  border-radius: 8px;
  font: 0.5rem/0.5rem Onest-Medium;
  color: #fff;
  padding: 4px 6px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;

export const MembershipTagBar = styled.div`
  width: 2px;
  height: 16px;
`;
