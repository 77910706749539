import { IconButton } from '@mui/material';
import Link from 'next/link';
import { memo } from 'react';
import { useTheme } from 'styled-components';
import { useLocale } from '../../contexts/LocalizationContext';
import { displayApproximateValue } from '../../helper/helper';
import { ProductOfferInterface } from '../../Interface/ProductDetailsInterface';
import {
  DefectiveKeyRatio,
  monthsUntilNow,
} from '../../utils/sellerStatsUtils';
import { MaskIcon } from '../Icons/Icons';
import {
  DefectiveKeyRating,
  SellerDetailsCardV3Content,
  SellerDetailsCardV3ContentRow,
  SellerDetailsCardV3ContentRowTitle,
  SellerDetailsCardV3ContentRowValue,
  SellerDetailsCardV3Header,
  SellerDetailsCardV3Wrapper,
} from './SellerDetailsCardV3Style';

interface Props {
  offerDetails: ProductOfferInterface | null;
}

function SellerDetailsCardV3({ offerDetails }: Props) {
  const theme = useTheme();
  const { messages } = useLocale();
  const { selling_since_msg, orders_fulfilled_msg, defective_keys_msg } =
    messages || {};
  return (
    <SellerDetailsCardV3Wrapper>
      <SellerDetailsCardV3Header>
        <h3>Seller Stats</h3>
        <Link href={`/vendor/${offerDetails?.sellerId}`}>
          <a target="_blank">
            <IconButton>
              <MaskIcon
                url="/icons/open-link-bold-24.svg"
                width="20px"
                height="20px"
                color={theme.palette.text.t3}
                selected={true}
                margin="0"
              />
            </IconButton>
          </a>
        </Link>
      </SellerDetailsCardV3Header>
      <SellerDetailsCardV3Content>
        <SellerDetailsCardV3ContentRow>
          <SellerDetailsCardV3ContentRowTitle>
            <div>
              <MaskIcon
                url="/icons/yellow-star.svg"
                width="20px"
                height="20px"
                color={
                  'linear-gradient(95.83deg, #FFCC00 3.89%, #DD8600 97.43%)'
                }
                selected={true}
                margin="0"
              />
            </div>
            <span>Rating</span>
          </SellerDetailsCardV3ContentRowTitle>
          <SellerDetailsCardV3ContentRowValue>
            {offerDetails
              ? Number(offerDetails.sellerRatings).toFixed(1)
              : null}
            {offerDetails?.totalSellerReviewsCount ? (
              <span>| {offerDetails?.totalSellerReviewsCount} Reviews</span>
            ) : null}
          </SellerDetailsCardV3ContentRowValue>
        </SellerDetailsCardV3ContentRow>
        <SellerDetailsCardV3ContentRow>
          <SellerDetailsCardV3ContentRowTitle>
            <div>
              <MaskIcon
                url="/icons/store-24.svg"
                width="20px"
                height="20px"
                color={theme.palette.primary.main}
                selected={true}
                margin="0"
              />
            </div>
            <span>{selling_since_msg}</span>
          </SellerDetailsCardV3ContentRowTitle>
          <SellerDetailsCardV3ContentRowValue>
            {!!offerDetails?.storeOnboardingDate
              ? monthsUntilNow(offerDetails.storeOnboardingDate) > 1
                ? `${monthsUntilNow(offerDetails.storeOnboardingDate)} months`
                : `${monthsUntilNow(offerDetails.storeOnboardingDate)} month`
              : `1 month`}
          </SellerDetailsCardV3ContentRowValue>
        </SellerDetailsCardV3ContentRow>
        {!offerDetails?.ordersProcessed ? null : (
          <SellerDetailsCardV3ContentRow>
            <SellerDetailsCardV3ContentRowTitle>
              <div>
                <MaskIcon
                  url="/icons/check-24.svg"
                  width="20px"
                  height="20px"
                  selected={true}
                  color={theme.palette.success.main}
                  margin="0"
                />
              </div>
              <span>{orders_fulfilled_msg}</span>
            </SellerDetailsCardV3ContentRowTitle>
            <SellerDetailsCardV3ContentRowValue>
              {offerDetails?.ordersProcessed
                ? displayApproximateValue(offerDetails?.ordersProcessed)
                : 0}
            </SellerDetailsCardV3ContentRowValue>
          </SellerDetailsCardV3ContentRow>
        )}
        <SellerDetailsCardV3ContentRow>
          <SellerDetailsCardV3ContentRowTitle>
            <div>
              <MaskIcon
                url="/icons/defective-key-vertical.svg"
                width="20px"
                height="20px"
                color={theme.palette.text.t1}
                selected={true}
                margin="0"
              />
            </div>
            <span>{defective_keys_msg}</span>
          </SellerDetailsCardV3ContentRowTitle>
          <SellerDetailsCardV3ContentRowValue>
            <DefectiveKeyRating
              color={
                DefectiveKeyRatio(
                  offerDetails?.positivity
                    ? 100 - Number(offerDetails.positivity)
                    : 0
                ).color
              }
            >
              {
                DefectiveKeyRatio(
                  offerDetails?.positivity
                    ? 100 - Number(offerDetails.positivity)
                    : 0
                ).value
              }
            </DefectiveKeyRating>
          </SellerDetailsCardV3ContentRowValue>
        </SellerDetailsCardV3ContentRow>
      </SellerDetailsCardV3Content>
    </SellerDetailsCardV3Wrapper>
  );
}

export default memo(SellerDetailsCardV3);
