import { useRouter } from 'next/router';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTheme } from 'styled-components';
import { useLocale } from '../../contexts/LocalizationContext';
import { PageLoaderContext } from '../../contexts/PageLoaderContext';
import { usePdpContext } from '../../contexts/PdpPageContext';
import { GWMobileWrapper, GWTabletWrapper } from '../../styles/window.style';
import { MaskIcon } from '../Icons/Icons';
import Select from '../InputComponents/Select';
import {
  AllVariationText,
  Divider,
  NewPdpVariationsMobileBox,
  NewPdpVariationsWrapper,
  NewPdpVariationValue,
  SectionBodyContainer,
  SectionHeading,
  SectionWrapper,
  ShowAllButtonContainer,
  VariationBoxWrapper,
} from './NewPdpVariationsStyle';
import VariationBox from './components/VariationBox';

import dynamic from 'next/dynamic';
import { getSumOfNumericalValuesFromString } from '../../helper/helper';
import Button from '../NewButton/Button';

const RegionDrawerSelector = dynamic(
  () => import('./components/RegionDrawerSelector'),
  { ssr: false }
);

const VariationDrawerSelector = dynamic(
  () => import('./components/VariationDrawerSelector'),
  { ssr: false }
);

function NewPdpVariations() {
  const theme = useTheme();
  const { product, handleShowAllAvailableVariations } = usePdpContext();
  const { messages } = useLocale();
  const router = useRouter();
  const {
    region_msg,
    edition_msg,
    all_variations_msg,
    pdp_value_msg,
    see_all_msg,
  } = messages || {};
  const { dispatchPageLoader } = useContext(PageLoaderContext);

  const [noOfVariationsToShow, setNoOfVariationsToShow] = useState(8);

  const showTotalVariations = useCallback(() => {
    setNoOfVariationsToShow(product?.data?.allVariations?.length ?? 8);
  }, [product?.data?.allVariations]);

  const [showVariationDrawer, setShowVariationDrawer] = useState(false);
  const handleShowVariationDrawer = useCallback(() => {
    setShowVariationDrawer((prev) => !prev);
  }, []);

  const [showRegionDrawer, setShowRegionDrawer] = useState(false);
  const handleShowRegionDrawer = useCallback(() => {
    setShowRegionDrawer((prev) => !prev);
  }, []);

  const variations = useMemo(() => {
    let sortedVariations = [];
    let variations = product?.data?.allVariations ?? [];

    //sort variations
    if (
      product.data?.productTypes?.includes('giftcard') ||
      product.data?.productTypes?.includes('game point')
    ) {
      sortedVariations = variations?.sort((a, b) => {
        return (
          Number(getSumOfNumericalValuesFromString(a.productVersion) ?? 0) -
          Number(getSumOfNumericalValuesFromString(b.productVersion) ?? 0)
        );
      });
    } else {
      sortedVariations = variations?.sort((a, b) => {
        let labelA = a.productVersion.toUpperCase();
        let labelB = b.productVersion.toUpperCase();

        // Ensure 'STANDARD' comes next
        if (labelA === 'STANDARD') return -1;
        if (labelB === 'STANDARD') return 1;

        // Ensure 'GLOBAL' comes first
        if (labelA === 'GLOBAL') return -1;
        if (labelB === 'GLOBAL') return 1;

        // Ensure 'ROW' comes next
        if (labelA === 'ROW') return -1;
        if (labelB === 'ROW') return 1;

        // Alphabetical sorting for all other labels
        return labelA.localeCompare(labelB);
      });
    }
    // sort variations by price and put sold out variations at the end if offer object is not present then it is sold out
    return sortedVariations?.sort((a, b) => {
      if (a?.offer && b?.offer) {
        return a?.offer?.price - b?.offer?.price;
      } else {
        return a?.offer ? -1 : 1;
      }
    });
  }, [product.data?.allVariations, product.data?.productTypes]);

  // Get all distinct available regions
  const availableRegions = useMemo(
    () =>
      Array.from(
        new Set(variations?.map((variation) => variation?.productRegion))
      ),
    [variations]
  );
  const [currentRegion, setCurrentRegion] = useState<string>(
    product?.data?.regions[0]?.name
  );

  useEffect(() => {
    setCurrentRegion(product?.data?.regions[0]?.name);
  }, [product?.data?.regions]);

  const handleSelectVariation = useCallback(
    (slug: string) => {
      dispatchPageLoader({
        type: 'show',
      });
      router.push(`/${slug}`).finally(() => {
        dispatchPageLoader({
          type: 'hide',
        });
      });
    },
    [dispatchPageLoader, router]
  );

  const handleSelectRegion = useCallback(
    (region: string) => {
      setCurrentRegion(region);
      dispatchPageLoader({
        type: 'show',
      });
      router
        .push(
          `/${
            variations?.find(
              (variation) =>
                variation?.productRegion?.toLocaleLowerCase() ===
                region?.toLocaleLowerCase()
            )?.slug
          }`
        )
        .finally(() => {
          dispatchPageLoader({
            type: 'hide',
          });
        });
    },
    [dispatchPageLoader, router, variations]
  );

  const filteredVariations = useMemo(
    () =>
      variations?.filter(
        (variation) =>
          variation?.productRegion?.toLowerCase() ===
          currentRegion?.toLowerCase()
      ),
    [variations, currentRegion]
  );

  return (
    <>
      <GWTabletWrapper>
        <NewPdpVariationsWrapper>
          {availableRegions?.length > 1 && (
            <SectionWrapper style={{ paddingTop: '16px' }}>
              <SectionHeading>{region_msg}</SectionHeading>
              <SectionBodyContainer>
                <Select
                  hideInputInDrawer
                  showDivider
                  options={availableRegions.map((el) => ({
                    value: el,
                    name: el,
                  }))}
                  value={{
                    value: currentRegion,
                    name:
                      currentRegion?.length > 32
                        ? `${currentRegion?.slice(0, 30)}...`
                        : currentRegion,
                  }}
                  handleChange={(option) => {
                    handleSelectRegion(option.value);
                  }}
                  height="40px"
                  style={{
                    borderRadius: '40px',
                    boxShadow: 'none',
                    minWidth: '230px',
                    maxWidth: '330px',
                    background: theme.palette.background.bg1,
                  }}
                  dropDownStyle={{
                    borderRadius: '8px',
                    border: `1px solid ${theme.palette.border.b1}`,
                    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
                  }}
                  valueStyle={{
                    font: '0.875rem/1.125rem Onest-Medium',
                    width: 'max-content',
                  }}
                  dropDownOptionStyle={{
                    font: '0.875rem/1.125rem Onest-Medium',
                    padding: '12px 16px',
                  }}
                  dropDownOptionStyleActive={{
                    font: '0.875rem/1.125rem Onest-Medium',
                    padding: '12px 16px',
                  }}
                  dropDownOptionStyleDisabled={{
                    font: '0.875rem/1.125rem Onest-Medium',
                    padding: '12px 16px',
                  }}
                />
              </SectionBodyContainer>
              <AllVariationText onClick={handleShowAllAvailableVariations}>
                {all_variations_msg}
              </AllVariationText>
            </SectionWrapper>
          )}
          {filteredVariations && filteredVariations.length > 1 ? (
            <>
              <Divider />

              <VariationBoxWrapper>
                {filteredVariations && filteredVariations.length > 8 ? (
                  <>
                    {filteredVariations
                      .slice(0, noOfVariationsToShow)
                      .map((variation, index) => (
                        <VariationBox
                          key={index}
                          variation={variation}
                          selected={variation.slug === product.data.slug}
                          handleSelectVariation={handleSelectVariation}
                        />
                      ))}
                  </>
                ) : (
                  filteredVariations.map((variation, index) => (
                    <VariationBox
                      key={index}
                      variation={variation}
                      selected={variation.slug === product.data.slug}
                      handleSelectVariation={handleSelectVariation}
                    />
                  ))
                )}
              </VariationBoxWrapper>
              {noOfVariationsToShow < filteredVariations.length && (
                <ShowAllButtonContainer>
                  <Button
                    buttonTheme="grey"
                    ariaLabel="view more"
                    onClick={showTotalVariations}
                    style={{
                      borderRadius: '20px',
                      font: '.875/1.125rem Onest-Bold',
                    }}
                    height="40px"
                  >
                    {see_all_msg}
                    <MaskIcon
                      url="/icons/arrow-right-chevron-24.svg"
                      height="20px"
                      width="20px"
                      margin="0 0 0 -4px"
                      color={theme.palette.text.t1}
                      selected={true}
                      style={{ transform: 'rotate(90deg)' }}
                    />
                  </Button>
                </ShowAllButtonContainer>
              )}
            </>
          ) : null}
        </NewPdpVariationsWrapper>
      </GWTabletWrapper>
      <GWMobileWrapper>
        {availableRegions?.length > 1 ||
        (filteredVariations && filteredVariations.length > 1) ? (
          <NewPdpVariationsMobileBox>
            {availableRegions?.length > 1 ? (
              <SectionWrapper>
                <SectionHeading>{region_msg}</SectionHeading>
                <NewPdpVariationValue onClick={handleShowRegionDrawer}>
                  <p>{currentRegion}</p>
                  <MaskIcon
                    url="/icons/arrow-down-chevron-24.svg"
                    width="20px"
                    height="20px"
                    margin="0"
                    selected
                    color={theme.palette.text.t3}
                  />
                </NewPdpVariationValue>
              </SectionWrapper>
            ) : null}
            {filteredVariations && filteredVariations.length > 1 ? (
              <SectionWrapper>
                <SectionHeading>
                  {product.data.productTypes.includes('giftcard') ||
                  product.data.productTypes.includes('game point')
                    ? pdp_value_msg
                    : edition_msg}
                </SectionHeading>
                <NewPdpVariationValue onClick={handleShowVariationDrawer}>
                  <p>
                    {(variations.find(
                      (variation) => variation.slug === product.data.slug
                    )?.productVersion?.length ?? 0) > 30 ? (
                      <>
                        {variations
                          .find(
                            (variation) => variation.slug === product.data.slug
                          )
                          ?.productVersion.substring(0, 28)}
                        ...
                      </>
                    ) : (
                      variations.find(
                        (variation) => variation.slug === product.data.slug
                      )?.productVersion
                    )}
                  </p>
                  <MaskIcon
                    url="/icons/arrow-down-chevron-24.svg"
                    width="20px"
                    height="20px"
                    margin="0"
                    selected
                    color={theme.palette.text.t3}
                  />
                </NewPdpVariationValue>
              </SectionWrapper>
            ) : null}
          </NewPdpVariationsMobileBox>
        ) : null}
        {showRegionDrawer && (
          <RegionDrawerSelector
            open={showRegionDrawer}
            handleClose={handleShowRegionDrawer}
            variations={variations}
            currentRegion={currentRegion}
            availableRegions={availableRegions}
            handleSelectRegion={handleSelectRegion}
          />
        )}
        {showVariationDrawer && (
          <VariationDrawerSelector
            open={showVariationDrawer}
            handleClose={handleShowVariationDrawer}
            variations={filteredVariations}
            currentRegion={currentRegion}
            handleSelectVariation={handleSelectVariation}
          />
        )}
      </GWMobileWrapper>
    </>
  );
}

export default memo(NewPdpVariations);
