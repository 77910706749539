import styled from 'styled-components';

export const PriceNotFinalTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font: 0.75rem/1rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t3};
`;

export const PriceNotFinalTooltipText = styled.div`
  display: flex;
  padding: 4px 2px;
  width: 240px;
  font: 0.875rem/1.125rem Onest-Medium;
`;
