import DOMPurify from 'isomorphic-dompurify';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useLocale } from '../../contexts/LocalizationContext';
import { usePdpContext } from '../../contexts/PdpPageContext';
import { saveEventV3 } from '../../utils/eventTracking';
import ProductGenres from '../ProductGenres/ProductGenres';
import {
  AboutGameContainer,
  AboutGameDescription,
  AboutGameTitle,
  GameDescriptionContainer,
  ProductDescriptionInner,
  ReadMore,
} from '../ProductInformation/ProductInformationStyle';
import { ProductTitle } from '../WebLister/WebListerStyle';

const PDPAboutGame: React.FC = () => {
  const router = useRouter();
  const { product } = usePdpContext();
  const { query } = router;
  const [isReadMore, setIsReadMore] = useState<boolean>(false);
  const productDescriptionRef = useRef<any>(null);
  const [productDescription, setProductDescription] = useState(
    product.data.descriptionLocalized || product.data.description
  );

  const { messages } = useLocale();
  const {
    product_description_msg,
    read_less_msg,
    read_more_msg,
    product_info_not_available_msg,
  } = messages || {};

  const handleReadMoreToggle = () => {
    saveEventV3({
      category: 'pdp_info',
      action: 'click',
      label: 'product_description',
      properties: query.slug as string,
      value: [isReadMore ? 'read_less' : 'read_more'],
      from: router,
    });
    setIsReadMore((prev) => !prev);
  };

  useEffect(() => {
    product.data.descriptionLocalized
      ? setProductDescription(product.data.descriptionLocalized)
      : setProductDescription(product.data.description);
  }, [product.data.description, product.data.descriptionLocalized]);

  return (
    <AboutGameContainer id="product-about-the-game">
      <>
        <AboutGameTitle>
          <h2>{product_description_msg}</h2>
        </AboutGameTitle>
        <GameDescriptionContainer>
          <AboutGameDescription
            ref={productDescriptionRef}
            showFull={isReadMore}
            id="product-description"
          >
            <ProductTitle>{product.data.name}</ProductTitle>
            {product.data?.genres?.length > 0 && (
              <ProductGenres genres={product.data.genres} />
            )}

            {productDescription && productDescription != '' ? (
              <ProductDescriptionInner
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    !isReadMore && productDescription.length > 1000
                      ? productDescription + '<div class="overlay" />'
                      : productDescription + ''
                  ),
                }}
                fixedHeight={!isReadMore && productDescription.length > 1000}
              />
            ) : (
              <GameDescriptionContainer>
                <p>{product_info_not_available_msg}</p>
              </GameDescriptionContainer>
            )}
            {productDescription && productDescription.length > 1000 ? (
              <ReadMore onClick={handleReadMoreToggle} fixedHeight={isReadMore}>
                {isReadMore ? read_less_msg : read_more_msg}
              </ReadMore>
            ) : null}
          </AboutGameDescription>
        </GameDescriptionContainer>
      </>
    </AboutGameContainer>
  );
};

export default PDPAboutGame;
