import Link from 'next/link';
import { memo } from 'react';
import { useTheme } from 'styled-components';
import { COLORS } from '../../../constants/colors';
import { displayCurrencyValue } from '../../../helper/helper';
import { useAppSelector } from '../../../redux/hooks';
import { BackgroundIcon } from '../../Icons/Icons';
import { PriceTypeInterface } from '../../PrimarySellerCardV3/components/PriceBox';
import {
  MembershipPromotionStripContainer,
  MembershipTag,
  MembershipTagBar,
} from './MembershipPromotionStripStyle';

interface MembershipPromotionStripProps {
  selectedPriceType: PriceTypeInterface;
  position: 'top' | 'bottom';
  savingAmount: number;
}

function MembershipPromotionStrip({
  selectedPriceType,
  position,
  savingAmount,
}: MembershipPromotionStripProps) {
  const theme = useTheme();
  const global = useAppSelector((state) => state.global);

  return (
    <Link href={'/plus'} prefetch={false} passHref>
      <a>
        <MembershipPromotionStripContainer
          position={position}
          selectedPriceType={
            selectedPriceType === 'membership' && savingAmount > 0
              ? 'membership'
              : 'regular'
          }
        >
          <BackgroundIcon
            url={`/logo/${
              selectedPriceType === 'regular' ? 'plus-logo' : 'plus-logo-white'
            }.svg`}
            width="24px"
            height="12px"
            margin="0"
          />{' '}
          <MembershipTag
            style={{
              color:
                selectedPriceType === 'regular' ? COLORS.drifflePlus : '#fff',
              backgroundColor:
                selectedPriceType === 'regular'
                  ? theme.mode === 'dark'
                    ? '#292929'
                    : '#f3e7ff'
                  : COLORS.drifflePlus,
            }}
          >
            Membership
          </MembershipTag>
          <MembershipTagBar
            style={{
              backgroundColor:
                selectedPriceType === 'regular'
                  ? theme.mode === 'dark'
                    ? '#292929'
                    : '#f3e7ff'
                  : COLORS.drifflePlus,
            }}
          />
          <p>
            {selectedPriceType === 'membership' && savingAmount > 0
              ? 'Yay! Saving'
              : 'Save extra'}{' '}
            {displayCurrencyValue(
              savingAmount,
              global.locationDetails?.currency_symbol,
              global.locationDetails?.multiplier
            )}
          </p>
        </MembershipPromotionStripContainer>
      </a>
    </Link>
  );
}

export default memo(MembershipPromotionStrip);
