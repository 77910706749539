import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const Container = styled.div`
  position: relative;
  width: 100%;
  & h2 {
    font-size: 18px;
    margin-bottom: 16px;
    margin-top: 0;
  }
  margin-bottom: 32px;

  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 0 16px;
    margin-bottom: 20px;
    & h2 {
      font-size: 16px;
      margin-bottom: 12px;
      margin-top: 0;
    }
  }
`;

export const FBTHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const FBTHeaderTitle = styled.div`
  color: ${(props) => props.theme.palette.text.t1};
  text-transform: capitalize;
  min-height: 32px;
  display: flex;
  align-items: center;

  h3 {
    font: 1.25rem/1.5rem Onest-SemiBold;
    margin: 0;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    h3 {
      font: 1.125rem/1.375rem Onest-SemiBold;
    }
  }
`;

export const FBTBundleNumber = styled.div`
  font: 0.875rem/1.125rem Onest-Medium;
  margin-right: 8px;
  color: ${(props) => props.theme.palette.text.t3};
`;

export const FBTBundleContainer = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: ${WIDTH.tabletMax}) {
    gap: 4px;
  }
`;

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  & > div:last-of-type {
    border-right: 0;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div<{
  isBoosted?: boolean;
}>`
  position: relative;
  padding: 16px 12px;
  width: 100%;
  border: ${(props) =>
    props.isBoosted
      ? '2px solid #9e60ec'
      : '1px solid ' + props.theme.palette.border.b1};
  background: ${(props) => props.theme.palette.background.bg1};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: ${WIDTH.tabletMax}) {
    & > a {
      flex-direction: column;
      height: auto;
      justify-items: center;
    }
  }
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const IconCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: grid;
  place-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  right: -24px;
  border: 1px solid ${(props) => props.theme.palette.border.b2};
  // border: 1px solid #9e60ec;

  background: ${(props) => props.theme.palette.background.bg4};
  color: ${(props) => props.theme.palette.text.t1};
  z-index: 3;

  @media (max-width: ${WIDTH.tabletMax}) {
    right: 50%;
    top: 105%;
    transform: translate(50%, -50%);
  }
`;

export const Subtitle = styled.p`
  color: ${(props) => props.theme.palette.text.t3};
  text-align: center;
  font: 1rem/1.25rem Onest-Medium;
  margin-top: 6px;
`;

export const Price = styled.p<{ length?: number }>`
  color: ${(props) => props.theme.palette.text.t1};
  text-align: center;
  font: ${(props) =>
      props.length && props.length < 16 ? '1.75rem/1.75rem' : '1.625/1.625'}
    Onest-Bold;
  margin-bottom: 2px;
`;

export const ImageContainer = styled.div`
  width: 100px;
  height: 140px;
  position: relative;
  & img {
    border-radius: 8px;
  }
`;

export const ProductTitle = styled.div`
  color: ${(props) => props.theme.palette.text.t1};
  // text-align: center;
  font-family: Onest-Bold;
  font-size: 15px;
  margin-bottom: 4px;
  line-height: 19px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ProductPrice = styled.p<{ length?: number }>`
  color: ${(props) => props.theme.palette.text.t1};
  font-family: Onest-Bold;
  font-size: ${(props) =>
    props.length && props.length <= 13 ? '20px' : '16px'};
  line-height: 24px;
`;

export const DiscountPrice = styled.p`
  color: ${(props) => props.theme.palette.success.main};
  font-size: 16px;
  line-height: 20px;
  font-family: Onest-Medium;
  text-align: center;
`;

export const ProductMrp = styled.p<{ length?: number }>`
  color: ${(props) => props.theme.palette.text.t3};
  font-family: Onest-Medium;
  font-size: 14px;
  line-height: 18px;
  text-decoration: line-through;
`;

export const MobileCardContainer = styled.div`
  & > a > div {
    background: ${(props) => props.theme.palette.background.bg1};
    :hover {
      background: ${(props) => props.theme.palette.background.bg1};
    }
  }
`;

export const SpecialOfferTag = styled.div`
  position: absolute;
  top: -16px;
  right: -4px;
`;

export const PromoCodeText = styled.div`
  margin-top: 6px;
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t3};
  span {
    font: 1rem/1.25rem Onest-Bold;
    color: #9e60ec;
  }
  text-align: center;
`;

export const SpecialOfferText = styled.div`
  height: 24px;
  width: fit-content;
  text-transform: uppercase;
  text-wrap: nowrap;
  color: #9e60ec;
  display: flex;
  align-items: center;
  padding: 0 6px;
  border-radius: 4px;
  min-width: 105px;
  border: 2px solid #9e60ec;
  font: 0.75rem/1rem Onest-Bold;
  position: absolute;
  top: -12px;
  right: 100px;
  background: ${(props) => props.theme.palette.background.bg1};
  @media (max-width: ${WIDTH.tabletMax}) {
    bottom: -12px;
    left: 50%;
    transform: translate(-50%, 0);
    top: auto;
  }
`;

export const MoreDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  p {
    font: 1.25rem/1.5rem Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    gap: 16px;
  }
`;

export const MoreActionDetails = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
`;

export const MoreDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  justify-content: space-between;
`;

export const MoreDetailsTitle = styled.div`
  font: 0.75rem/1rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t3};
  height: 12px;
`;

export const MoreDetailsValue = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;
  font: 1.25rem/1.25rem Onest-SemiBold;
  color: ${(props) => props.theme.palette.text.t1};
  span {
    font: 0.875rem/1.125rem Onest-Medium;
    color: ${(props) => props.theme.palette.success.main};
  }
  p {
    font: 0.875rem/1.125rem Onest-Medium;
    color: #9e60ec;
  }
`;

export const DisplayDiscountContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  @media (max-width: ${WIDTH.tabletMax}) {
    flex-direction: column;
    gap: 0;
  }
`;
