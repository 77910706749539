import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo } from 'react';
import { saveEventV3 } from '../../utils/eventTracking';
import {
  ProductTypeTagText,
  ProductTypeTagWrapper,
} from './ProductTypeTagStyle';

interface Props {
  productTypes: Array<string>;
  padding?: string;
  fontSize?: number;
  lineHeight?: number;
}

function ProductTypeTag({
  fontSize,
  lineHeight,
  productTypes,
  padding,
}: Props) {
  const router = useRouter();
  return (
    <Link
      href={getProductTypeTagStyle(productTypes)?.link ?? '/store'}
      passHref
      prefetch={false}
    >
      <a>
        <ProductTypeTagWrapper
          onClick={() => {
            saveEventV3({
              action: 'click',
              category: 'pdp',
              label: 'product_type_tag',
              value: [getProductTypeTagStyle(productTypes).text ?? '/store'],
              from: router,
              properties: getProductTypeTagStyle(productTypes).link,
            });
          }}
          bgColor={getProductTypeTagStyle(productTypes)?.bgColor ?? '#5539CC'}
          padding={padding ?? '4px 8px'}
        >
          <ProductTypeTagText
            fontSize={fontSize ?? 0.75}
            lineHeight={lineHeight ?? 1}
          >
            {getProductTypeTagStyle(productTypes)?.text}
          </ProductTypeTagText>
        </ProductTypeTagWrapper>
      </a>
    </Link>
  );
}

export default memo(ProductTypeTag);

const getProductTypeTagStyle = (productTypes: string[]) => {
  switch (productTypes.join(',')) {
    case 'game':
      return {
        bgColor: '#5539CC',
        textColor: '#FFFFFF',
        text: 'Game',
        link: '/store?productType[]=game',
      };
    case 'dlc,game':
    case 'game,dlc':
      return {
        bgColor: '#7A81FF',
        textColor: '#FFFFFF',
        text: 'Game + DLC',
        link: '/store?productType[]=game&productType[]=dlc',
      };
    case 'giftcard':
      return {
        bgColor: '#FF7F6A',
        textColor: '#FFFFFF',
        text: 'Gift Card',
        link: '/store?productType[]=giftcard',
      };
    case 'dlc':
      return {
        bgColor: '#F18D31',
        textColor: '#FFFFFF',
        text: 'DLC',
        link: '/store?productType[]=dlc',
      };
    case 'game point':
      return {
        bgColor: '#A10FD4',
        textColor: '#FFFFFF',
        text: 'Game Points',
        link: '/store?productType[]=game point',
      };
    case 'subscription':
      return {
        bgColor: '#24BCA8',
        textColor: '#FFFFFF',
        text: 'Subscription',
        link: '/store?productType[]=subscription',
      };

    case 'software':
      return {
        bgColor: '#90324B',
        textColor: '#FFFFFF',
        text: 'Software',
        link: '/store?productType[]=software',
      };
    default:
      return {
        bgColor: '#5539CC',
        textColor: '#FFFFFF',
        text: productTypes.join(','),
        link: '/store',
      };
  }
};
