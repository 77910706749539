import Link from 'next/link';
import { memo } from 'react';
import { useLocale } from '../../../contexts/LocalizationContext';
import { displayCurrencyValue } from '../../../helper/helper';
import { AllVariationInterface } from '../../../Interface/ProductDetailsInterface';
import { useAppSelector } from '../../../redux/hooks';
import TooltipWithArrow from '../../ToolTipWithArrow/TooltipWithArrow';
import {
  VariationBodyPrice,
  VariationBoxBody,
  VariationBoxContainer,
  VariationBoxHeader,
  VariationBoxInput,
  VariationBoxInputContainer,
  VariationBoxTitle,
} from '../NewPdpVariationsStyle';

interface VariationBoxProps {
  variation: AllVariationInterface;
  selected: boolean;
  handleSelectVariation: (slug: string) => void;
}

function VariationBox({
  variation,
  selected,
  handleSelectVariation,
}: VariationBoxProps) {
  const { messages } = useLocale();
  const { sold_out_msg } = messages || {};
  const { locationDetails } = useAppSelector((state) => state.global);
  return (
    <Link href={`/${variation.slug}`} prefetch={false} passHref>
      <a>
        <VariationBoxContainer
          selected={selected}
          onClick={() => handleSelectVariation(variation.slug)}
        >
          {/* <VariationBoxContainerBgShade /> */}
          <VariationBoxHeader>
            <TooltipWithArrow title={<>{variation.productVersion}</>}>
              <VariationBoxTitle>
                {variation.productVersion.length > 22
                  ? variation.productVersion.substring(0, 20) + '...'
                  : variation.productVersion}
              </VariationBoxTitle>
            </TooltipWithArrow>
            <VariationBoxInputContainer>
              <VariationBoxInput
                type="radio"
                checked={selected}
                onChange={() => {}}
              />
            </VariationBoxInputContainer>
          </VariationBoxHeader>
          <VariationBoxBody>
            <VariationBodyPrice>
              {variation?.offer?.price ? (
                <>
                  <span>from</span>{' '}
                  {displayCurrencyValue(
                    variation?.offer?.price,
                    locationDetails?.currency_symbol,
                    locationDetails?.multiplier
                  )}
                </>
              ) : (
                sold_out_msg
              )}
            </VariationBodyPrice>
          </VariationBoxBody>
        </VariationBoxContainer>
      </a>
    </Link>
  );
}

export default memo(VariationBox);
