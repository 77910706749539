import React from 'react';
import styled, { keyframes } from 'styled-components';
import { LocalesType } from '../../Interface/LocalesInterface';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';
import { PriceTypeInterface } from './components/PriceBox';

export const CardWrapper = styled.div<{ isLowest: boolean }>`
  position: relative;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: fit-content;

  min-width: 300px;
  width: auto;
  margin-bottom: 12px;

  transition: 0.3s;
  @media (max-width: 1024px) {
    min-width: 100;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-bottom: 16px;
    width: 100%;
    // padding: 16px;
  }
`;

interface ButtonCompProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  // width?: string;
  // height?: string;
  // buttonType?: 'solid' | 'outlined' | 'open';
  // buttonTheme?: 'primary' | 'grey' | 'black' | 'lightGrey';
  // customTheme?: ButtonThemeI;
  // disabled?: boolean;
}

export const PdpNotifyMeActiveButton = styled.button<ButtonCompProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.24);
  color: #000;
  font: 0.875rem/1.125rem Onest-Bold;
  cursor: pointer;
  transition: 0.3s;
  gap: 8px;
  opacity: 1;
  &:active {
    transform: scale(0.95);
  }
  & p,
  span,
  div {
    font-family: Onest-Bold;
    font-size: 1rem;
    line-height: 1rem;
  }
`;
// top: ${({ sidebarPosition }) => sidebarPosition === 'relative' ? '0' : '93px'};
export const CardContainer = styled.div`
  width: 343px;
  margin-top: 45px;
  padding-bottom: 0px;
  z-index: 10;
  @media (max-width: ${WIDTH.tabletMax}) {
    margin-top: 0;
    margin: 0 16px;
    width: auto;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin: 0 16px;
    position: static;
    padding-bottom: 0;
    width: auto;
  }
`;

export const CardTitle = styled.div``;

export const SellerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 12px;

  @media (max-width: ${WIDTH.mobileMax}) {
    padding-top: 8px;
    padding-bottom: 0px;
  }
`;

export const SellerAvatarContainer = styled.div`
  display: flex;
  align-items: center;
  max-height: 64px;
  /* background-color: ${COLORS.primaryLight1};
  border-radius: 6px; */
`;

export const SellerDetailsContainer = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

export const SellerTitleContainer = styled.div`
  font-family: Onest-Bold;
  font-size: 16px;
  line-height: 19px;
  color: ${COLORS.blackLight1};
  flex: 1;
`;

export const SellerNameContainer = styled.div`
  font-family: Onest-Bold;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${COLORS.black};
  flex: 1;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SellerHistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  // border-right: 1px solid ${COLORS.greyLight};
  padding: 0 5px 0 0;
  font-style: normal;
  font-family: Onest-Regular;
  font-size: 16px;
  line-height: 19px;
  gap: 4px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${COLORS.greyDark};
  & > span {
    font-family: Onest-Bold;
    color: ${COLORS.black};
  }
`;

export const OrdersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 10px;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.greyDark};
`;

export const CardG = styled.div<{
  isLowest: boolean;
  hasOfferSubscriptionDiscount: boolean;
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: ${(props) => props.theme.palette.background.bg1};
  border-left: ${(props) =>
    props.isLowest
      ? '3px solid #e4b90a'
      : `1px solid ${props.theme.palette.border.b1}`};
  border-right: ${(props) =>
    props.isLowest
      ? '3px solid #e4b90a'
      : `1px solid ${props.theme.palette.border.b1}`};

  border-bottom: ${(props) =>
    props.hasOfferSubscriptionDiscount
      ? 'none'
      : props.isLowest
      ? '3px solid #e4b90a'
      : `1px solid ${props.theme.palette.border.b1}`};

  border-radius: ${(props) =>
    props.hasOfferSubscriptionDiscount ? '0 0' : '0 0 12px 12px '};

  padding: 12px;
`;

export const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (max-width: ${WIDTH.mobileMax}) {
  }
`;

export const CardTooltipContainer = styled.div`
  display: flex;
  padding: 8px 4px;
  text-align: center;
  font: 0.875rem/1.125rem Onest-Medium;
`;

export const PlusServiceChargeText = styled.div`
  display: flex;
  font: 0.75rem/0.875rem Onest-Medium;
  align-items: center;
  color: ${(props) => props.theme.palette.text.t2};
  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 4px 0 0;
  }
`;

export const ButtonsWrapper = styled.div`
  justify-self: flex-end;
  @media (max-width: ${WIDTH.mobileMid}) {
    // display: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
  @media (max-width: ${WIDTH.tabletMax}) {
    flex-direction: column;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-direction: column;
    max-width: 100%;
    display: none;
  }
`;

export const MobileButtonsContainer = styled.div<{
  active: boolean;
}>`
  display: flex;
  flex-direction: row-reverse;
  gap: 12px;
  opacity: ${(props) => (props.active ? 1 : 0.6)};
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-top: 12px;
  }
`;

export const MobileAddToCartButton = styled.div`
  width: fit-content;
  > button {
    background-color: ${COLORS.gray1};
  }
`;
export const MobileBuyNowButton = styled.div`
  width: 100%;

  & > button {
    background: #535ce8;
    &:hover {
      background: #535ce8;
      opacity: 0.9;
    }
    &:active {
      background: #535ce8;
      opacity: 0.9;
    }
  }
`;

export const OtherSellerOffers = styled.div`
  color: ${COLORS.black};
  margin-top: 20px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: ${COLORS.greyDark};
  }
`;

export const PriceContainer = styled.div<{
  selectedPriceType: PriceTypeInterface;
  priceType: PriceTypeInterface;
  isActiveSubscription: boolean;
  selectedRadioOffer: boolean;
}>`
  display: flex;
  flex-direction: row;
  gap: 4px;
  min-height: ${(props) => (props.isActiveSubscription ? '40px' : '56px')};
  justify-content: space-between;
  align-items: center;
  border: ${(props) => (props.isActiveSubscription ? 'none' : '2px solid')};
  padding: ${(props) => (props.isActiveSubscription ? 0 : '8px')};
  border-radius: 8px;
  cursor: ${(props) => (props.isActiveSubscription ? 'default' : 'pointer')};
  border-color: ${(props) =>
    props.selectedRadioOffer
      ? props.selectedPriceType === props.priceType
        ? props.priceType === 'membership'
          ? '#9D60EA'
          : props.theme.palette.border.b2
        : 'transparent'
      : 'transparent'};
`;

export const ButtonContainer = styled.div<{
  selectedPriceType?: PriceTypeInterface;
}>`
  width: 100%;
  > button {
    ${(props) =>
      props.selectedPriceType &&
      `background: ${
        props.selectedPriceType === 'membership'
          ? 'radial-gradient(100% 100% at 0% 0%, #B67EFDdd 0%, #7626DCdd 68.08%)'
          : props.theme.palette.primary.main
      };`}
    :hover {
      ${(props) =>
        props.selectedPriceType &&
        props.selectedPriceType === 'membership' &&
        `background: radial-gradient(100% 100% at 0% 0%, #B67EFD 0%, #7626DC 68.08%);`}
    }
  }

  // @media (max-width: ${WIDTH.tabletMax}) {
  //   margin-right: 10px;
  // }
`;

export const PriceInfoPillsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;

interface PriceInfoPillProps {
  backgroundColor: string;
  color: string;
  locale: LocalesType;
}

export const PriceInfoPill = styled.div<PriceInfoPillProps>`
  font-family: ${(props) =>
    props.locale === 'fr' || props.locale === 'it' || props.locale === 'de'
      ? 'Onest-SemiBold'
      : 'Onest-Bold'};
  font-size: ${(props) =>
    props.locale === 'fr' || props.locale === 'it' || props.locale === 'de'
      ? '12px'
      : '14px'};
  line-height: 16px;
  background: transparent;
  border: 2px solid ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  ${(props) =>
    props.theme.mode === 'dark' && `border: 2px solid ${props.color};`}
  width: fit-content;
  padding: 8px 14px 8px 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  @media (max-width: ${WIDTH.mobileMax}) {
    font-family: Onest-SemiBold;
    font-size: 14px;
  }
`;

export const PriceName = styled.div`
  font-family: Onest-Bold;
  font-size: 14px;
  line-height: 16px;
  background: ${COLORS.purple2};
  color: ${COLORS.purple};
  width: fit-content;
  font-size: 14px;
  padding: 8px 16px 8px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
`;

export const NotifyMeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  border-radius: 12px 12px;
  border-left: ${(props) => `1px solid ${props.theme.palette.border.b1}`};
  border-top: ${(props) => `1px solid ${props.theme.palette.border.b1}`};
  border-right: ${(props) => `1px solid ${props.theme.palette.border.b1}`};
  border-bottom: ${(props) => `1px solid ${props.theme.palette.border.b1}`};
  padding: 16px;
  color: ${(props) => props.theme.palette.text.t1};
  & > h2 {
    font-size: 1.25rem;
    font-family: Onest-SemiBold;
    line-height: 1.5rem;
    margin: 0 0 16px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    align-items: start;
  }
`;

export const NotifyMeText = styled.div`
  font: 0.875rem/1.125rem Onest-Medium;
  margin-bottom: 16px;
  color: ${(props) => props.theme.palette.text.t2};
`;

export const OtherOffersContainer = styled.div`
  margin-bottom: 12px;
  width: fit-content;
  & div:first-of-type {
    background: ${(props) =>
      props.theme.mode === 'dark' ? '#042162' : '#dde8ff'};
    color: ${(props) =>
      props.theme.mode !== 'dark'
        ? props.theme.palette.primary.main
        : '#4885FF'};
    border-radius: 16px;
    height: 27px;
    line-height: 1.125rem;
    font-size: 0.875rem;
    ${(props) =>
      props.theme.mode === 'light'
        ? 'font-family: Onest-SemiBold;'
        : 'font-family: Onest-SemiBold;'}
    padding: 5px 11px 5px 8px;
    width: fit-content;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    & span {
      font-family: Onest-SemiBold !important;
    }
    &:active {
      transform: scale(0.98);
    }
  }
`;

export const SecurePaymentBanner = styled.div`
  color: ${COLORS.purple};
  padding: 16px;
  border-radius: 8px;
  background: #e3e5fb;
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 21px;
  max-width: 300px;
  & > p {
    max-width: 228px;
    font-size: 14px;
    line-height: 18px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    display: none;
  }
`;

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 25%;
  }
  50% {
    background-position: 50% 25%;
  }
  100%{
     background-position: 100% 50%;
  }
  

`;

export const StoreTagWrapper = styled.div`
  position: absolute;
  right: -12px;
  top: -12px;
  z-index: 10;

  // > div {

  //   position: relative;
  //   > p {
  //     position: absolute;
  //     top: 7px;
  //     right: 9px;
  //     margin: 0;
  //     font: 12px Onest-SemiBold;
  //     color: ${COLORS.white};
  //   }
  // }
`;

export const StoreTagContent = styled.div`
  background: linear-gradient(
    135deg,
    #cfa80b,
    #c67a05,
    #cfa80b,
    #c67a05,
    #cfa80b,
    #c67a05,
    #cfa80b
  );
  border-radius: 0 4px 0 0;
  height: 26px;
  width: 121px;
  justify-content: center;
  position: absolute;
  right: -9px;
  top: -10px;
  z-index: 50;
  background-size: 400% 400%;
  display: flex;
  align-items: center;
  clip-path: polygon(100% -1%, 100% 98%, 14% 123%, 21% 51%, 16% 0%);
  animation: ${gradientAnimation} 2s linear infinite;
  div {
    top: 7px;
    right: 9px;
    margin: 0;
    font: 12px Onest-SemiBold;
    color: ${COLORS.white};
  }
`;

export const AllTimeLowestPriceInfoPill = styled.div`
  width: 124px;
  height: 32px;
  background: linear-gradient(
    135deg,
    #cfa80b,
    #c67a05,
    #cfa80b,
    #c67a05,
    #cfa80b,
    #c67a05,
    #cfa80b
  );
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 400% 400%;
  // animation: ${gradientAnimation} 2s linear infinite;
  border-radius: 8px;
  p {
    font-family: Onest-Bold;
    font-size: 12px;
    font-style: normal;
    color: #fff;
    line-height: 20px; /* 166.667% */
    text-transform: uppercase;
  }
`;

export const PriceValueContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const PriceValueActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const BuyBoxPlusTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 0 10px;
  border-radius: 12px;
  border: 2px solid #9d60ea;
`;

export const PriceSelectInputRadio = styled.input<{ fillColor: string | null }>`
  height: 20px;
  width: 20px;
  appearance: none;
  border: 2px solid
    ${(props) => (props.theme.mode === 'dark' ? '#444444' : '#d9d9d9')};
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;

  &:checked {
    border-color: ${(props) =>
      props.fillColor ? props.fillColor : props.theme.palette.primary.main};
  }

  &:before {
    content: '';
    height: 12px; /* Adjust the size for the inner white circle */
    width: 12px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.checked
        ? props.fillColor
          ? props.fillColor
          : props.theme.palette.primary.main
        : 'transparent'};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.2s ease-in-out;
  }
  &:checked:before {
    transform: translate(-50%, -50%) scale(1);
  }
`;

interface PriceProps {
  isLowest: boolean;
  isLong: number;
  isActiveSubscription: boolean;
}

export const PriceValueV2 = styled.div<PriceProps>`
  font-family: Onest-SemiBold;
  font-size: ${(props) =>
    props.isActiveSubscription
      ? '32px'
      : props.isLong > 15
      ? '21px'
      : props.isLong > 12
      ? '20px'
      : '24px'};
  line-height: ${(props) =>
    props.isLong > 15 ? '21px' : props.isLong > 12 ? '24px' : '28px'};
  color: ${(props) =>
    props.isLowest ? `transparent` : props.theme.palette.text.t1};
  background: ${(props) =>
    props.isLowest
      ? 'linear-gradient(97.73deg, #CFA80B 3.19%, #C67A05 88.09%, #CFA80B 172.93%, #C67A05 257.86%, #CFA80B 342.66%)'
      : 'none'};
  -webkit-background-clip: ${(props) => (props.isLowest ? 'text' : 'none')};
  background-clip: ${(props) => (props.isLowest ? 'text' : 'none')};
  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: ${(props) =>
      props.isLong > 15 ? '18px' : props.isLong > 11 ? '21px' : '28px'};
  }
`;

export const DiscountPercent = styled.p<{ isLong: number }>`
  color: #01b401;
  font-size: ${(props) => (props.isLong >= 11 ? '.75rem' : '1rem')};
  font-family: Onest-Medium;
  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: ${(props) => (props.isLong >= 11 ? '0.75rem' : '1rem')};
  }
`;

export const SellerRatingComponent = styled.div`
  display: flex !important;
  align-items: center !important;
  gap: 2px !important;
  p {
    font-size: 12px;
    line-height: 16px;
    font-family: Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
  }
  span {
    font-size: 12px;
    line-height: 16px;
    font-family: Onest-Medium;
    color: ${(props) => props.theme.palette.text.t3};
  }
`;

export const SellerNameComponent = styled.div`
  display: flex;
  align-items: center !important;
  & > p:first-of-type {
    line-height: 16px;
    font-size: 12px;
    color: ${(props) => props.theme.palette.text.t2};
    a {
      text-decoration: none;
      span {
        color: ${(props) => props.theme.palette.text.t1};
        font: 0.875rem/1.125rem Onest-SemiBold;
        &:hover {
          color: ${(props) => props.theme.palette.primary.main};
        }
      }
    }
  }
  & > p:last-of-type {
    color: ${(props) => props.theme.palette.text.t2};
    line-height: 16px;
    font-size: 12px;
    span {
      font: 0.875rem/1.125rem Onest-SemiBold;
      color: ${(props) => props.theme.palette.text.t1};
    }
  }
`;

export const CardTopStrip = styled.div<{ borderColor: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${(props) => props.borderColor};
  border-bottom: none;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
`;

export const LowestPriceStrip = styled.div<{ isPremium: boolean }>`
  width: 100%;
  justify-content: center;
  height: 32px;
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.isPremium
      ? 'linear-gradient(97.73deg, #CFA80B 3.19%, #C67A05 88.09%, #CFA80B 172.93%, #C67A05 257.86%, #CFA80B 342.66%)'
      : props.theme.mode === 'dark'
      ? '#042162'
      : '#dde8ff'};

  // background-size: 400% 400%;
  // animation: ${gradientAnimation} 6s linear infinite;

  p {
    text-transform: uppercase;
    font: 0.75rem/1rem Onest-SemiBold;
    color: ${(props) =>
      props.isPremium
        ? '#FFFFFF'
        : props.theme.mode === 'dark'
        ? '#4885FF'
        : props.theme.palette.primary.main};
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 24px;
    p {
      font: 0.625rem/0.875rem Onest-SemiBold;
    }
  }
`;

export const PromotedOfferStrip = styled.div`
  display: flex;
  height: 32px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid ${(props) => props.theme.palette.border.b1};
  background: ${(props) => props.theme.palette.background.bg1};
  p {
    font-size: 12px;
    line-height: 16px;
    font-family: Onest-Medium;
    color: #9a9a9a;
    letter-spacing: 0.3px;
  }
`;

export const ServiceFeeText = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  font: 0.75rem/1rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t3};
  // margin-top: 4px;
`;
