import { memo } from 'react';
import { usePdpContext } from '../../contexts/PdpPageContext';
import { ProductWrapper } from '../../styles/pageStyles/pdpStyle';
import { GWMobileWrapper } from '../../styles/window.style';
import NewPdpVariations from '../NewPdpVariations/NewPdpVariations';
import ProductImportantNoticeBanner from '../ProductImportantNoticeBanner';
import ProductInformation from '../ProductInformation/ProductInformation';
import { DetailsSectionContainer } from './ProductDetailsSectionStyle';

interface Props {
  setProductImageModal: (x: boolean) => void;
  setIndex: (x: number) => void;
}

const ProductDetailsSection = (props: Props) => {
  const { product } = usePdpContext();
  return (
    <DetailsSectionContainer>
      <GWMobileWrapper>
        <>
          {/* <ProductImportantDetails /> */}
          <div style={{ padding: '0 16px' }}>
            {/* <PdpVariations /> */}
            <NewPdpVariations />
          </div>
          <ProductWrapper>
            {product.data.notice && (
              <ProductImportantNoticeBanner notice={product.data.notice} />
            )}
          </ProductWrapper>
        </>
      </GWMobileWrapper>

      <ProductInformation
        setProductImageModal={props.setProductImageModal}
        setIndex={props.setIndex}
      />
    </DetailsSectionContainer>
  );
};

export default memo(ProductDetailsSection);
