import { isNumber } from 'lodash';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ThemeContext } from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';
import { useLocale } from '../../contexts/LocalizationContext';
import {
  NotifyMeProductInterface,
  useNotifyMeProducts,
} from '../../contexts/NotifyMeProductsContext';
import { usePdpContext } from '../../contexts/PdpPageContext';
import useSubscriptionDiscount from '../../hooks/pdp/useSubscriptionDiscount';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAppSelector } from '../../redux/hooks';
import { GWMobileWrapper, GWWebWrapper } from '../../styles/window.style';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';

import MembershipPromotionStrip from '../Membership/MembershipPromotionStrip/MembershipPromotionStrip';
import NewsletterPdp from '../Newsletter/NewsletterPdp';
import PriceNotFinalText from '../PriceNotFinalText/PriceNotFinalText';
import SpecialInfoCardsPdp from '../SpecialInfoCardsPdp/SpecialInfoCardsPdp';
import {
  CardContainer,
  CardG,
  CardTopStrip,
  CardWrapper,
  PriceInfo,
  PromotedOfferStrip,
} from './PrimarySellerCardv3Styles';
import BuyActions from './components/BuyActions';
import NotifyMeBox from './components/NotifyMeBox';
import OtherOfferStrip from './components/OtherOfferStrip';
import PriceBox, { PriceTypeInterface } from './components/PriceBox';
import SellerInfoDetails from './components/SellerInfoDetails';

const NotifyMeRecommendationDrawer = dynamic(
  () => import('../NotifyMeRecommendation/NotifyMeRecommendationDrawer'),
  { ssr: false }
);

const NotifyMeRecommendationPopUp = dynamic(
  () => import('../NotifyMeRecommendation/NotifyMeRecommendationPopUp'),
  { ssr: false }
);

const ProductAddedModal = dynamic(
  () => import('../ProductAddedModal/ProductAddedModal'),
  {
    ssr: false,
  }
);

const ProductCheckoutConfirmationMobile = dynamic(
  () =>
    import(
      '../ProductCheckoutConfirmationMobile/ProductCheckoutConfirmationMobile'
    ),
  { ssr: false }
);

const PrimarySellerCardV3 = () => {
  const { messages } = useLocale();
  const { lowest_price_msg } = messages || {};

  const { width } = useWindowDimensions();
  const theme = useContext(ThemeContext);
  const user = useAppSelector((state) => state.user);
  const cart = useAppSelector((state) => state.cart);
  const router = useRouter();

  const {
    product,
    primarySeller: seller,
    selectedRadioOffer,
    setToggleSubscriptionOffer,
    toggleSubscriptionOffer,
  } = usePdpContext();

  const [isSellerCentric, setIsSellerCentric] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  const [notifyMeStatus, setNotifyMeStatus] = useState(false);
  const [notifyMeLoading, setNotifyMeLoading] = useState(false);

  const [hasOfferSubscriptionDiscount, setHasOfferSubscriptionDiscount] =
    useState(Number(seller?.plusDiscount ?? 0) > 0);

  // const [selectedPriceType, setSelectedPriceType] =
  //   useState<PriceTypeInterface>(
  //     (user.subscription === 'active' || cart.subscription) &&
  //       hasOfferSubscriptionDiscount
  //       ? 'membership'
  //       : 'regular'
  //   );

  useEffect(() => {
    if (Number(seller?.plusDiscount ?? 0) > 0) {
      setHasOfferSubscriptionDiscount(true);
    } else {
      setHasOfferSubscriptionDiscount(false);
    }
  }, [seller?.plusDiscount]);

  useEffect(() => {
    if (
      (user.subscription === 'active' || cart.subscription) &&
      hasOfferSubscriptionDiscount
    ) {
      setToggleSubscriptionOffer('membership');
      setToggleSubscriptionOffer('membership');
    } else {
      setToggleSubscriptionOffer('regular');
      setToggleSubscriptionOffer('regular');
    }
  }, [
    cart.subscription,
    hasOfferSubscriptionDiscount,
    setToggleSubscriptionOffer,
    user.subscription,
  ]);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const [showRecommendationPopUp, setShowRecommendationPopUp] =
    useState<boolean>(false);

  const handleShowRecommendationPopUp = useCallback(() => {
    setShowRecommendationPopUp(!showRecommendationPopUp);
  }, [showRecommendationPopUp]);

  useEffect(() => {
    if (
      seller &&
      router.query.seller &&
      isNumber(Number(router.query.seller))
    ) {
      if (Number(seller.sellerId) === Number(router.query.seller)) {
        setIsSellerCentric(true);
      }
    }
  }, [seller, router]);

  const { notifyProducts, handleNotifyProductClick } = useNotifyMeProducts();

  const handleClickNotifyButton = useCallback(
    async (action: 'add' | 'remove') => {
      if (product?.data?.productId) {
        setNotifyMeLoading(true);
        let productObj = {
          productId: product?.data?.productId,
          title: product?.data?.name,
          slug: product?.data?.slug,
          image: product?.data?.coverImage,
          platform: product?.data?.platform,
          price: product?.data?.mrp ?? null,
          regionId: product?.data?.regions[0]?.id ?? null,
        };
        const response = await handleNotifyProductClick(productObj, action);
        if (response) {
          if (action === 'add') {
            setNotifyMeStatus(true);
            // dispatchSnackbar({
            //   type: 'success',
            //   payload: we_will_email_you_when_this_product_is_back_in_stock,
            // });
            setShowRecommendationPopUp(true);
          } else {
            setNotifyMeStatus(false);
          }
        }
        setNotifyMeLoading(false);
      }
    },
    [
      handleNotifyProductClick,
      product?.data?.coverImage,
      product?.data?.platform,
      product?.data?.mrp,
      product?.data?.productId,
      product?.data?.regions,
      product?.data?.slug,
      product?.data?.name,
    ]
  );

  useEffect(() => {
    if (
      notifyProducts &&
      product?.data?.slug &&
      notifyProducts
        .map((p: NotifyMeProductInterface) => p.slug)
        .includes(product?.data?.slug)
    ) {
      setNotifyMeStatus(true);
    } else {
      setNotifyMeStatus(false);
    }
  }, [notifyProducts, product?.data?.slug]);

  const discount = useMemo(() => {
    if (product && seller && Number(product.data.mrp) > Number(seller.price)) {
      const calculatedDiscount = Math.round(
        ((Number(product.data.mrp) - Number(seller.price)) * 100) /
          Number(product.data.mrp)
      );

      if (calculatedDiscount > 0) {
        if (calculatedDiscount > 99) {
          return 99;
        }
        return calculatedDiscount;
      } else {
        return 1; // Return at least 1% discount
      }
    }

    return null; // Return null if any of the required conditions are not met
  }, [product, seller]);

  const { subscriptionDiscount } = useSubscriptionDiscount({
    offer: seller,
    mrp: product.data.mrp,
  });

  const cardTopStrip = useMemo(() => {
    return (
      <CardTopStrip
        borderColor={
          product.data?.offers?.length > 0
            ? product.data.offers?.filter(
                (offer) => offer.isFeatured && seller?.offerId === offer.offerId
              ).length === 1
              ? theme.palette.border.b1
              : theme.palette.border.b1
            : 'transparent'
        }
      >
        {product.data?.offers?.length > 0 ? (
          product.data.offers?.filter(
            (offer) => offer.isFeatured && seller?.offerId === offer.offerId
          ).length === 1 ? (
            <PromotedOfferStrip>
              <p>Promoted Offer</p>
            </PromotedOfferStrip>
          ) : (
            <PromotedOfferStrip>
              <p>{lowest_price_msg}</p>
            </PromotedOfferStrip>
          )
        ) : null}
      </CardTopStrip>
    );
  }, [
    seller?.offerId,
    product.data.offers,
    theme.palette.border.b1,
    lowest_price_msg,
  ]);

  const handleChangePriceTypeSelect = useCallback(
    (variant: PriceTypeInterface) => {
      // setSelectedPriceType(variant);
      setToggleSubscriptionOffer(variant);
    },
    [setToggleSubscriptionOffer]
  );

  return (
    <>
      <CardContainer>
        <CardWrapper isLowest={false}>
          {cardTopStrip}
          {seller ? (
            <>
              <CardG
                isLowest={false}
                hasOfferSubscriptionDiscount={hasOfferSubscriptionDiscount}
              >
                <PriceInfo style={{ marginBottom: '8px' }}>
                  {user.subscription === 'active' &&
                  hasOfferSubscriptionDiscount ? (
                    <PriceBox
                      discount={discount}
                      selectedPriceType={toggleSubscriptionOffer}
                      priceType={'membership'}
                      handleClick={handleChangePriceTypeSelect}
                      subscriptionDiscount={subscriptionDiscount}
                      hasOfferSubscriptionDiscount={
                        hasOfferSubscriptionDiscount
                      }
                      offer={seller}
                    />
                  ) : hasOfferSubscriptionDiscount ? (
                    <>
                      <PriceBox
                        discount={discount}
                        selectedPriceType={toggleSubscriptionOffer}
                        priceType={'regular'}
                        handleClick={handleChangePriceTypeSelect}
                        subscriptionDiscount={subscriptionDiscount}
                        hasOfferSubscriptionDiscount={
                          hasOfferSubscriptionDiscount
                        }
                        offer={seller}
                      />

                      <PriceBox
                        discount={discount}
                        selectedPriceType={toggleSubscriptionOffer}
                        priceType={'membership'}
                        handleClick={handleChangePriceTypeSelect}
                        subscriptionDiscount={subscriptionDiscount}
                        hasOfferSubscriptionDiscount={
                          hasOfferSubscriptionDiscount
                        }
                        offer={seller}
                      />
                    </>
                  ) : (
                    <PriceBox
                      discount={discount}
                      selectedPriceType={toggleSubscriptionOffer}
                      priceType={'regular'}
                      handleClick={handleChangePriceTypeSelect}
                      subscriptionDiscount={subscriptionDiscount}
                      hasOfferSubscriptionDiscount={
                        hasOfferSubscriptionDiscount
                      }
                      offer={seller}
                    />
                  )}
                </PriceInfo>

                <PriceNotFinalText />
                <SellerInfoDetails />
                <BuyActions
                  discount={discount}
                  cardTopStrip={cardTopStrip}
                  selectedPriceType={toggleSubscriptionOffer}
                  handleClickNotifyButton={handleClickNotifyButton}
                  offer={seller}
                />
              </CardG>

              <GWWebWrapper>
                {open && (
                  <ProductAddedModal
                    open={open}
                    product={product.data}
                    seller={seller}
                    handleClose={handleClose}
                  />
                )}
              </GWWebWrapper>
              <GWMobileWrapper>
                <ProductCheckoutConfirmationMobile
                  open={open}
                  product={product.data}
                  offer={seller}
                  handleClose={handleClose}
                />
              </GWMobileWrapper>
            </>
          ) : (
            <NotifyMeBox
              handleClickNotifyButton={handleClickNotifyButton}
              notifyMeStatus={notifyMeStatus}
              notifyMeLoading={notifyMeLoading}
            />
          )}

          {seller && hasOfferSubscriptionDiscount ? (
            <MembershipPromotionStrip
              savingAmount={subscriptionDiscount.plusExtraSaving}
              selectedPriceType={
                selectedRadioOffer?.offerId === seller.offerId &&
                toggleSubscriptionOffer === 'membership'
                  ? 'membership'
                  : 'regular'
              }
              position="bottom"
            />
          ) : null}
        </CardWrapper>

        {!isSellerCentric && <OtherOfferStrip />}

        <GWWebWrapper>
          <SpecialInfoCardsPdp />
          <NewsletterPdp />
        </GWWebWrapper>
      </CardContainer>

      {!showRecommendationPopUp ? null : width &&
        width > getNumFromResolutionConstant(WIDTH.mobileMax) ? (
        <NotifyMeRecommendationPopUp
          handleModal={handleShowRecommendationPopUp}
          show={showRecommendationPopUp}
          products={product.data}
        />
      ) : (
        <NotifyMeRecommendationDrawer
          drawerState={showRecommendationPopUp}
          toggleDrawer={handleShowRecommendationPopUp}
          products={product.data}
        />
      )}
    </>
  );
};

export default memo(PrimarySellerCardV3);
