import axios from 'axios';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTheme } from 'styled-components';
import { apiConstants } from '../../../../constants/apiConstants';
import { getPlatformPageLinkByPlatform } from '../../../../constants/platforms';
import { WIDTH } from '../../../../constants/screenResolution';
import { useLocale } from '../../../../contexts/LocalizationContext';
import { usePdpContext } from '../../../../contexts/PdpPageContext';
import { SnackbarContext } from '../../../../contexts/SnackbarContext';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { saveEventV3 } from '../../../../utils/eventTracking';
import { getNumFromResolutionConstant } from '../../../../utils/getNumberFromResolutionConstant';
import { ifStringIsUrl } from '../../../../utils/ifStringIsUrl';

import { exportPlatformIcon } from '../../../Icons/PlatformIcons';
import {
  CheckTextButton,
  IconContainer,
  ImportantDetailCard,
  ImportantDetailCardInfo,
} from '../../PDPPageStyles';

const ActivationDetailsPopup = dynamic(
  () =>
    import('../../../ActivationDetails/ActivationDetailsPopup').then(
      (mod) => mod.ActivationDetailsPopup
    ),
  { ssr: false }
);

const ActivationDetailDrawer = dynamic(
  () =>
    import('../../../ActivationDetails/ActivationDetailDrawer').then(
      (mod) => mod.ActivationDetailDrawer
    ),
  { ssr: false }
);

const PlatformDetail: React.FC = () => {
  const { width } = useWindowDimensions();
  const { product } = usePdpContext();
  const router = useRouter();
  const theme = useTheme();

  const { messages } = useLocale();
  const {
    platform_msg,
    activation_guide_new_msg,
    pdp_check_description_for_activation_guide,
  } = messages || {};

  const { dispatchSnackbar } = useContext(SnackbarContext);
  const [activationDetailsTitle, setActivationDetailsTitle] =
    useState<string>('');
  const [showActivationDetails, setShowActivationDetails] =
    useState<boolean>(false);
  const [activationDetailsData, setActivationDetailsData] = useState<any>(null);

  const handleShowActivationDetail = useCallback(() => {
    setShowActivationDetails(!showActivationDetails);
  }, [showActivationDetails]);

  const fetchActivationDetails = useCallback(
    async (pid: number) => {
      try {
        const { data, status } = await axios.get(
          `${apiConstants.server}/public/catalog/products/${pid}/activation-details`
        );
        if (status === 200) {
          if (data) {
            if (data?.type == 'link') {
              return window.open(data.content, '_blank');
            }
            setShowActivationDetails(true);
            setActivationDetailsTitle(
              data.title ? data?.title : 'Activation Guide'
            );
            setActivationDetailsData(data.content);
          }
        }
      } catch (error) {
        setShowActivationDetails(false);
        dispatchSnackbar({
          type: 'failure',
          payload: 'Some error occurred. Please try again.',
        });
      }
    },
    [dispatchSnackbar]
  );

  useEffect(() => {
    if (
      router.query.ag === '1' &&
      product.data.activationDetails &&
      !ifStringIsUrl(product.data.activationDetails)
    ) {
      fetchActivationDetails(parseInt(product.data.activationDetails));
    }
  }, [fetchActivationDetails, product.data.activationDetails, router.query.ag]);

  return (
    <>
      <ImportantDetailCard>
        <IconContainer>
          {exportPlatformIcon(product.data.platform, '32px', '32px', '0 0 0 0')}
        </IconContainer>
        <ImportantDetailCardInfo>
          <div>
            <p style={{ textTransform: 'capitalize' }}>
              {platform_msg}:{' '}
              {getPlatformPageLinkByPlatform(product.data.platform) ===
              '/platform/other' ? (
                <span>{product.data.platform || 'Other'}</span>
              ) : (
                <Link
                  href={`${getPlatformPageLinkByPlatform(
                    product.data.platform
                  )}`}
                  passHref
                >
                  <a>
                    <span>{product.data.platform || 'Other'}</span>
                  </a>
                </Link>
              )}
            </p>
          </div>
          {product.data.activationDetails &&
          product.data.activationDetails !== null ? (
            <CheckTextButton
              style={
                product.data.activationDetails === '' ||
                product.data.activationDetails === null
                  ? { cursor: 'default', color: theme.palette.text.disabled }
                  : {}
              }
              onClick={
                product.data.activationDetails === '' ||
                product.data.activationDetails === null
                  ? () => {}
                  : () => {
                      saveEventV3({
                        category: 'pdp_info',
                        action: 'click',
                        label: 'check_activation_pill',
                        properties: 'modal open',
                        value: [],
                        from: router,
                      });

                      {
                        product.data.activationDetails
                          ? fetchActivationDetails(
                              parseInt(product.data.activationDetails)
                            )
                          : null;
                      }
                    }
              }
            >
              {activation_guide_new_msg}
            </CheckTextButton>
          ) : (
            <CheckTextButton
              style={{
                cursor: 'default',
                color: theme.palette.text.disabled,
                fontFamily: 'Onest-Medium',
              }}
            >
              {pdp_check_description_for_activation_guide}
            </CheckTextButton>
          )}
        </ImportantDetailCardInfo>
      </ImportantDetailCard>
      {width && width > getNumFromResolutionConstant(WIDTH.mobileMax) ? (
        <>
          {showActivationDetails && (
            <ActivationDetailsPopup
              handleModal={handleShowActivationDetail}
              show={showActivationDetails}
              data={activationDetailsData}
              title={activationDetailsTitle}
            />
          )}
        </>
      ) : (
        <>
          {showActivationDetails && (
            <ActivationDetailDrawer
              drawerState={showActivationDetails}
              data={activationDetailsData}
              toggleDrawer={handleShowActivationDetail}
              title={activationDetailsTitle}
            />
          )}
        </>
      )}
    </>
  );
};

export default memo(PlatformDetail);
