import { memo } from 'react';
import { useTheme } from 'styled-components';
import { useLocale } from '../../contexts/LocalizationContext';
import { MaskIcon } from '../Icons/Icons';
import TooltipWithArrow from '../ToolTipWithArrow/TooltipWithArrow';
import {
  PriceNotFinalTextWrapper,
  PriceNotFinalTooltipText,
} from './PriceNotFinalTextStyles';

interface Props {
  iconOnly?: boolean;
}

function PriceNotFinalText({ iconOnly = false }: Props) {
  const theme = useTheme();
  const { messages } = useLocale();
  const { service_fee_applies_at_checkout_msg } = messages || {};
  return (
    <PriceNotFinalTextWrapper>
      {!iconOnly && <>Price not final </>}
      <TooltipWithArrow
        title={
          <PriceNotFinalTooltipText>
            {service_fee_applies_at_checkout_msg}
          </PriceNotFinalTooltipText>
        }
      >
        <div>
          <MaskIcon
            url={`/icons/small-info-24.svg`}
            height="18px"
            width="18px"
            margin="0 0 0"
            selected
            color={theme.palette.text.t3}
          />
        </div>
      </TooltipWithArrow>
    </PriceNotFinalTextWrapper>
  );
}

export default memo(PriceNotFinalText);
