import Link from 'next/link';
import { useLocale } from '../../contexts/LocalizationContext';
import { usePdpContext } from '../../contexts/PdpPageContext';
import { camelCaseToSentenceCase } from '../../helper/helper';
import { ProductDetailsInterface } from '../../Interface/ProductDetailsInterface';
import {
  BreadcrumbsContainer,
  BreadCrumbsList,
  BreadCrumbsListItem,
  CrumbText,
} from './BreadcrumbsStyle';
// import { BreadcrumbsMessages } from './messages';

const Breadcrumbs = () => {
  const { messages } = useLocale();
  const { home_msg } = messages || {};

  const { product } = usePdpContext();
  return (
    <BreadcrumbsContainer>
      <BreadCrumbsList>
        <BreadCrumbsListItem>
          <Link href="/" passHref prefetch={false}>
            <a>
              {/* <MaskIcon
                url="/icons/home-white.svg"
                height="15px"
                width="15px"
                // selected={true}
                margin="0 8px 3px 0"
              /> */}
              <CrumbText>{home_msg}</CrumbText>
            </a>
          </Link>
        </BreadCrumbsListItem>

        <BreadCrumbsListItem>
          <Link
            // href={`/store?productType[]=${product.data.productTypes[0] || ''}`}
            href={BreadCrumbProductTypeMapping(product)}
            passHref
            prefetch={false}
          >
            <a>
              <CrumbText>
                {camelCaseToSentenceCase(
                  product.data.productTypes[0] || 'Other'
                )}
              </CrumbText>
            </a>
          </Link>
        </BreadCrumbsListItem>

        {!!product.data.platform && (
          <BreadCrumbsListItem>
            <Link
              // href={`/store?platform[]=${product.data.platform || ''}`}
              href={BreadCrumbPlatformMapping(product)}
              passHref
              prefetch={false}
            >
              <a>
                <CrumbText>{product.data.platform || 'Other'}</CrumbText>
              </a>
            </Link>
          </BreadCrumbsListItem>
        )}

        <BreadCrumbsListItem>
          <Link href={`/${product.data.slug}`} passHref prefetch={false}>
            <a>
              <CrumbText>
                {product.data.name.length > 45
                  ? product.data.name.slice(0, 42) + ' ... '
                  : product.data.name || 'Other'}
              </CrumbText>
            </a>
          </Link>
        </BreadCrumbsListItem>
      </BreadCrumbsList>
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;

export const BreadCrumbProductTypeMapping = (product: {
  data: ProductDetailsInterface;
  currentSeller?: string;
}): string => {
  switch (product.data.productTypes[0]) {
    case 'dlc':
      // return `/store?productType[]=${product.data.productTypes[0] || ''}`;
      return `/store/dlc`;
    case 'game point':
      return `/store/gamepoints`;
    case 'game':
      return `/store/games`;
    case 'giftcard':
      return `/store/gift-cards`;
    case 'software':
      return `/store/softwares`;
    case 'subscription':
      // return `/store?productType[]=${product.data.productTypes[0] || ''}`;
      return `/store/subscription`;
    default:
      return `/store?productType[]=${product.data.productTypes[0] || ''}`;
  }
};

export const BreadCrumbPlatformMapping = (product: {
  data: ProductDetailsInterface;
  currentSeller?: string;
}): string => {
  switch (product.data.platform) {
    case 'Xbox Live':
      return `/store/xbox`;
    case 'Ubisoft Connect':
      return `/store/ubisoft-connect-games`;
    case 'Steam':
      return `/store/steam`;
    case 'PSN':
      return `/store/psn`;
    case 'Nintendo':
      return `/store/nintendo`;
    case 'NCSoft':
      return `/store/ncsoft-games`;
    case 'Mojang':
      return `/store/mojang-games`;
    case 'GOG COM':
      return `/store/gog-games`;
    case 'Epic Games':
      return `/store/epic-games`;
    case 'EA Play':
      return `/store/ea-play`;
    case 'Battle.net':
      return `/store/battle-net-games`;
    case 'Zalando':
      return `/store/zalando`;
    case 'Zomato':
      return `/store/zomato`;
    case 'Myntra':
      return `/store/myntra`;
    case 'MakeMyTrip':
      return `/store/MakeMyTrip`;
    case 'Binance':
      return `/store/binance-gift-cards`;
    case 'FM Redeem':
      return `/store/fm-redeem`;
    case 'Roblox':
      return `/store/roblox`;
    case 'Swiggy':
      return `/store/swiggy`;
    case 'Nykaa':
      return `/store/nykaa`;
    case 'Rockstar Games':
      return `/store/rockstar-games`;
    case 'Microsoft Store':
      return `/store/microsoft-store`;
    default:
      return `/store?platform[]=${product.data.platform || ''}`;
  }
};
