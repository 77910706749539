import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const OtherOffersWrapper = styled.div`
  margin-bottom: 32px;
  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 16px 16px 24px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin: 16px 16px 24px;
    margin-top: 0;
  }
`;

export const OtherOffersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: grid;
  place-content: center;
  place-items: center;
  margin-top: 16px;
  button {
    height: 40px;
    font: 0.875rem/1.125rem Onest-SemiBold;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    > button {
      width: 100%;
      @media (max-width: ${WIDTH.mobileMax}) {
        height: 32px;
        font: 0.75rem/1rem Onest-SemiBold;
      }
    }
  }
`;

export const OtherOfferFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  width: 100%;
  p {
    font: 0.875rem/1.125rem Onest-Medium;
    color: ${({ theme }) => theme.palette.text.t3};
  }
`;
