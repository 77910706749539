import axios, { AxiosRequestConfig } from 'axios';
import { RequestObject } from '../Interface/RequestInterface';
import { logoutUserServer } from '../redux/userSlice';
/*
  This function is not being used  
  type checks 
*/

export const syncRequest = async ({
  method,
  baseURL,
  path,
  params,
  onSuccess,
  onError,
  headers,
  config,
  dispatch,
  postData,
  router,
}: RequestObject) => {
  let axiosConfig: AxiosRequestConfig = {
    headers: {
      //Can add mandatory headers here
      ...headers,
    },
    timeout: 10000,
    ...config,
  };

  if (params) axiosConfig.params = params;

  let finalUrl = baseURL + path;

  let res;
  try {
    if (method === 'GET') {
      res = await axios.get(finalUrl, axiosConfig);
    } else if (method === 'POST') {
      res = await axios.post(finalUrl, postData, axiosConfig);
    }
    if (onSuccess) onSuccess(res);
  } catch (error: any) {
    if (error && error.response && error.response.status === 401 && dispatch) {
      dispatch(logoutUserServer(router, null));
    }
    if (onError) {
      onError(error);
    }
  }
};
