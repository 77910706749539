import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const SectionContainer = styled.div`
  margin: 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: ${WIDTH.tabletMid}) {
    margin: 0;
  }
`;

interface ISectionHeader {
  border: 'none' | null;
}

export const SectionHeader = styled.div<ISectionHeader>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 0;
    margin-bottom: 12px;
  }
`;

export const SectionTitle = styled.div`
  margin: 0;
  font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
  color: ${(props) => props.theme.palette.text.t1};
  font: 1.25rem/1.5rem Onest-SemiBold;
  min-height: 32px;
  display: flex;
  align-items: center;

  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 0;
    font: 1.125rem/1.375rem Onest-SemiBold;
  }
`;

export const SectionSub = styled.div`
  font-style: normal;
  font-family: Onest-Bold;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  font-feature-settings: 'pnum' on, 'lnum' on;
  /* flex: 1;
  justify-content: flex-end; */
  color: ${COLORS.primary};
  cursor: pointer;
  /* &:hover {
    color: #e23d13;
    transition: 0.3s all ease-in-out;
  } */
`;

export const SectionContent = styled.div`
  width: 100%;

  @media (max-width: ${WIDTH.mobileMax}) {
  }
`;
