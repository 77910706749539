import styled from 'styled-components';

export const SellerDetailsCardV3Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  min-width: 298px;
  min-height: 256px;
  z-index: 1;
  overflow: hidden;
  background: ${(props) => props.theme.palette.background.bg1};
  border: 1px solid ${(props) => props.theme.palette.border.b2};
`;

export const SellerDetailsCardV3Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 4px;
  background: ${(props) => props.theme.palette.background.bg3};

  h3 {
    font: 1rem/1.25rem Onest-SemiBold;
    color: ${(props) => props.theme.palette.text.t1};
  }
`;

export const SellerDetailsCardV3Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background: ${(props) => props.theme.palette.background.bg1};
  > div {
    border-bottom: 1px solid ${(props) => props.theme.palette.border.b2};
    :last-child {
      border-bottom: none;
    }
  }
`;

export const SellerDetailsCardV3ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 16px 0;
`;

export const SellerDetailsCardV3ContentRowTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t3};
`;

export const SellerDetailsCardV3ContentRowValue = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font: 1rem/1.25rem Onest-SemiBold;
  color: ${(props) => props.theme.palette.text.t1};
  span {
    color: ${(props) => props.theme.palette.text.t3};
    font: 1rem/1.25rem Onest-Medium;
  }
`;

export const DefectiveKeyRating = styled.div<{ color?: string }>`
  color: ${(props) =>
    props.color ? props.color : props.theme.palette.text.t1};
`;
