import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const TitleContainer = styled.div`
  margin-bottom: 16px;
  min-height: 32px;
  display: flex;
  align-items: center;
  h3 {
    margin: 0;
    font: 1.25rem/1.5rem Onest-SemiBold;
    color: ${(props) => props.theme.palette.text.t1};
  }

  @media (max-width: ${WIDTH.tabletMax}) {
    margin-bottom: 12px;
    h3 {
      margin: 0;
      font: 1.125rem/1.375rem Onest-SemiBold;
      color: ${(props) => props.theme.palette.text.t1};
    }
  }
`;

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.background.bg1};
  padding: 20px;
  border: 1px solid ${(props) => props.theme.palette.border.b1};
  border-radius: 8px;

  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 16px;
  }
`;

export const RequirementsContainer = styled.div`
  // background: ${COLORS.white};
  // padding: 24px;
  // border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  // box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.04);
  // border-radius: 6px;
  position: relative;
  color: #14012d;
  & h4 {
    margin-top: 32px;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  & > span {
    font-size: 14px;
    color: ${(props) => props.theme.palette.text.t2};
  }
`;

export const Tab = styled.div<{ active?: boolean }>`
  background: ${(props) =>
    props.active
      ? props.theme.palette.primary.light
      : props.theme.palette.background.bg3};
  color: ${(props) =>
    props.active
      ? props.theme.palette.text.primary
      : props.theme.palette.text.t2};
  font-size: 14px;
  line-height: 14px;
  font-family: Onest-Bold;
  padding: 8px 12px;
  border-radius: 20px;
  text-transform: capitalize;
  cursor: pointer;
`;

export const ProductRequirementsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 74px;
  grid-row-gap: 32px;
  padding: 24px 0;
  padding-bottom: 0;
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 24px 0;
    padding-bottom: 0;
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
  }
`;

export const ProductRequirementTitle = styled.p`
  font-size: 16px;
  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const RequirementListItem = styled.div`
  /* display: table-row; */
  display: flex;
  gap: 16px;
  align-items: top;
`;

export const RequirementListCellOne = styled.div`
  padding-bottom: 5px;
  /* text-align: right; */
  display: table-cell;
  white-space: nowrap;
  padding-right: 20px;
`;

export const RequirementListCellTwo = styled.div`
  padding-bottom: 5px;
  display: table-cell;
`;

export const RequirementTitle = styled.p`
  font: 1rem/1.25rem Onest-SemiBold;
  color: ${(props) => props.theme.palette.text.t3};
  margin-bottom: 2px;
  text-transform: capitalize;
`;

export const RequirementDetail = styled.p`
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
  & > span {
    display: block;
    margin-bottom: 8px;
    max-width: 500px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  @media (max-width: ${WIDTH.mobileMid}) {
    max-width: 200px;
  }
`;

export const RequirementIconContainer = styled.div`
  width: 24px;
  min-width: 24px;
  min-height: 24px;
  height: 24px;
  border-radius: 50%;
  // background: ${COLORS.purple2};
  color: ${(props) => props.theme.palette.primary.main};
  display: grid;
  // place-content: center;
  // place-items: center;
`;

export const NotAvailable = styled.div`
  // background: ${(props) => props.theme.palette.background.bg3};
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: start;
  gap: 8px;
  font-size: 16px;
  line-height: 20px;
  font-family: Onest-Medium;

  @media (max-width: ${WIDTH.mobileMax}) {
    // padding: 12px;
  }
`;
