import { useEffect, useMemo, useState } from 'react';
// import { gtag } from '../../utils/gtm';
import {
  ProductDetailsInterface,
  ProductOfferInterface,
} from '../../Interface/ProductDetailsInterface';

import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import ProductDetailsSection from '../../components/ProductDetailsSection/ProductDetailsSection';
import { apiConstants } from '../../constants/apiConstants';
import {
  BodyContainer,
  BodyWrapper,
  PrimarySellerContainer,
  ProductFlexFirst,
  ProductWrapper,
} from '../../styles/pageStyles/pdpStyle';

import { Box } from '@mui/material';
import Head from 'next/head';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { camelCaseToSentenceCase } from '../../helper/helper';
import { useAppSelector } from '../../redux/hooks';

import { ErrorBoundary } from '@sentry/nextjs';
import { useRouter } from 'next/dist/client/router';
import { GtagItemInterface } from '../../Interface/GtagInterface';
import ErrorFallbackComponent from '../../components/ErrorFallbackComponent/ErrorFallbackComponent';
import { getIconUrl } from '../../components/Icons/PlatformIcons';

const ProductImagesMuiModal = dynamic(
  () => import('../../components/ProductImagesModal/ProductImagesMuiModal'),
  { ssr: false }
);

import dynamic from 'next/dynamic';
import ProductImportantNoticeBanner from '../../components/ProductImportantNoticeBanner';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { usePdpContext } from '../../contexts/PdpPageContext';

import {
  GWMobileWrapper,
  GWTabletWrapper,
  GWWebWrapper,
} from '../../styles/window.style';
import { trackEvent } from '../../utils/eventTracking';
import { getLocalStorageString } from '../../utils/localStorageUtils';
import { sendProductDetailViewEvent } from '../../utils/recombee';
import { WE_USER_EVENT_PRODUCT_VIEWED } from '../../utils/we';
import Footer from '../Footer/Footer';
import NewPdpVariations from '../NewPdpVariations/NewPdpVariations';
import PrimarySellerCardV3 from '../PrimarySellerCardV3/PrimarySellerCardV3';
import ProductDescriptionHead from '../ProductDescriptionHead/ProductDescriptionHead';
import NewPDPHead from './components/NewPDPHead';

const ProductBuyRestriction = dynamic(
  () => import('../ProductBuyRestriction/ProductBuyRestriction'),
  {
    ssr: false,
  }
);

const AllPdpVariations = dynamic(
  () => import('../AllPdpVariations/AllPdpVariations'),
  {
    ssr: false,
  }
);

const trunkNumber = (number: number) => {
  let str_num: string = number.toLocaleString('en-US', {
    maximumFractionDigits: 2,
  });
  if (typeof str_num === 'string') {
    return str_num.replace(/\,/gi, '');
  }
  return '';
};

const createCrumbs = (
  productType: Array<string>,
  platform: string,
  productSlug: string,
  productId: string
) => {
  let retObj: any = {};
  retObj['@context'] = 'https://schema.org/';
  retObj['@type'] = 'BreadcrumbList';

  let retArr: Array<any> = [];
  let home = {
    '@type': 'ListItem',
    position: 1,
    item: {
      name: 'Home',
      '@id': 'https://driffle.com',
    },
  };
  let type = {
    '@type': 'ListItem',
    position: 2,
    item: {
      name: camelCaseToSentenceCase(productType[0]) || 'Other',
      '@id': `https://driffle.com/store?productType[]=${
        productType[0] || 'Other'
      }`,
    },
  };
  let plat = {
    '@type': 'ListItem',
    position: 3,
    item: {
      name: platform || 'Other',
      '@id': `https://driffle.com/store?platform[]=${platform || 'Other'}`,
    },
  };
  let slug = {
    '@type': 'ListItem',
    position: 4,
    item: {
      name: productSlug || 'Other',
      '@id': productId,
    },
  };
  retArr.push(home);
  retArr.push(type);
  retArr.push(plat);
  retArr.push(slug);

  retObj['itemListElement'] = retArr;
  return retObj;
};

const displayOffers = (
  mrp: number | null,
  offers: Array<ProductOfferInterface>,
  currency: string | null | undefined,
  multiplier: number | undefined | null,
  slug: string
) => {
  let offRet: Array<any> = [];
  offers
    .filter((singleOffer) => !singleOffer.isFeatured)
    .map((offer) => {
      let x: any = {};
      x['@type'] = 'Offer';
      // x['price'] = trunkNumber(offer.price * (multiplier || 1));
      // x['priceValidUntil'] = '2099-12-31';
      // x['priceCurrency'] = currency;

      // create priceSpecification array
      let priceSpecification: Array<any> = [
        {
          '@type': 'UnitPriceSpecification',
          price: trunkNumber(offer.price * (multiplier || 1)),
          priceCurrency: currency,
        },
        {
          '@type': 'UnitPriceSpecification',
          priceType: 'https://schema.org/SubscriptionPrice',
          price: trunkNumber(
            (offer.price - Number(offer.plusDiscount || 0) * offer.price) *
              (multiplier || 1)
          ),
          priceCurrency: currency,
        },
      ];

      // check if mrp is present and add it to the priceSpecification array
      if (mrp !== null) {
        priceSpecification.push({
          '@type': 'UnitPriceSpecification',
          priceType: 'https://schema.org/ListPrice',
          price: trunkNumber(mrp * (multiplier || 1)),
          priceCurrency: currency,
        });
      }

      x['priceSpecification'] = priceSpecification;
      x['availability'] = 'http://schema.org/InStock';
      x['url'] = `https://driffle.com/${slug}?seller=${offer.sellerId || '91'}`;
      x['seller'] = {
        '@type': 'Organization',
        name: offer.storeName,
        url: `https://driffle.com/vendor/${offer.sellerId || '91'}`,
      };
      offRet.push(x);
    });
  return offRet;
};

const displayAggregateOffers = (
  lowPrice: number,
  highPrice: number,
  offerCount: number | null | undefined,
  multiplier: number | undefined | null
) => {
  let x: any = {};

  return x;
};

interface Props {
  product: {
    data: ProductDetailsInterface;
    currentSeller?: string;
  };
  slug: string;
  sellerCentric?: boolean;
  primarySeller: ProductOfferInterface | null;
}

export interface ProductRating {
  overall: number;
  ratings: any;
  count: number;
}

const PDPPage = ({ product, primarySeller, ...props }: Props) => {
  const router = useRouter();
  const global = useAppSelector((state) => state.global);

  const [productImageModal, setProductImageModal] = useState<boolean>(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const { cleverTap } = useAnalytics();

  const {
    valuesToBeUsed,
    productRating,
    primarySeller: seller,
    showBuyRestriction,
    handleShowBuyRestriction,
    showAllAvailableVariations,
    handleShowAllAvailableVariations,
  } = usePdpContext();

  const discount = useMemo(() => {
    return (
      product &&
      seller &&
      Number(product.data.mrp) > Number(seller.price) &&
      Math.round(
        ((Number(product.data.mrp) - Number(seller.price)) * 100) /
          Number(product.data.mrp)
      )
    );
  }, [product, seller]);

  useEffect(() => {
    if (valuesToBeUsed.currency && product) {
      if (seller) {
        WE_USER_EVENT_PRODUCT_VIEWED(
          {
            platform: product.data?.platform ?? '',
            category: product.data?.genres?.toString() ?? '',
            currency: valuesToBeUsed?.currency ?? '',
            discount: discount ? (discount > 99 ? 99 : discount) : 0,
            imageUrl: product.data.coverImageOriginal,
            productName: product.data?.name ?? '',
            pageUrl: window.location.href,
            productId: product.data.productId?.toString() ?? '',
            productType: product.data.productTypes?.toString() ?? '',
            productUrl: product.data?.slug ?? '',
            basePrice: seller.price?.toString() ?? '',
            inStock: true,
          },
          cleverTap
        );
      } else {
        WE_USER_EVENT_PRODUCT_VIEWED(
          {
            platform: product.data?.platform ?? '',
            category: product.data?.genres?.toString() ?? '',
            currency: valuesToBeUsed.currency ?? '',
            discount: 0,
            imageUrl: product.data?.coverImageOriginal ?? '',
            productName: product.data?.name ?? '',
            pageUrl: window.location.href,
            productId: product.data.productId?.toString() ?? '',
            productType: product.data.productTypes?.toString() ?? '',
            productUrl: product.data.slug,
            basePrice: '-',
            inStock: false,
          },
          cleverTap
        );
      }
    }
  }, [discount, product, seller, valuesToBeUsed.currency, cleverTap]);

  const setImageModal = (x: boolean) => {
    setProductImageModal(x);
  };

  useEffect(() => {
    if (
      typeof valuesToBeUsed.offers[0]?.price === 'string' ||
      typeof valuesToBeUsed.offers[0]?.price === 'number'
    ) {
      const itemsArray: Array<GtagItemInterface> = [
        {
          item_id: product?.data.productSku,
          item_name: product?.data.name,
          google_business_vertical: 'retail',
          price:
            valuesToBeUsed.offers[0].price *
            (valuesToBeUsed.multiplier ||
              global.locationDetails?.multiplier ||
              1),
          basePrice: valuesToBeUsed.offers[0].price,
          currency:
            valuesToBeUsed.currency ||
            global.locationDetails?.currency ||
            'EUR',
          item_brand: product?.data.publishers[0],
          item_category: product?.data.productTypes[0],
          // item_category2: product?.data.platform,
          quantity: 1,
        },
      ];

      const eventData = {
        event: 'view_item',
        ecommerce: {
          // currency:
          //   valuesToBeUsed.currency ||
          //   global.locationDetails?.currency ||
          //   'EUR',
          // value:
          //   valuesToBeUsed.offers[0].price *
          //   (valuesToBeUsed.multiplier ||
          //     global.locationDetails?.multiplier ||
          //     1),
          value: valuesToBeUsed.offers[0].price,
          items: itemsArray,
          currency: 'EUR',
          fbObjectViewPageItemId: [product?.data.productSku.toString()],
          fbObjectViewPageItemName: product?.data.name,
        },
      };

      trackEvent('gtm_event', { eventData });

      // meta/fb pixel view content
      // if (cookie.get('drif_acq') === 'facebook-ads') {
      try {
        //@ts-ignore
        fbq('track', 'ViewContent', {
          content_ids: [product?.data.productSku],
          content_name: product?.data.name,
          content_type: 'product_page',
          value: valuesToBeUsed.offers[0].price,
          currency: 'EUR',
        });
      } catch (error) {}

      // snaptr view content
      try {
        //@ts-ignore
        snaptr('track', 'VIEW_CONTENT', {
          price: valuesToBeUsed.offers[0].price,
          currency: 'EUR',
          item_ids: [product?.data.productSku],
          item_category: product.data.productTypes[0] ?? '',
        });
      } catch (error) {
        console.error('Error in snaptr', error);
      }

      // tiktok pixel view content
      // try {
      //   //@ts-ignore
      //   ttq.track('ViewContent', {
      //     contents: [
      //       {
      //         content_id: product.data.productSku, // string. ID of the product. Example: "1077218".
      //         content_type: 'product', // string. Either product or product_group.
      //         content_name: product.data.name, // string. The name of the page or product. Example: "shirt".
      //         price: valuesToBeUsed.offers[0].price, // number. The price of a single item. Example: 25.
      //       },
      //     ],
      //   });
      // } catch (error) {}

      // }
    }
  }, []);

  let ogImageUrl: string | null = null;
  if (product?.data) {
    try {
      ogImageUrl = `/api/ogimage?coverImage=${
        product?.data.coverImageOriginal
      }&name=${product?.data.name}&symbol=${
        valuesToBeUsed.offers.length > 0
          ? valuesToBeUsed.currency || global.locationDetails?.currency || 'EUR'
          : ''
      }&price=${
        valuesToBeUsed.offers.length > 0
          ? trunkNumber(
              valuesToBeUsed.offers[0]?.price *
                (valuesToBeUsed.multiplier || global.locationDetails.multiplier)
            )
          : 'Stock out'
      }&platformName=${product?.data.platform}&platformIcon=${
        apiConstants.frontend + getIconUrl(product?.data.platform)
      }`;

      if (product?.data.regions.length > 0) {
        ogImageUrl += `&productRegion=${product?.data.regions[0].name}`;
      }
    } catch (err) {}
  }

  useEffect(() => {
    const i = setInterval(() => {
      let checkIfRecombeeUserIsSet = getLocalStorageString('rru');
      if (checkIfRecombeeUserIsSet === '1') {
        sendProductDetailViewEvent(product?.data.productSku, product?.data.tag);
        clearInterval(i);
      }
    }, 400);
    return () => {
      clearInterval(i);
    };
  }, [product?.data.productSku, product?.data.tag]);

  return (
    <>
      <Head key="product-slug-head">
        <title>Buy {product?.data.name}</title>
        <meta httpEquiv="last-modified" content={new Date().toDateString()} />
        <meta
          name="description"
          content={`Shop the Best Deals for ${product?.data.name} at Driffle - Instant Delivery of Authentic Products at Unbeatable Prices!`}
        />
        {/* {props.slug === 'dummy-product-qwerty' ||
        !!router.query.currency ||
        !!router.query.country ? (
          <meta name="robots" content="noindex,nofollow" />
        ) : (
          <meta name="robots" content="index,follow" />
        )} */}

        {/* re-indexing pages with country currency  */}
        {props.slug === 'dummy-product-qwerty' ? (
          <meta name="robots" content="noindex,nofollow" />
        ) : (
          <meta name="robots" content="index,follow" />
        )}
        {/* <meta name="robots" content="index,follow" /> */}
        <link rel="canonical" href={`https://driffle.com/${props.slug}`} />
        {/* <link
          rel="alternate"
          href={`https://driffle.com/${props.slug}`}
          hrefLang="x-default"
        /> */}
        {valuesToBeUsed.offers.length >= 1 ? (
          <meta
            property="product:price:amount"
            content={trunkNumber(
              valuesToBeUsed.offers[0]?.price *
                (valuesToBeUsed.multiplier || global.locationDetails.multiplier)
            )}
          />
        ) : (
          <meta property="product:price:amount" content="-" />
        )}

        <meta
          property="product:price:currency"
          content={valuesToBeUsed.currency || global.locationDetails?.currency}
        />
        <meta property="product:brand" content={product?.data.publishers[0]} />
        <meta
          property="product:availability"
          content={valuesToBeUsed.offers.length >= 1 ? 'instock' : 'outofstock'}
        />
        <meta
          property="product:retailer_item_id"
          content={product?.data.productSku}
        />
        <meta property="product:condition" content="new" />
        {/* OG Meta */}
        <meta property="og:title" content={`Buy ${product?.data.name}`} />
        <meta property="og:type" content="og:product" />
        <meta
          property="og:description"
          content={`Shop the Best Deals for ${product?.data.name} at Driffle - Instant Delivery of Authentic Products at Unbeatable Prices!`}
        />
        <meta
          property="og:url"
          content={`https://driffle.com/${
            router.locale === 'en' ? '' : router.locale + '/'
          }${props.slug}`}
        />
        <meta property="og:site_name" content="Driffle" />
        <meta
          property="og:image"
          content={ogImageUrl || product?.data.coverImageOriginal}
        />
        <meta property="og:availability" content="instock" />
        <meta property="fb:app_id" content="1787660858154522" />
        {/* <meta property="article:tag" content={product?.data.name} /> */}

        {/* Twitter Meta */}
        <meta property="twitter:title" content={product?.data.name} />
        <meta
          property="twitter:description"
          content={`Shop the Best Deals for ${product?.data.name} at Driffle - Instant Delivery of Authentic Products at Unbeatable Prices!`}
        />
        <meta
          property="twitter:url"
          content={`https://driffle.com/${
            router.locale === 'en' ? '' : router.locale + '/'
          }${props.slug}`}
        />
        <meta
          property="twitter:image"
          content={product?.data.coverImageOriginal}
        />
        <meta name="twitter:card" content="summary" />
        <script
          id="json-ld-product"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@graph': [
                {
                  '@context': 'https://schema.org/',
                  '@type': 'Product',
                  productID: String(product?.data.productSku) || '',
                  name: product?.data.name,
                  url: `https://driffle.com/${props.slug}`,
                  description:
                    'Buy ' +
                    product?.data.name +
                    ' from Driffle, the place to buy your games at the best price with fast delivery',
                  image: product?.data.coverImageOriginal,
                  sku: product?.data?.productSku?.toString(),
                  brand: {
                    '@type': 'Brand',
                    name: product?.data.publishers[0] || '',
                  },
                  releaseDate: product.data.releaseDate ?? '',
                  offers: {
                    '@type': 'AggregateOffer',
                    lowPrice: trunkNumber(
                      valuesToBeUsed.lowestOfferValue *
                        (valuesToBeUsed?.multiplier || 1)
                    ),
                    highPrice: trunkNumber(
                      valuesToBeUsed.highestOfferValue *
                        (valuesToBeUsed?.multiplier || 1)
                    ),
                    offerCount: valuesToBeUsed?.offers?.length,
                    priceCurrency:
                      valuesToBeUsed.currency ||
                      global.locationDetails?.currency ||
                      'EUR',
                    offers: displayOffers(
                      product?.data?.mrp ?? null,
                      valuesToBeUsed.offers,
                      valuesToBeUsed.currency ||
                        global.locationDetails?.currency ||
                        'EUR',
                      valuesToBeUsed?.multiplier ||
                        global.locationDetails.multiplier,
                      valuesToBeUsed.slug
                    ),
                  },
                  rating: {
                    '@type': 'Rating',
                    worstRating: '1',
                    bestRating: '5',
                  },
                  // reviewCount {
                  //   '@type':'ReviewCount',
                  //   re
                  // }
                  aggregateRating: {
                    '@type': 'AggregateRating',
                    ratingValue: productRating.overall
                      ? (Math.round(Number(productRating.overall) * 100) / 100)
                          .toFixed(1)
                          .toString()
                      : '4.0',
                    reviewCount: productRating.count
                      ? productRating.count
                      : '100',
                  },
                },
                createCrumbs(
                  product?.data.productTypes,
                  product?.data.platform,
                  props.slug,
                  String(product?.data.productId)
                ),
              ],
            }),
          }}
        />
      </Head>
      {/* createSchemaDescriptionSchema(product?.data.publishers[0]), */}

      <div>
        <Header />
        <Navbar />

        <BodyWrapper>
          <ErrorBoundary
            fallback={ErrorFallbackComponent}
            onError={() => {
              //window.location.reload(false);
            }}
            // onError={(error, errorInfo) => errorService.log({ error, errorInfo })}
            onReset={() => {
              // reset the state of your app
            }}
          >
            <BodyContainer>
              <ProductFlexFirst>
                <>
                  <GWTabletWrapper>
                    <Breadcrumbs />
                  </GWTabletWrapper>
                  <GWMobileWrapper>
                    <ProductDescriptionHead
                      productImageModal={productImageModal}
                      setProductImageModal={setImageModal}
                    />
                  </GWMobileWrapper>
                  <GWWebWrapper>
                    <div style={{ marginBottom: '16px' }}>
                      <NewPDPHead />
                      {
                        Array.isArray(product.data.allVariations) &&
                        product.data.allVariations?.length > 1 ? (
                          <NewPdpVariations />
                        ) : null
                        // <PdpVariations />
                      }

                      <ProductWrapper>
                        {product.data.notice && (
                          <ProductImportantNoticeBanner
                            notice={product.data.notice}
                          />
                        )}
                      </ProductWrapper>
                    </div>
                  </GWWebWrapper>
                </>

                <ProductDetailsSection
                  setIndex={setSelectedImageIndex}
                  setProductImageModal={setImageModal}
                />
              </ProductFlexFirst>
              <GWWebWrapper>
                <PrimarySellerContainer>
                  <PrimarySellerCardV3 />
                </PrimarySellerContainer>
              </GWWebWrapper>
              {productImageModal && product.data.screenshots.length > 0 ? (
                <ProductImagesMuiModal
                  product={product.data}
                  videos={product.data.videos}
                  images={product.data.screenshots}
                  productImageModal={productImageModal}
                  setProductImageModal={setProductImageModal}
                  index={selectedImageIndex}
                  setIndex={setSelectedImageIndex}
                />
              ) : null}

              {showBuyRestriction?.state && (
                <ProductBuyRestriction
                  open={showBuyRestriction.state}
                  handleClose={handleShowBuyRestriction}
                  action={showBuyRestriction.action}
                  confirmation={showBuyRestriction.confirmation}
                  onSuccess={showBuyRestriction.onSuccess}
                />
              )}
            </BodyContainer>
          </ErrorBoundary>
        </BodyWrapper>

        <Footer />
        <GWMobileWrapper>
          <Box height={'24px'} />
        </GWMobileWrapper>

        {showAllAvailableVariations && (
          <AllPdpVariations
            open={showAllAvailableVariations}
            handleClose={handleShowAllAvailableVariations}
          />
        )}
      </div>
    </>
  );
};

export default PDPPage;
