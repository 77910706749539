import { memo } from 'react';
import { useTheme } from 'styled-components';
import { useLocale } from '../../contexts/LocalizationContext';
import { usePdpContext } from '../../contexts/PdpPageContext';
import { BackgroundIcon, MaskIcon } from '../Icons/Icons';
import TooltipWithArrow from '../ToolTipWithArrow/TooltipWithArrow';
import {
  SpecialInfoCard,
  SpecialInfoCardIcon,
  SpecialInfoCardTitle,
  SpecialInfoCardTooltip,
  Wrapper,
} from './SpecialInfoCardsPdpStyle';

function SpecialInfoCardsPdp() {
  const { product } = usePdpContext();
  const theme = useTheme();
  const { messages } = useLocale();
  const {
    instant_delivery_msg,
    product_will_be_delivered_msg,
    digital_key_msg,
    this_is_digital_edition_msg,
    pre_order_msg,
    assured_refunds_msg,
    product_code_will_be_delivered_on_release_date_msg,
    assured_replacement_refund_msg,
    support_msg,
    verified_sellers_msg,
    get_prompt_assistance_from_our_msg,
    buy_confidently_from_verified_and_msg,
  } = messages || {};

  return (
    <Wrapper>
      {product.data.isPreReleased ? (
        <TooltipWithArrow
          title={
            <SpecialInfoCardTooltip>
              {product_code_will_be_delivered_on_release_date_msg}
            </SpecialInfoCardTooltip>
          }
        >
          <SpecialInfoCard>
            <SpecialInfoCardIcon>
              <BackgroundIcon
                url="/icons/calendar-illustration.svg"
                height="24px"
                width="24px"
                margin="0"
              />
            </SpecialInfoCardIcon>
            <SpecialInfoCardTitle>{pre_order_msg}</SpecialInfoCardTitle>
          </SpecialInfoCard>
        </TooltipWithArrow>
      ) : (
        <TooltipWithArrow
          title={
            <SpecialInfoCardTooltip>
              {product_will_be_delivered_msg}
            </SpecialInfoCardTooltip>
          }
        >
          <SpecialInfoCard>
            <SpecialInfoCardIcon>
              <BackgroundIcon
                url="/icons/instant-bolt-24.svg"
                height="24px"
                width="24px"
                margin="0"
              />
            </SpecialInfoCardIcon>
            <SpecialInfoCardTitle>{instant_delivery_msg}</SpecialInfoCardTitle>
          </SpecialInfoCard>
        </TooltipWithArrow>
      )}

      <TooltipWithArrow
        title={
          <SpecialInfoCardTooltip>
            {get_prompt_assistance_from_our_msg}
          </SpecialInfoCardTooltip>
        }
      >
        <SpecialInfoCard>
          <SpecialInfoCardIcon>
            <MaskIcon
              url="/icons/support-24.svg"
              height="24px"
              width="24px"
              margin="0"
              selected
              color={theme.palette.success.main}
            />
          </SpecialInfoCardIcon>
          <SpecialInfoCardTitle>24/7 {support_msg}</SpecialInfoCardTitle>
        </SpecialInfoCard>
      </TooltipWithArrow>

      <TooltipWithArrow
        title={
          <SpecialInfoCardTooltip>
            {buy_confidently_from_verified_and_msg}
          </SpecialInfoCardTooltip>
        }
      >
        <SpecialInfoCard style={{ width: '100%' }}>
          <SpecialInfoCardIcon>
            <BackgroundIcon
              url="/icons/verified.svg"
              height="24px"
              width="24px"
              margin="0"
            />
          </SpecialInfoCardIcon>
          <SpecialInfoCardTitle>{verified_sellers_msg}</SpecialInfoCardTitle>
        </SpecialInfoCard>
      </TooltipWithArrow>
    </Wrapper>
  );
}

export default memo(SpecialInfoCardsPdp);
