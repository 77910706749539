import {
  SectionContainer,
  SectionContent,
  SectionHeader,
  SectionSub,
  SectionTitle,
} from './PageSectionStyle';

interface Props {
  title: String;
  sub?: JSX.Element | JSX.Element[] | string | string[] | null;
  children: JSX.Element | JSX.Element[] | string | string[];
  border?: 'none' | null;
}

const PageSection = (props: Props) => {
  return (
    <SectionContainer>
      <SectionHeader border={props.border ? props.border : null}>
        <SectionTitle>{props.title}</SectionTitle>
        {props.sub ? <SectionSub>{props.sub}</SectionSub> : null}
      </SectionHeader>
      <SectionContent>{props.children}</SectionContent>
    </SectionContainer>
  );
};

export default PageSection;
