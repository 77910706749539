import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { memo, useEffect, useState } from 'react';
import { exportPegiIcons } from '../Icons/PegiIcons';

import ProductRequirements from '../ProductRequirements/ProductRequirements';

import { useLocale } from '../../contexts/LocalizationContext';
import { usePdpContext } from '../../contexts/PdpPageContext';
import { useTrendingProducts } from '../../contexts/TrendingProductsContext';
import {
  GWMobileWrapper,
  GWTabletWrapper,
  GWWebWrapper,
} from '../../styles/window.style';
import { saveEventV3 } from '../../utils/eventTracking';
import OtherOffersSection from '../OtherOffersSection/OtherOffersSection';
import PDPAboutGame from '../PDPAboutGame/PDPAboutGame';
import PdpFBT from '../PdpFBT/PdpFBT';
import useProductReviews from '../ProductReviews/hooks/useProductReviews';
import {
  InformationContainer,
  LanguagesContainer,
  OtherDetailsContainer,
  OtherDetailsList,
  OtherDetailsListContent,
  OtherDetailsListItem,
  OtherDetailsListTitle,
  OtherDetailsTitle,
  OtherDetailsWrapper,
  ScreenshotsContainer,
  Title,
} from './ProductInformationStyle';

const PdpTrendingProducts = dynamic(
  () => import('../SimilarProducts/PdpTrendingProducts'),
  {
    ssr: false,
  }
);

const LazyProductReviews = dynamic(
  () => import('../ProductReviews/ProductReviewsV2'),
  {
    ssr: false,
  }
);

const RecombeeRecommendedProducts = dynamic(
  () => import('../SimilarProducts/RecombeeRecommendedProducts'),
  {
    ssr: false,
  }
);

const ProductImagesSlider = dynamic(
  () => import('../ProductImagesSlider/ProductImagesSlider'),
  {
    ssr: false,
  }
);

interface Props {
  setProductImageModal: (x: boolean) => void;
  setIndex: (x: number) => void;
}

const ProductInformation = (props: Props) => {
  const router = useRouter();
  const { query } = router;

  const { messages } = useLocale();
  const {
    other_details_msg,
    languages_msg,
    release_date_msg,
    publishers_msg,
    developers_msg,
    age_rating_msg,
    you_may_also_like_msg,
    screenshots_msg,
  } = messages || {};

  const {
    product,
    valuesToBeUsed,
    recombeeRecommendations,
    fbtRefDesktop,
    fbtRefMobile,
    recommRefDesktop,
    recommRefMobile,
  } = usePdpContext();

  const [isReadMore, setIsReadMore] = useState<boolean>(false);

  const productReviewHook = useProductReviews();

  // const similarProductsHook = useSimilarProducts({
  //   product: product.data,
  //   shouldRecommend: false,
  //   status:
  //     !showRecombeeProductRecommendations() ||
  //     (showRecombeeProductRecommendations() &&
  //       recombeeRecommendations === null),
  // });

  const handleReadMoreToggle = () => {
    saveEventV3({
      category: 'pdp_info',
      action: 'click',
      label: 'product_description',
      properties: query.slug as string,
      value: [isReadMore ? 'read_less' : 'read_more'],
      from: router,
    });
    setIsReadMore((prev) => !prev);
  };

  const { trendingProducts, fetchTrendingProducts } = useTrendingProducts();

  const [productDescription, setProductDescription] = useState(
    product.data.descriptionLocalized || product.data.description
  );

  useEffect(() => {
    product.data.descriptionLocalized
      ? setProductDescription(product.data.descriptionLocalized)
      : setProductDescription(product.data.description);
  }, [product.data.description, product.data.descriptionLocalized]);

  useEffect(() => {
    if (!trendingProducts) {
      fetchTrendingProducts();
    }
  }, [fetchTrendingProducts, trendingProducts]);

  return (
    <InformationContainer>
      <GWWebWrapper>
        <div>
          {/* <AboutGameWrapper> */}

          {(!product?.data?.productVariations ||
            (product?.data?.productVariations &&
              !Object.keys(product?.data?.productVariations).some(
                (key: any) =>
                  product?.data?.productVariations &&
                  product?.data?.productVariations[key]?.length > 0
              ))) &&
          !(
            Array.isArray(valuesToBeUsed.offers) &&
            valuesToBeUsed.offers.length > 1
          ) ? (
            <PDPAboutGame />
          ) : null}

          <OtherOffersSection
            offers={valuesToBeUsed.offers}
            product={product.data}
            valuesToBeUsed={valuesToBeUsed}
            id="product-other-offers"
          />

          {product.data?.productId && (
            <div ref={fbtRefDesktop.ref}>
              <PdpFBT />
            </div>
          )}

          {/* {getProductRecommendationType() === 'recombee' &&
          showRecombeeProductRecommendations() ? (
            <> */}
          {
            <div ref={recommRefDesktop.ref}>
              {recombeeRecommendations && (
                <RecombeeRecommendedProducts
                  products={recombeeRecommendations?.list}
                  recommendationId={recombeeRecommendations?.id}
                  titleName="pdp_you_may_also_like"
                  title={you_may_also_like_msg ?? ''}
                  showAddToCartBtn={false}
                />
              )}
            </div>
          }
          {/* </>
          ) : (
            <LazySimilarProducts
              products={similarProductsHook.products}
              loading={similarProductsHook.loading}
              showCountMobile={similarProductsHook.showCountMobile}
              handleSeeMore={similarProductsHook.handleSeeMore}
              handleScrollClick={similarProductsHook.handleScrollClick}
              handleScroll={similarProductsHook.handleScroll}
              scrollButtonDisable={similarProductsHook.scrollButtonDisable}
              sliderRef={similarProductsHook.sliderRef}
            />
          )} */}

          {(product?.data?.productVariations &&
            Object.keys(product?.data?.productVariations).some(
              (key: any) =>
                product?.data?.productVariations &&
                product?.data?.productVariations[key]?.length > 0
            )) ||
          (Array.isArray(valuesToBeUsed.offers) &&
            valuesToBeUsed.offers.length > 1) ? (
            <PDPAboutGame />
          ) : null}

          <LazyProductReviews
            reviews={productReviewHook.reviews}
            isLoading={productReviewHook.isLoading}
            visibleReviews={productReviewHook.visibleReviews}
            handleLoadMore={productReviewHook.handleLoadMore}
            setShowReviewModal={productReviewHook.setShowReviewModal}
            postReviewLoading={productReviewHook.postReviewLoading}
            showReviewModal={productReviewHook.showReviewModal}
            handleCloseModal={productReviewHook.handleCloseModal}
            handleOptionClick={productReviewHook.handleSortToggle}
            selectedOption={productReviewHook.sortingOrder}
            sortOptions={productReviewHook.sortOptions}
            isReviewed={productReviewHook.isReviewed}
          />

          <ProductRequirements
            systemRequirements={product.data?.systemRequirements}
            id="product-system-requirements"
          />
          <OtherDetailsContainer id="product-other-details">
            <OtherDetailsTitle>
              <h3>{other_details_msg}</h3>
            </OtherDetailsTitle>
            <OtherDetailsWrapper>
              <OtherDetailsList
                hasLanguages={product.data?.language?.length > 0}
              >
                {product.data?.releaseDate ? (
                  <OtherDetailsListItem>
                    <OtherDetailsListTitle>
                      {release_date_msg}
                    </OtherDetailsListTitle>
                    <OtherDetailsListContent>
                      {product.data?.releaseDate}
                    </OtherDetailsListContent>
                  </OtherDetailsListItem>
                ) : null}
                {Array.isArray(product.data?.publishers) &&
                product.data?.publishers?.length >= 1 ? (
                  <OtherDetailsListItem>
                    <OtherDetailsListTitle>
                      {publishers_msg}
                    </OtherDetailsListTitle>
                    <OtherDetailsListContent>
                      {Array.isArray(product.data?.publishers)
                        ? product.data?.publishers?.map((pub, index) => (
                            <div key={index}>{pub}</div>
                          ))
                        : null}
                    </OtherDetailsListContent>
                  </OtherDetailsListItem>
                ) : null}

                {Array.isArray(product.data?.developers) &&
                product.data?.developers?.length >= 1 ? (
                  <OtherDetailsListItem>
                    <OtherDetailsListTitle>
                      {developers_msg}
                    </OtherDetailsListTitle>
                    <OtherDetailsListContent>
                      {Array.isArray(product.data?.developers)
                        ? product.data?.developers?.map((pub, index) => (
                            <div key={index}>{pub}</div>
                          ))
                        : null}
                    </OtherDetailsListContent>
                  </OtherDetailsListItem>
                ) : null}

                {product.data?.ageRating ? (
                  <OtherDetailsListItem>
                    <OtherDetailsListTitle>
                      {age_rating_msg}
                    </OtherDetailsListTitle>
                    <OtherDetailsListContent>
                      {exportPegiIcons(
                        product.data?.ageRating,
                        '30px',
                        '30px',
                        '0'
                      )}
                      {}
                    </OtherDetailsListContent>
                  </OtherDetailsListItem>
                ) : null}
              </OtherDetailsList>
              <GWTabletWrapper>
                {product.data?.language?.length > 0 ? (
                  <LanguagesContainer>
                    <div>
                      <OtherDetailsListTitle>
                        {languages_msg}
                      </OtherDetailsListTitle>
                      <OtherDetailsListContent style={{ maxWidth: 680 }}>
                        {Array.isArray(product.data?.language)
                          ? product.data?.language?.map((lang, index) => (
                              <span key={index}>
                                {lang}
                                {''}
                                {index === product.data?.language?.length - 1
                                  ? ''
                                  : ', '}
                              </span>
                            ))
                          : null}
                      </OtherDetailsListContent>
                    </div>
                  </LanguagesContainer>
                ) : null}
              </GWTabletWrapper>
            </OtherDetailsWrapper>
          </OtherDetailsContainer>
          {Array.isArray(product.data?.screenshots) &&
          product.data?.screenshots?.length >= 1 ? (
            <ScreenshotsContainer id="product-screenshots">
              <Title>{screenshots_msg}</Title>
              {Array.isArray(product.data?.screenshots) &&
              product.data?.screenshots?.length > 0 &&
              product.data?.screenshots?.some(
                (cur) => cur.url_original || cur.urlOriginal
              ) ? (
                <ProductImagesSlider
                  videos={product.data?.videos}
                  screenshots={product.data?.screenshots}
                  setProductImageModal={props.setProductImageModal}
                  setIndex={props.setIndex}
                />
              ) : null}
            </ScreenshotsContainer>
          ) : null}
          {trendingProducts && (
            <PdpTrendingProducts products={trendingProducts} />
          )}
        </div>
      </GWWebWrapper>
      <GWMobileWrapper>
        <div>
          <OtherOffersSection
            offers={valuesToBeUsed.offers}
            product={product.data}
            valuesToBeUsed={valuesToBeUsed}
            id="product-other-offers-mobile"
          />
          {/* {width && width <= getNumFromResolutionConstant(WIDTH.tabletMax) && (
            <RecommendedProducts product={product.data} />
          )} */}
          {/* <AboutGameWrapper> */}
          {Array.isArray(product.data?.screenshots) &&
          product.data?.screenshots?.length >= 1 ? (
            <ScreenshotsContainer id="product-screenshots">
              <Title>{screenshots_msg}</Title>
              {Array.isArray(product.data?.screenshots) &&
              product.data?.screenshots?.length > 0 &&
              product.data?.screenshots?.some(
                (cur) => cur.url_original || cur.urlOriginal
              ) ? (
                <ProductImagesSlider
                  videos={product.data?.videos}
                  screenshots={product.data?.screenshots}
                  setProductImageModal={props.setProductImageModal}
                  setIndex={props.setIndex}
                />
              ) : null}
            </ScreenshotsContainer>
          ) : null}

          {product.data?.productId && (
            <div ref={fbtRefMobile.ref}>
              <PdpFBT />
            </div>
          )}

          {/* {getProductRecommendationType() === 'recombee' &&
          showRecombeeProductRecommendations() ? (
            <> */}
          {
            <div ref={recommRefMobile.ref}>
              {recombeeRecommendations && (
                <RecombeeRecommendedProducts
                  products={recombeeRecommendations?.list}
                  recommendationId={recombeeRecommendations?.id}
                  titleName="pdp_you_may_also_like"
                  title={you_may_also_like_msg ?? ''}
                  showAddToCartBtn={false}
                />
              )}
            </div>
          }
          {/* </>
          ) : (
            <LazySimilarProducts
              products={similarProductsHook.products}
              loading={similarProductsHook.loading}
              showCountMobile={similarProductsHook.showCountMobile}
              handleSeeMore={similarProductsHook.handleSeeMore}
              handleScrollClick={similarProductsHook.handleScrollClick}
              handleScroll={similarProductsHook.handleScroll}
              scrollButtonDisable={similarProductsHook.scrollButtonDisable}
              sliderRef={null}
            />
          )} */}

          <LazyProductReviews
            reviews={productReviewHook.reviews}
            isLoading={productReviewHook.isLoading}
            visibleReviews={productReviewHook.visibleReviews}
            handleLoadMore={productReviewHook.handleLoadMore}
            setShowReviewModal={productReviewHook.setShowReviewModal}
            postReviewLoading={productReviewHook.postReviewLoading}
            showReviewModal={productReviewHook.showReviewModal}
            handleCloseModal={productReviewHook.handleCloseModal}
            handleOptionClick={productReviewHook.handleSortToggle}
            selectedOption={productReviewHook.sortingOrder}
            sortOptions={productReviewHook.sortOptions}
            isReviewed={productReviewHook.isReviewed}
          />

          <PDPAboutGame />
          {/* <PdpBanners
            productType={product.data.productTypes[0]}
            productSlug={product.data.slug}
          /> */}

          <ProductRequirements
            systemRequirements={product.data.systemRequirements}
            id="product-system-requirements-mobile"
          />
          <OtherDetailsContainer id="product-other-details">
            <OtherDetailsTitle>
              <h3>{other_details_msg}</h3>
            </OtherDetailsTitle>
            <OtherDetailsWrapper>
              {product.data?.language?.length > 0 ? (
                <LanguagesContainer>
                  <div>
                    <OtherDetailsListTitle>
                      {languages_msg}
                    </OtherDetailsListTitle>
                    <OtherDetailsListContent style={{ maxWidth: 680 }}>
                      {Array.isArray(product.data?.language)
                        ? product.data?.language?.map((lang, index) => (
                            <span key={index}>
                              {lang}
                              {''}
                              {index === product.data?.language?.length - 1
                                ? ''
                                : ', '}
                            </span>
                          ))
                        : null}
                    </OtherDetailsListContent>
                  </div>
                </LanguagesContainer>
              ) : null}
              <OtherDetailsList
                hasLanguages={product.data?.language?.length > 0}
              >
                {product.data.releaseDate ? (
                  <OtherDetailsListItem>
                    <OtherDetailsListTitle>
                      {release_date_msg}
                    </OtherDetailsListTitle>
                    <OtherDetailsListContent>
                      {product.data?.releaseDate}
                    </OtherDetailsListContent>
                  </OtherDetailsListItem>
                ) : null}
                {Array.isArray(product.data?.publishers) &&
                product.data?.publishers?.length >= 1 ? (
                  <OtherDetailsListItem>
                    <OtherDetailsListTitle>
                      {publishers_msg}
                    </OtherDetailsListTitle>
                    <OtherDetailsListContent>
                      {Array.isArray(product.data?.publishers)
                        ? product.data?.publishers?.map((pub, index) => (
                            <div key={index}>{pub}</div>
                          ))
                        : null}
                    </OtherDetailsListContent>
                  </OtherDetailsListItem>
                ) : null}

                {Array.isArray(product.data?.developers) &&
                product.data?.developers?.length >= 1 ? (
                  <OtherDetailsListItem>
                    <OtherDetailsListTitle>
                      {developers_msg}
                    </OtherDetailsListTitle>
                    <OtherDetailsListContent>
                      {Array.isArray(product.data?.developers)
                        ? product.data?.developers?.map((pub, index) => (
                            <div key={index}>{pub}</div>
                          ))
                        : null}
                    </OtherDetailsListContent>
                  </OtherDetailsListItem>
                ) : null}

                {product.data?.ageRating ? (
                  <OtherDetailsListItem>
                    <OtherDetailsListTitle>
                      {age_rating_msg}
                    </OtherDetailsListTitle>
                    <OtherDetailsListContent>
                      {exportPegiIcons(
                        product.data?.ageRating,
                        '30px',
                        '30px',
                        '0'
                      )}
                      {}
                    </OtherDetailsListContent>
                  </OtherDetailsListItem>
                ) : null}
              </OtherDetailsList>
            </OtherDetailsWrapper>
          </OtherDetailsContainer>
          {trendingProducts && (
            <PdpTrendingProducts products={trendingProducts} />
          )}
          <div style={{ height: '32px' }} />
        </div>
      </GWMobileWrapper>
    </InformationContainer>
  );
};

export default memo(ProductInformation);
