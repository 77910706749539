import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const DescriptionHeadContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const DescriptionHeadWrapper = styled.div`
  margin-bottom: 16px;
  background: transparent;
  border-radius: 8px;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 16px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-bottom: 0;
    border-radius: 8px;
  }
`;

export const DescriptionContentContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    margin-top: 0;
  }
`;

export const DescriptionPadding = styled.div`
  padding-bottom: 48px;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 0px 24px 16px 24px;
  }
`;

export const DescriptionHeadFoot = styled.div`
  padding: 10px;
  display: flex;
  color: black;
  justify-content: space-between;
  font-size: 14px;
`;

export const IconContainer = styled.div`
  margin-right: 14px;
  span {
    margin-right: 0;
  }
`;

export const ShareGame = styled.div`
  display: flex;
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-direction: column;
  }
`;

export const ShareIconsContainer = styled.div`
  display: flex;
  margin-left: 10px;
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-top: 10px;
    margin-left: 0;
  }
`;
