import Image from 'next/image';
import { memo } from 'react';
import {
  ImageBorder,
  ImageWrapper,
} from '../OtherOffersRow/OtherOffersRowStyle';

interface Props {
  storeIcon: string;
  storeName: string;
  height?: string;
  width?: string;
}

function CircularStoreImage({ storeIcon, storeName, height, width }: Props) {
  return (
    <ImageWrapper>
      <ImageBorder height={height} width={width} />
      <Image
        src={storeIcon}
        alt={storeName ?? ''}
        height={height || '40px'}
        width={width || '40px'}
        objectFit="cover"
        objectPosition="center"
        unoptimized
        priority={false}
      />
    </ImageWrapper>
  );
}

export default memo(CircularStoreImage);
