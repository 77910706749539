import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTheme } from 'styled-components';
import { useLocale } from '../../contexts/LocalizationContext';
import { usePdpContext } from '../../contexts/PdpPageContext';
import { GWWebWrapper } from '../../styles/window.style';
import { saveEventV3 } from '../../utils/eventTracking';
import { MaskIcon } from '../Icons/Icons';
import { ProductImg, ZoomContainer } from './ProductImageStyle';

interface Props {
  image: string;
  alt: string;
  productImageModal: boolean;
  setProductImageModal: (x: boolean) => void;
}

const ProductImage = (props: Props) => {
  const { product } = usePdpContext();
  const theme = useTheme();
  const { messages } = useLocale();
  const { view_msg } = messages || {};

  const [expandSS, setExpandSS] = useState<boolean>(false);
  const router = useRouter();

  const handleExpandToggle = () => {
    setExpandSS((prev) => !prev);
  };

  return (
    <>
      <ProductImg
        onMouseEnter={handleExpandToggle}
        onMouseLeave={handleExpandToggle}
        onClick={
          product.data.screenshots.length > 0
            ? () => {
                props.setProductImageModal(true);
                saveEventV3({
                  category: 'pdp_info',
                  action: 'click',
                  label: 'main_product_image',
                  properties: router.query.slug as string,
                  value: ['available'],
                  from: router,
                });
              }
            : () => {
                saveEventV3({
                  category: 'pdp_info',
                  action: 'click',
                  label: 'main_product_image',
                  properties: router.query.slug as string,
                  value: ['not-available'],
                  from: router,
                });
              }
        }
      >
        <Image
          src={props.image}
          alt={props.alt}
          layout="fill"
          objectFit="cover"
          unoptimized
          priority
        />
        {product.data.screenshots.length > 0 && (
          <ZoomContainer style={{ width: 'fit-content' }}>
            <MaskIcon
              url="/icons/view-images.svg"
              width="20px"
              height="20px"
              selected
              margin="0"
              color={'#000'}
              style={{ minWidth: '20px' }}
            />
            <GWWebWrapper>
              <p>{view_msg}</p>
            </GWWebWrapper>
          </ZoomContainer>
        )}
      </ProductImg>
    </>
  );
};

export default ProductImage;
