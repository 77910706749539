import { memo, useCallback, useEffect, useState } from 'react';
import { ProductOfferInterface } from '../../Interface/ProductDetailsInterface';
import { H4Title } from '../../styles/pageStyles/commonStyle';

import { isNumber } from 'lodash';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';
import { ProductDetailsInterface } from '../../Interface/ProductDetailsInterface';
import { useLocale } from '../../contexts/LocalizationContext';
import { usePdpContext } from '../../contexts/PdpPageContext';
import { McPdpData } from '../../pages/[slug]';
import { saveEventV3 } from '../../utils/eventTracking';
import { MaskIcon } from '../Icons/Icons';
import Button from '../NewButton/Button';
import OtherOfferRowV2 from '../OtherOffersRow/OtherOfferRowV2';
import PageSection from '../PageSection/PageSection';
import OtherOfferFilter from './OtherOfferFilter';
import {
  ButtonContainer,
  OtherOffersListContainer,
  OtherOffersWrapper,
} from './OtherOffersSectionStyle';

interface Props {
  product: ProductDetailsInterface;
  offers: Array<ProductOfferInterface>;
  valuesToBeUsed: McPdpData;
  id: string;
}

const INITIAL_OFFERS_TO_SHOW = 4;

const OtherOffersSection = (props: Props) => {
  const router = useRouter();
  const theme = useTheme();

  const { primarySeller } = usePdpContext();

  const { messages } = useLocale();
  const {
    no_offers_available_msg,
    view_more_msg,
    other_offers_msg,
    load_number_more_offers_msg,
    see_more_offers_msg,
  } = messages || {};

  const [offers, setOffers] = useState<Array<ProductOfferInterface>>(
    props.offers?.length > 0 &&
      router?.query?.seller &&
      isNumber(Number(router?.query?.seller))
      ? []
      : props?.offers
          ?.filter((cur) => !cur.isFeatured)
          ?.filter((cur) => cur.offerId !== primarySeller?.offerId)
  );
  const [maxShownOffers, setMaxShownOffers] = useState<number>(
    INITIAL_OFFERS_TO_SHOW
  );
  const [isSellerCentric, setIsSellerCentric] = useState<boolean>(false);

  const [selectedOption, setSelectedOption] = useState<string>('Best Price');
  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
    saveEventV3({
      category: 'other_offers',
      action: 'click',
      label: 'filter',
      properties: '',
      value: [value],
      from: router,
    });
  };

  useEffect(() => {
    if (offers.length > 0) {
      if (router.query.seller && isNumber(Number(router.query.seller))) {
        if (
          offers.filter(
            (cur) => Number(cur.sellerId) === Number(router.query.seller)
          )[0]
        ) {
          setIsSellerCentric(true);
        }
      }
    }
  }, [offers, router]);

  useEffect(() => {
    if (Array.isArray(props.offers)) {
      if (props.offers.findIndex((cur) => !!cur.isFeatured) >= 0) {
        setOffers(props.offers.filter((cur) => !cur.isFeatured));
      } else {
        setOffers(props.offers.slice(1));
      }
      // setOffers(props.offers);
    }
  }, [props]);

  const handleShowMore = useCallback(() => {
    if (Array.isArray(offers) && offers.length > 3) {
      setMaxShownOffers((prevMax: number) => {
        if (prevMax >= offers.length) {
          return prevMax + INITIAL_OFFERS_TO_SHOW;
        }
        return offers.length;
      });
    }
  }, [offers]);

  const showButton = useCallback(() => {
    return (
      offers.length > INITIAL_OFFERS_TO_SHOW && maxShownOffers !== offers.length
    );
  }, [offers, maxShownOffers]);

  return (
    <>
      {!isSellerCentric && Array.isArray(offers) && offers.length > 0 ? (
        <OtherOffersWrapper id={props.id}>
          <PageSection
            title={other_offers_msg ?? ''}
            sub={
              props.offers.length > 2 ? (
                <OtherOfferFilter
                  handleOptionClick={handleOptionClick}
                  selectedOption={selectedOption}
                />
              ) : null
            }
          >
            <OtherOffersListContainer>
              {offers && offers.length > 0 ? (
                offers
                  .sort((a, b) => {
                    if (selectedOption.includes('Price')) {
                      return a.price - b.price;
                    } else if (
                      selectedOption.includes('Rating') &&
                      a.sellerRatings &&
                      b.sellerRatings
                    ) {
                      return b.sellerRatings - a.sellerRatings;
                    } else {
                      return a.price - b.price;
                    }
                  })
                  .slice(0, maxShownOffers)
                  .filter((cur) => !cur.isFeatured)
                  .map((offer, index) => (
                    <OtherOfferRowV2
                      key={index}
                      offer={offer}
                      product={props.product}
                      offers={offers}
                      position={index}
                      valuesToBeUsed={props.valuesToBeUsed}
                    />
                  ))
              ) : (
                <H4Title>{no_offers_available_msg}</H4Title>
              )}
            </OtherOffersListContainer>
            <>
              {showButton() && (
                <ButtonContainer>
                  <Button
                    buttonTheme="grey"
                    ariaLabel="view more"
                    onClick={handleShowMore}
                    style={{
                      borderRadius: '20px',
                      font: '.875/1.125rem Onest-Bold',
                    }}
                    height="40px"
                  >
                    {load_number_more_offers_msg
                      ? load_number_more_offers_msg.replace(
                          '[--NUMBER--]',
                          (offers.length - INITIAL_OFFERS_TO_SHOW).toString() ??
                            ''
                        )
                      : see_more_offers_msg}
                    <MaskIcon
                      url="/icons/arrow-right-chevron-24.svg"
                      height="20px"
                      width="20px"
                      margin="0 0 0 -4px"
                      color={theme.palette.text.t1}
                      selected={true}
                      style={{ transform: 'rotate(90deg)' }}
                    />
                  </Button>
                </ButtonContainer>
              )}
            </>
          </PageSection>
        </OtherOffersWrapper>
      ) : null}
    </>
  );
};

export default memo(OtherOffersSection);
