import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const BreadcrumbsContainer = styled.div`
  margin-bottom: 22px;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: none;
  }
`;

export const BreadCrumbsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`;

export const BreadCrumbsListItem = styled.li`
  margin: 0;
  padding: 0;
  margin-right: 8px;
  color: #969696;
  font-size: 14px;
  font-family: Onest-Medium;

  &:not(:last-child) {
    &:after {
      content: '›';
      margin: 0px 10px;
      font-size: 16px;
      line-height: 14px;
      color: #969696;
      font-family: Onest-Bold;
    }
  }
`;

export const CrumbText = styled.span`
  &:hover {
    text-decoration: underline;
  }
`;
