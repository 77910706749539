import {
  Pegi12BackgroundIcon,
  Pegi16BackgroundIcon,
  Pegi18BackgroundIcon,
  Pegi3BackgroundIcon,
  Pegi7BackgroundIcon,
} from './Icons';

export const exportPegiIcons = (
  x: string,
  height: string,
  width: string,
  margin: string
) => {
  switch (x) {
    case 'PEGI 3':
      return (
        <Pegi3BackgroundIcon height={height} width={width} margin={margin} />
      );
    case 'PEGI 7':
      return (
        <Pegi7BackgroundIcon height={height} width={width} margin={margin} />
      );
    case 'PEGI 12':
      return (
        <Pegi12BackgroundIcon height={height} width={width} margin={margin} />
      );
    case 'PEGI 16':
      return (
        <Pegi16BackgroundIcon height={height} width={width} margin={margin} />
      );

    case 'PEGI 18':
      return (
        <Pegi18BackgroundIcon height={height} width={width} margin={margin} />
      );

    default:
      return x;
  }
};
