import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const ProdutImageContent = styled.div`
  // min-width: 120px;
  // cursor: pointer;
  @media (max-width: ${WIDTH.tabletMax}) {
    // min-width: 90px;
  }
  @media (max-width: ${WIDTH.mobileMid}) {
    // min-width: 80px;
  }
`;

export const ProductImg = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.palette.border.b1};
  cursor: pointer;
  min-width: 184px;
  min-height: 248px;
  @media (max-width: ${WIDTH.tabletMax}) {
    min-width: 100px;
    min-height: 132px;
  }
  @media (max-width: ${WIDTH.mobileMid}) {
    min-width: 100px;
    min-height: 132px;
  }
`;

export const ZoomContainer = styled.div`
  padding: 8px;
  border-radius: 24px;
  background: #ffffffb3;
  position: absolute;
  bottom: 12px;
  left: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  line-height: 16px;
  width: calc(100% - 24px);
  place-content: start;
  overflow: hidden;
  & p {
    white-space: nowrap;
    color: #000;
    font-family: Onest-SemiBold;
  }

  // @media (max-width: ${WIDTH.mobileMax}) {
  //   display: none;
  // }
`;
