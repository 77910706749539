import axios from 'axios';
import { GetServerSideProps } from 'next';
import {
  ProductDetailsInterface,
  ProductOfferInterface,
} from '../../Interface/ProductDetailsInterface';
import { apiConstants } from '../../constants/apiConstants';

import { LocationDetailsInterface } from '../../Interface/GlobalInterface';
import PDPPage from '../../components/PDPPage';
import { PdpPageProvider } from '../../contexts/PdpPageContext';
import { ssrCommonMessages } from '../../localization/ssr/common';
import { ssrPDPMessages } from '../../localization/ssr/pdp';
import {
  getCountryListBySsr,
  getIPAddressServerSide,
  getUserInfoSSR,
} from '../../utils/cf';
import { getSelectedLocaleForSSR } from '../../utils/getSelectedLocaleForSSR';

export interface McPdpData {
  offers: Array<ProductOfferInterface>;
  highestOfferValue: number;
  lowestOfferValue: number;
  slug: string;
  multiplier: number | null;
  currency: string | null | undefined;
  currencySymbol: string | null | undefined;
}

interface Props {
  product: {
    data: ProductDetailsInterface;
    currentSeller?: string;
  };
  mcPdpData: McPdpData | null;
  slug: string;
  primarySeller: ProductOfferInterface | null;
  sellerCentric?: boolean;
}

const PDP = ({ mcPdpData, ...props }: Props) => {
  return (
    <PdpPageProvider
      product={props.product}
      mcPdpData={mcPdpData}
      primarySeller={props.primarySeller}
      sellerCentric={props?.sellerCentric ?? false}
    >
      <PDPPage {...props} />
    </PdpPageProvider>
  );
};

export default PDP;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const localeData = await import(
    `../../localization/languages/${context?.locale ?? 'en'}.ts`
  );

  const ssrMessages = getSelectedLocaleForSSR(localeData.default, {
    ...ssrPDPMessages,
    ...ssrCommonMessages,
  });

  let product: any = null; //ak-TODO
  let curr = null;
  let countryList: Array<LocationDetailsInterface> = [];
  let multiplier = null;
  let currencySymbol = null;

  const { slug } = context.params!; // ! is a non-null assertion
  const { currency, country, seller } = context.query!;

  if (currency) {
    curr = currency;
  }

  // await axios
  //   .get(apiConstants.search + '/countries', {
  //     withCredentials: true,
  //     headers: {
  //       Cookie: `currency=${currency || context.req.cookies['currency']};`, //not-being used
  //     },
  //   })
  //   .then((response) => {
  //     countryList = response.data.countryList;
  //   })
  //   .catch((error) => {});
  countryList = await getCountryListBySsr();

  if (Array.isArray(countryList) && currency) {
    let country = countryList.find((c) => c.currency === currency);
    if (country) {
      multiplier = country.multiplier;
      currencySymbol = country.currency_symbol;
    }
  }

  const REGION = process.env.REGION ?? '';
  let uri: string =
    apiConstants.ssrServer.server.replace('[--REGION--]', `${REGION}`) +
    `/public/catalog/v2/products/${slug}?`;

  if (!!Number(seller)) {
    uri += `seller=${seller}&`;
  }

  if (context.locale) {
    uri += `locale=${context.locale}&`;
  }
  if (context.query) {
    const { language, version, region, platform } = context.query;
    uri =
      uri +
      `language=${language || ''}&version=${
        version ? encodeURIComponent(version as string) : ''
      }&region=${region || ''}&platform=${platform || ''}&`;
  }

  const newHeaders: any = {};

  let userIp = getIPAddressServerSide(context.req);
  if (userIp) {
    newHeaders['x-ip-address'] = userIp;
  }

  const userInfo = await getUserInfoSSR(context.req, context.res);

  let redirection = '';
  await axios
    .get(uri, {
      headers: newHeaders,
      // TODO: Remove this timeout in production as in Dev NODE_ENV is production
      ...(process.env.NODE_ENV === 'production' ? { timeout: 5000 } : {}),
    })
    .then((response) => {
      if (
        response.data.status === '301' &&
        response.data.message === 'redirect'
      ) {
        redirection =
          (context.locale === 'en' ? '' : '/' + context.locale + '/') +
          response.data.data.new_state +
          (context.req.url
            ? context?.req?.url?.split('?').length > 1
              ? '?' + context?.req?.url?.split('?')[1]
              : ''
            : '');
        return;
      } else {
        product = response?.data;
      }
    })
    .catch(async (error) => {
      // fallback
      // if (uri.includes('/catalog/v2')) {
      //   await axios
      //     .get(uri.replace('/catalog/v2', '/catalog'))
      //     .then((response) => {
      //       product = response?.data;
      //     })
      //     .catch((err) => {
      //       product = null;
      //     });
      // } else {
      console.log(
        JSON.stringify({
          request: 'invalid-pdp-request',
          url: uri,
          error: error.message,
          userInfo: userInfo,
        })
      );
      product = null;
      // }
    });

  if (redirection) {
    return {
      redirect: {
        destination: '/' + redirection,
        permanent: false,
      },
    };
  }

  if (product) {
    let newOffers: Array<ProductOfferInterface> | null = null;
    if (product && Array.isArray(product?.data?.offers)) {
      let offers = product?.data?.offers.filter(
        (offer: any) => Number(offer.price) > 0
      );

      offers = offers.map((offer: ProductOfferInterface) => {
        let updatedOffer = { ...offer };

        // delete offer.plusDiscount if userData.user.subscription === 'no'

        if (userInfo.user.subscription === 'no') {
          delete updatedOffer.plusDiscount;
        }

        return updatedOffer;
      });

      newOffers = [...offers];
      product.data.offers = newOffers;
    }

    let mcPdpData = null;
    let primarySeller = null;
    if (newOffers && newOffers.length > 0) {
      primarySeller =
        newOffers.filter((el) => !!el.isFeatured)[0] ??
        newOffers.filter((el) => !!el.isLowest)[0] ??
        newOffers[0];
    }

    if (currency && multiplier && currencySymbol) {
      mcPdpData = {
        offers: newOffers,
        slug,
        currency: curr,
        multiplier,
        currencySymbol,
      };
    }

    return {
      props: {
        locale: context?.locale ?? 'en',
        localeData: ssrMessages,
        reduxInitialState: userInfo,
        product,
        mcPdpData,
        slug,
        sellerCentric: !!seller,
        primarySeller,
      },
    };
  } else {
    return {
      notFound: true,
    };
  }
};
