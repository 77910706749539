import styled from 'styled-components';

interface ProductTypeTagWrapperProps {
  bgColor: string;
  padding: string;
}

export const ProductTypeTagWrapper = styled.div<ProductTypeTagWrapperProps>`
  display: flex;
  max-height: 26px;
  width: max-content;
  padding: ${(props) => props.padding};
  background: ${(props) => props.bgColor};
  border-radius: 6px;
`;

export const ProductTypeTagText = styled.div<{
  fontSize: number;
  lineHeight: number;
}>`
  font: ${(props) => props.fontSize}rem / ${(props) => props.lineHeight}rem
    Onest-SemiBold;
  color: #fff;
  text-transform: uppercase;
`;
