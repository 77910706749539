import { Skeleton } from '@mui/material';
import Link from 'next/dist/client/link';
import dynamic from 'next/dynamic';
import router from 'next/router';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import {
  ProductDetailsInterface,
  ProductOfferInterface,
} from '../../Interface/ProductDetailsInterface';
import { WIDTH } from '../../constants/screenResolution';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useLocale } from '../../contexts/LocalizationContext';
import { usePdpContext } from '../../contexts/PdpPageContext';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { displayCurrencyValue, isTouchDevice } from '../../helper/helper';
import useSubscriptionDiscount from '../../hooks/pdp/useSubscriptionDiscount';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { McPdpData } from '../../pages/[slug]';
import { addToCart } from '../../redux/cartSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  GWMobileWrapper,
  GWOnlyMobileWrapper,
  GWTabletWrapper,
  GWWebWrapper,
} from '../../styles/window.style';
import { saveEventV3 } from '../../utils/eventTracking';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import {
  WE_USER_EVENT_ADDED_TO_CART,
  WE_USER_EVENT_BUY_NOW_CLICKED,
} from '../../utils/we';
import AvatarRound from '../Avatar/AvatarRound';
import { BackgroundIcon, MaskIcon } from '../Icons/Icons';
import LoaderCircle from '../LoaderDots/LoaderCircle';
import Button from '../NewButton/Button';
import PriceNotFinalText from '../PriceNotFinalText/PriceNotFinalText';
import { PriceSelectInputRadio } from '../PrimarySellerCardV3/PrimarySellerCardv3Styles';
import { PriceTypeInterface } from '../PrimarySellerCardV3/components/PriceBox';
import CircularStoreImage from '../SellerDetailsCard/CircularStoreImage';
import SellerDetailsCardV3 from '../SellerDetailsCardV3/SellerDetailsCardV3';
import TooltipWithArrow from '../ToolTipWithArrow/TooltipWithArrow';
import {
  ButtonContainer,
  ButtonsWrapper,
  DiscountAndMRP,
  DividerLine,
  DividerLineVertical,
  MembershipButtonContainer,
  NewStoreTag,
  OffersStoreTag,
  OffersStoreTagContainer,
  PriceContainer,
  PriceValueOthers,
  RatingContainer,
  Row,
  RowContainer,
  RowLeft,
  RowRight,
  SellerAvatarContainer,
  SellerDetailsContainer,
  SellerHistoryContainer,
  SellerNameContainer,
} from './OtherOffersRowStyle';

const ProductCheckoutConfirmationMobile = dynamic(
  () =>
    import(
      '../ProductCheckoutConfirmationMobile/ProductCheckoutConfirmationMobile'
    ),
  {
    ssr: false,
  }
);

const ProductAddedModal = dynamic(
  () => import('../ProductAddedModal/ProductAddedModal'),
  {
    ssr: false,
  }
);

interface Props {
  offer: ProductOfferInterface;
  product: ProductDetailsInterface;
  offers: Array<ProductOfferInterface>;
  valuesToBeUsed: McPdpData;
  position: number;
}

const OtherOfferRowV2 = (props: Props) => {
  const { messages } = useLocale();
  const { buy_now_msg, go_to_cart_msg, ratings_msg, add_to_cart_msg, new_msg } =
    messages || {};

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { cart } = useAppSelector((state) => state);
  const { locationDetails } = useAppSelector((state) => state.global);
  const { dispatchSnackbar } = React.useContext(SnackbarContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [addToCartLoading, setAddToCartLoading] = useState<boolean>(false);
  const [buyNowLoading, setBuyNowLoading] = useState<boolean>(false);
  const [subscriptionBuyNowLoading, setSubscriptionBuyNowLoading] =
    useState<boolean>(false);
  const { cleverTap } = useAnalytics();
  const { width } = useWindowDimensions();
  const user = useAppSelector((state) => state.user);
  const [showCartConfirmation, setShowCartConfirmation] =
    useState<boolean>(false);
  const { handleCartAction, handleSelectRadioOffer, selectedRadioOffer } =
    usePdpContext();

  const [hoverState, setHoverState] = useState<boolean>(false);

  const handleMouseEnter = useCallback(() => {
    setHoverState(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoverState(false);
  }, []);

  const [hasOfferSubscriptionDiscount, setHasOfferSubscriptionDiscount] =
    useState(Number(props.offer?.plusDiscount ?? 0) > 0);

  useEffect(() => {
    if (Number(props.offer?.plusDiscount ?? 0) > 0) {
      setHasOfferSubscriptionDiscount(true);
    } else {
      setHasOfferSubscriptionDiscount(false);
    }
  }, [props.offer?.plusDiscount]);

  const discount = useMemo(() => {
    if (
      props.product &&
      props.offer.price &&
      Number(props.product.mrp) > Number(props.offer.price)
    ) {
      return Math.round(
        ((Number(props.product.mrp) - Number(props.offer.price)) * 100) /
          Number(props.product.mrp)
      );
    }
    return null;
  }, [props.product, props.offer.price]);

  const isProductInCart = useMemo(() => {
    return cart.cartItems.some((item) => item.offerId === props.offer.offerId);
  }, [cart, props.offer]);

  const handleBuyNowClick = useCallback(
    async (e: React.MouseEvent, variant: PriceTypeInterface) => {
      e.preventDefault();
      if (addToCartLoading || buyNowLoading) return;

      if (variant === 'membership') {
        setSubscriptionBuyNowLoading(true);
      } else {
        setBuyNowLoading(true);
      }
      const cartItems = cart.cartItems;
      saveEventV3({
        category: 'other_offers',
        action: 'click',
        label:
          user.subscription === 'inactive' && variant === 'membership'
            ? 'plus_buy_now'
            : 'buy_now',
        properties: String(props.position),
        value: [props.offer.offerId.toString()],
        jsonData: {
          price: props.offer.price.toString(),
          slug: props.product.slug,
          discount: discount ? (discount > 99 ? 99 : discount) : 0,
          currency: locationDetails.currency ?? '',
          sellerId: props?.offer?.sellerId,
        },
        from: router,
      });

      try {
        await dispatch(
          addToCart({
            offerId: props.offer?.offerId,
            haveSubscriptionInCart:
              variant === 'membership' && user.subscription === 'inactive',
            cartItems,
            product: props.product,
            offers: props.offers,
            locationDetails,
            qty: 1,
            router,
          })
        );
        WE_USER_EVENT_BUY_NOW_CLICKED(
          {
            currency: locationDetails.currency ?? '',
            discount: discount ? (discount > 99 ? 99 : discount) : 0,
            imageUrl: props.product.coverImageOriginal,
            productName: props.product.name,
            pageUrl: props.product.slug,
            platform: props.product.platform,
            productId: props.product.productId.toString(),
            productType: props.product.productTypes.toString(),
            productUrl: props.product.slug,
            basePrice: props.offer.price.toString(),
            countries: locationDetails.country_name ?? '',
          },
          cleverTap
        );
        router.push('/cart');
      } catch (err: any) {
        dispatchSnackbar({
          type: 'failure',
          payload: err?.message,
        });
      } finally {
        if (variant === 'membership') {
          setSubscriptionBuyNowLoading(false);
        } else {
          setBuyNowLoading(false);
        }
      }
    },
    [
      addToCartLoading,
      buyNowLoading,
      cart.cartItems,
      cleverTap,
      discount,
      dispatch,
      dispatchSnackbar,
      locationDetails,
      props.offer.offerId,
      props.offer.price,
      props.offer?.sellerId,
      props.offers,
      props.position,
      props.product,
      user.subscription,
    ]
  );

  const handleAddToCartClick = useCallback(
    async (e: React.MouseEvent, variant: PriceTypeInterface) => {
      e.preventDefault();
      if (addToCartLoading || buyNowLoading) return;
      const cartItems = cart.cartItems;
      if (isProductInCart) {
        router.push('/cart');
        return;
      }

      setAddToCartLoading(true);

      saveEventV3({
        category: 'other_offers',
        action: 'click',
        label:
          user.subscription === 'inactive' && variant === 'membership'
            ? 'plus_add_to_cart'
            : 'add_to_cart',
        properties: String(props.position),
        value: [props.offer.offerId.toString()],
        jsonData: {
          price: props.offer.price.toString(),
          slug: props.product.slug,
          discount: discount ? (discount > 99 ? 99 : discount) : 0,
          currency: locationDetails.currency ?? '',
          sellerId: props?.offer?.sellerId,
        },
        from: router,
      });

      try {
        await dispatch(
          addToCart({
            offerId: props.offer?.offerId,
            haveSubscriptionInCart:
              variant === 'membership' && user.subscription === 'inactive',
            cartItems,
            product: props.product,
            offers: props.offers,
            locationDetails,
            qty: 1,
            router,
          })
        );
        if (width && width > getNumFromResolutionConstant(WIDTH.mobileMax)) {
          handleOpen();
        } else {
          setShowCartConfirmation(true);
        }
        WE_USER_EVENT_ADDED_TO_CART(
          {
            currency: locationDetails.currency ?? '',
            discount: discount ? (discount > 99 ? 99 : discount) : 0,
            imageUrl: props.product.coverImageOriginal,
            productName: props.product.name,
            pageUrl: props.product.slug,
            platform: props.product.platform,
            productId: props.product.productId.toString(),
            productType: props.product.productTypes.toString(),
            productUrl: props.product.slug,
            basePrice: props.offer.price.toString(),
            countries: locationDetails.country_name ?? '',
          },
          cleverTap
        );
      } catch (err: any) {
        dispatchSnackbar({
          type: 'failure',
          payload: err?.message,
        });
      } finally {
        setAddToCartLoading(false);
      }
    },
    [
      addToCartLoading,
      buyNowLoading,
      cart.cartItems,
      cleverTap,
      discount,
      dispatch,
      dispatchSnackbar,
      isProductInCart,
      locationDetails,
      props.offer.offerId,
      props.offer.price,
      props.offer?.sellerId,
      props.offers,
      props.position,
      props.product,
      user.subscription,
      width,
    ]
  );

  const closeCheckoutConfirmation = useCallback(() => {
    setShowCartConfirmation(false);
  }, []);

  const { subscriptionDiscount } = useSubscriptionDiscount({
    offer: props.offer,
    mrp: props.product.mrp,
  });

  const SellerDetails = () => (
    <SellerDetailsContainer>
      <SellerNameContainer>
        <Link passHref href={'/vendor/' + props.offer.sellerId}>
          <a>
            <TooltipWithArrow
              noPadding
              showArrow={false}
              placement="right-start"
              title={<SellerDetailsCardV3 offerDetails={props.offer} />}
            >
              <span
                onClick={() =>
                  saveEventV3({
                    category: 'other_offers',
                    action: 'click',
                    label: 'seller_name',
                    properties: String(props.position),
                    value: [props.offer?.sellerId?.toString()],
                    from: router,
                    jsonData: {
                      storeName: props.offer?.storeName ?? '',
                      offerId: props.offer?.offerId?.toString(),
                    },
                  })
                }
                style={{ cursor: 'pointer' }}
              >
                {props.offer.storeName}
              </span>
            </TooltipWithArrow>
          </a>
        </Link>
        <BackgroundIcon
          url={`/icons/${
            props.offer?.verificationStatus === 1 ? 'verified' : 'unverified'
          }.svg`}
          width="20px"
          height="20px"
          margin="0 0 0"
        />
        {Number(props.offer.positivity) === 0 && new_msg && (
          <NewStoreTag>
            <p>{new_msg}</p>
          </NewStoreTag>
        )}
        {props?.offer?.storeTags?.driffles_choice?.display && (
          <OffersStoreTagContainer>
            <OffersStoreTag colorTo="#4885ff">
              <BackgroundIcon
                url="/icons/driffles-choice-icon.svg"
                width={'14px'}
                height="14px"
                color={'#fff'}
                selected={true}
                margin="0 0 0"
              />
              {/* <p>{props?.offer?.storeTags?.driffles_choice?.value}</p> */}
            </OffersStoreTag>
          </OffersStoreTagContainer>
        )}
        {props?.offer?.storeTags?.top_vendor?.display && (
          <OffersStoreTagContainer>
            <OffersStoreTag colorTo="#ff8d07">
              <BackgroundIcon
                url="/icons/top-vendor-icon.svg"
                width={'12px'}
                height="12px"
                color={'#fff'}
                selected={true}
                margin="0 0 0"
              />
              {/* <p>{props?.offer?.storeTags?.top_vendor?.value}</p> */}
            </OffersStoreTag>
          </OffersStoreTagContainer>
        )}
      </SellerNameContainer>
      {!!props.offer.sellerRatings &&
        !isNaN(Number(props.offer.sellerRatings)) &&
        Number(props.offer.sellerRatings) > 0 && (
          <SellerHistoryContainer>
            <RatingContainer>
              <MaskIcon
                url="/icons/yellow-star.svg"
                width={'20px'}
                height="20px"
                color={`linear-gradient(95.83deg, #FFCC00 3.89%, #DD8600 97.43%)`}
                selected={true}
                margin="0"
              />
              <span>{Number(props.offer.sellerRatings).toFixed(1)}</span>
              <p>{ratings_msg}</p>
            </RatingContainer>
          </SellerHistoryContainer>
        )}
    </SellerDetailsContainer>
  );

  const ActionButtons = () => (
    <ButtonsWrapper>
      {isProductInCart ? (
        <Button
          buttonTheme="grey"
          onClick={() => router.push('/cart')}
          ariaLabel="go to cart"
          width="fit-content"
          height="40px"
          style={{ minWidth: '170px' }}
        >
          <MaskIcon
            url="/icons/go-to-cart-24.svg"
            width={'20px'}
            height="20px"
            color={theme.palette.text.t1}
            selected={true}
            margin="0"
          />
          {go_to_cart_msg}
        </Button>
      ) : (
        <>
          <ButtonContainer>
            <Button
              buttonTheme="grey"
              onClick={(e) =>
                handleCartAction(
                  () =>
                    handleAddToCartClick(
                      e,
                      hasOfferSubscriptionDiscount &&
                        user.subscription === 'active'
                        ? 'membership'
                        : 'regular'
                    ),
                  'add_to_cart',
                  props.offer?.refundPolicy ?? 1,
                  isProductInCart
                )
              }
              width="40px"
              height="40px"
              ariaLabel={add_to_cart_msg}
              disabled={addToCartLoading}
            >
              {addToCartLoading ? (
                <LoaderCircle />
              ) : (
                <>
                  <GWTabletWrapper>
                    <MaskIcon
                      url="/icons/add-to-cart-24.svg"
                      width={'20px'}
                      height="20px"
                      color={theme.palette.text.t1}
                      selected={true}
                      margin="0"
                    />
                  </GWTabletWrapper>
                  <GWOnlyMobileWrapper>
                    <p
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: 'Onest-SemiBold',
                        gap: 8,
                      }}
                    >
                      <MaskIcon
                        url="/icons/add-to-cart-24.svg"
                        width={'20px'}
                        height="20px"
                        color={theme.palette.text.t1}
                        selected={true}
                        margin="0"
                      />
                    </p>
                  </GWOnlyMobileWrapper>
                </>
              )}
            </Button>
          </ButtonContainer>
          <ButtonContainer>
            <MembershipButtonContainer
              membership={
                hasOfferSubscriptionDiscount && user.subscription === 'active'
              }
            >
              <Button
                buttonTheme="primary"
                onClick={(e) =>
                  handleCartAction(
                    () =>
                      handleBuyNowClick(
                        e,
                        hasOfferSubscriptionDiscount &&
                          user.subscription === 'active'
                          ? 'membership'
                          : 'regular'
                      ),
                    'buy_now',
                    props.offer?.refundPolicy ?? 1,
                    isProductInCart
                  )
                }
                ariaLabel="buy now"
                disabled={buyNowLoading}
                width="fit-content"
                height="40px"
                style={{ padding: '0 20px', minWidth: '120px' }}
              >
                {buyNowLoading ? (
                  <LoaderCircle />
                ) : (
                  <p
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontFamily: 'Onest-SemiBold',
                      gap: 8,
                    }}
                  >
                    {buy_now_msg}
                  </p>
                )}
              </Button>
            </MembershipButtonContainer>
          </ButtonContainer>
        </>
      )}
    </ButtonsWrapper>
  );

  return (
    <>
      <RowContainer
        onMouseEnter={!isTouchDevice() ? handleMouseEnter : undefined}
        onMouseLeave={!isTouchDevice() ? handleMouseLeave : undefined}
        selectedOffer={selectedRadioOffer?.offerId === props.offer.offerId}
        onClick={(event) => {
          event.preventDefault();
          if (width && width <= getNumFromResolutionConstant(WIDTH.tabletMax)) {
            handleSelectRadioOffer(props.offer);
          }
        }}
      >
        <Row>
          <GWWebWrapper>
            <RowLeft>
              <SellerAvatarContainer>
                {props.offer?.storeIcon && props.offer?.storeName ? (
                  <CircularStoreImage
                    storeIcon={props.offer.storeIcon}
                    storeName={props.offer.storeName}
                  />
                ) : (
                  <AvatarRound
                    name={props.offer.storeName?.trim() || 'D-no'}
                    variant="circular"
                    height="40px"
                    width="40px"
                  />
                )}
              </SellerAvatarContainer>
              <SellerDetails />
            </RowLeft>
          </GWWebWrapper>
          <RowRight>
            <GWWebWrapper>
              <div style={{ display: 'flex', gap: '12px', height: '40px' }}>
                {!hasOfferSubscriptionDiscount ? null : user.subscription ===
                  'active' ? (
                  <>
                    <GWWebWrapper style={{ height: '100%' }}>
                      <DiscountAndMRP
                        style={{
                          alignItems:
                            discount && discount > 0 ? 'flex-end' : 'center',
                          height: '100%',
                        }}
                      >
                        <span style={{ color: '#9D60EA' }}>
                          Saving{' '}
                          {displayCurrencyValue(
                            subscriptionDiscount.plusExtraSaving,
                            props.valuesToBeUsed.currencySymbol,
                            props.valuesToBeUsed.multiplier
                          )}{' '}
                          with{' '}
                          <BackgroundIcon
                            url="/logo/plus-logo.svg"
                            height="12px"
                            width="24px"
                            margin="0"
                          />
                        </span>
                      </DiscountAndMRP>
                    </GWWebWrapper>
                  </>
                ) : (
                  <>
                    <PriceContainer>
                      <PriceValueOthers
                        characterLength={
                          displayCurrencyValue(
                            subscriptionDiscount.plusPrice,
                            props.valuesToBeUsed.currencySymbol,
                            props.valuesToBeUsed.multiplier
                          ).length
                        }
                      >
                        {displayCurrencyValue(
                          subscriptionDiscount.plusPrice,
                          props.valuesToBeUsed.currencySymbol,
                          props.valuesToBeUsed.multiplier
                        )}
                        <PriceNotFinalText iconOnly={true} />
                      </PriceValueOthers>
                      {!hoverState ? (
                        <DiscountAndMRP>
                          <span style={{ color: '#9D60EA' }}>
                            Save{' '}
                            {displayCurrencyValue(
                              subscriptionDiscount.plusExtraSaving,
                              props.valuesToBeUsed.currencySymbol,
                              props.valuesToBeUsed.multiplier
                            )}{' '}
                            with Plus
                          </span>
                        </DiscountAndMRP>
                      ) : (
                        <>
                          {discount && discount > 0 ? (
                            <DiscountAndMRP>
                              <p>
                                {props.product.mrp &&
                                  props.product.mrp > props.offer.price &&
                                  displayCurrencyValue(
                                    props.product.mrp,
                                    props.valuesToBeUsed.currencySymbol,
                                    props.valuesToBeUsed.multiplier
                                  )}
                              </p>
                              <span style={{ color: '#9D60EA' }}>{`~ ${
                                subscriptionDiscount?.totalPlusDiscount ||
                                discount
                              }%`}</span>
                            </DiscountAndMRP>
                          ) : null}
                        </>
                      )}
                    </PriceContainer>
                    {!hoverState ? null : (
                      <MembershipButtonContainer membership={true}>
                        <Button
                          buttonTheme="primary"
                          onClick={(e) =>
                            handleCartAction(
                              () => handleBuyNowClick(e, 'membership'),
                              'buy_now',
                              props.offer?.refundPolicy ?? 1,
                              isProductInCart
                            )
                          }
                          ariaLabel="buy now"
                          disabled={subscriptionBuyNowLoading}
                          width="fit-content"
                          height="40px"
                          style={{ padding: '0 20px', minWidth: '150px' }}
                        >
                          {subscriptionBuyNowLoading ? (
                            <LoaderCircle />
                          ) : (
                            <p
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontFamily: 'Onest-SemiBold',
                                gap: 8,
                              }}
                            >
                              Buy with plus
                            </p>
                          )}
                        </Button>
                      </MembershipButtonContainer>
                    )}
                    <DividerLineVertical />
                  </>
                )}
              </div>
            </GWWebWrapper>
            <PriceContainer>
              <PriceValueOthers
                characterLength={
                  displayCurrencyValue(
                    props.offer.price,
                    props.valuesToBeUsed.currencySymbol,
                    props.valuesToBeUsed.multiplier
                  ).length
                }
              >
                {displayCurrencyValue(
                  user.subscription === 'active'
                    ? subscriptionDiscount.plusPrice
                    : props.offer.price,
                  props.valuesToBeUsed.currencySymbol,
                  props.valuesToBeUsed.multiplier
                )}
                <PriceNotFinalText iconOnly={true} />
              </PriceValueOthers>

              {discount && discount > 0 ? (
                <DiscountAndMRP>
                  <p>
                    {props.product.mrp &&
                      props.product.mrp !== props.offer.price &&
                      displayCurrencyValue(
                        props.product.mrp,
                        props.valuesToBeUsed.currencySymbol,
                        props.valuesToBeUsed.multiplier
                      )}
                  </p>
                  <span>{`~ ${
                    user.subscription === 'active'
                      ? subscriptionDiscount?.totalPlusDiscount || discount
                      : discount
                  }%`}</span>
                </DiscountAndMRP>
              ) : null}
            </PriceContainer>
            <GWWebWrapper>
              {cart.isFetching && cart.isFetching === 'fetched' ? (
                <ActionButtons />
              ) : (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: '146px',
                    borderRadius: '4px',
                    height: '40px',
                    background: theme.palette.background.bg3,
                  }}
                />
              )}
            </GWWebWrapper>
            <GWMobileWrapper>
              <PriceSelectInputRadio
                type="radio"
                checked={selectedRadioOffer?.offerId === props.offer.offerId}
                fillColor={theme.palette.primary.main}
                onChange={() => {}}
              />
            </GWMobileWrapper>
          </RowRight>
        </Row>
        <GWMobileWrapper>
          <DividerLine />
        </GWMobileWrapper>
        <Row>
          <GWMobileWrapper style={{ width: '100%' }}>
            <RowLeft>
              <SellerDetails />
            </RowLeft>
          </GWMobileWrapper>
        </Row>
      </RowContainer>

      {width && width > getNumFromResolutionConstant(WIDTH.tabletMax) ? (
        <>
          {open && (
            <ProductAddedModal
              open={open}
              handleClose={handleClose}
              product={props.product}
              seller={props.offer}
            />
          )}
        </>
      ) : (
        <>
          {showCartConfirmation && (
            <ProductCheckoutConfirmationMobile
              open={showCartConfirmation}
              product={props.product}
              offer={props.offer}
              handleClose={closeCheckoutConfirmation}
            />
          )}
        </>
      )}
    </>
  );
};

export default memo(OtherOfferRowV2);
