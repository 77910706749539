import React, { memo } from 'react';
import { useTheme } from 'styled-components';
import { usePdpContext } from '../../../../contexts/PdpPageContext';
import { MaskIcon } from '../../../Icons/Icons';

import { capitalize } from 'lodash';
import { useLocale } from '../../../../contexts/LocalizationContext';
import {
  IconContainer,
  ImportantDetailCard,
  ImportantDetailCardInfo,
} from '../../PDPPageStyles';

const RegionDetail: React.FC = () => {
  const theme = useTheme();
  const { product } = usePdpContext();

  const { messages } = useLocale();
  const { region_msg } = messages || {};

  return (
    <>
      <ImportantDetailCard>
        <IconContainer>
          <MaskIcon
            width="32px"
            height="32px"
            color={theme.palette.text.primary}
            url="/icons/new-globe-24.svg"
            style={{ minWidth: '32px' }}
            selected={true}
            margin="0"
          />
        </IconContainer>
        <ImportantDetailCardInfo>
          <p>
            {capitalize(region_msg)}:{' '}
            <span>
              {product.data.regions && product.data.regions.length > 0
                ? product.data.regions.map((cur) => cur.name).join(', ')
                : '-'}
            </span>
          </p>
        </ImportantDetailCardInfo>
      </ImportantDetailCard>
    </>
  );
};

export default memo(RegionDetail);
