import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const CardContainer = styled.div`
  box-sizing: border-box;
  flex: 3;
  background: transparent;
  border-radius: 10px;
  // padding: 0 0 0 12px;
`;

export const DescriptionSellerContainer = styled.div`
  display: none;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: block;
    flex: 1;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    display: block;
    margin-top: 0px;
  }
`;

export const CardHead = styled.div`
  display: flex;
  @media (max-width: ${WIDTH.tabletMax}) {
    flex-direction: column;
    padding: 0px;
    height: 100%;
  }
`;

export const CardTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: Onest-Bold;
  position: relative;
  @media (max-width: ${WIDTH.tabletMax}) {
    font-size: 14px;
    line-height: 22px;
  }
  font-feature-settings: 'pnum' on, 'lnum' on;

  color: ${COLORS.blackPrimary};
  flex: 3;
`;

interface Props {
  message: string;
}

export const CardTitleStyle = styled.h1`
  margin: 0;
  font-size: ${(props: Props) => (props.message.length < 60 ? '24px' : '18px')};
  line-height: ${(props: Props) =>
    props.message.length < 60 ? '30px' : '24px'};
  font-family: Onest-Bold;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${COLORS.blackPrimary};
  flex: 3;
`;

interface CardContentProps {
  nonGame: boolean;
}

export const CardContent = styled.div<CardContentProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  @media (max-width: ${WIDTH.tabletMax}) {
    grid-template-columns: 1fr;
  }
`;

export const CardContentTile = styled.div`
  // min-height: 162px;
  border-radius: 10px;
  padding: 12px;
  position: relative;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.04);
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  background: ${(props) => props.theme.palette.background.bg1};
  display: flex;
  // flex-direction: column;
  gap: 8px;
  padding-right: 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 16px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 8px;
    height: 80px;
  }
`;

export const TileIconContainer = styled.div`
  width: 24px;
  height: 24px;
  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 0;
  }
  & > span {
    vertical-align: top;
  }
`;

export const TileTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    flex-direction: column;
    align-items: start;
    margin-top: 2px;
    gap: 0;
  }
`;

interface CanActivateProps {
  type?: 'platform' | 'requirement' | 'geoLocation';
}

export const CanActivate = styled.div<CanActivateProps>`
  font-size: 14px;
  border-radius: 8px;
  color: ${(props) => props.theme.palette.text.t2};
  font-family: Onest-Regular;
  line-height: 18px;
  vertical-align: center;
  & span {
    font-family: Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
  }
  & a {
    font-family: Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    color: ${COLORS.gray3};
    margin-bottom: 0px;
    font-size: 16px;
  }
`;

export const CannotActivate = styled.div`
  font-size: 14px;
  border-radius: 8px;
  color: ${(props) => props.theme.palette.text.t2};
  font-family: Onest-Regular;
  line-height: 16.8px;
  & span {
    font-family: Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    color: ${(props) => props.theme.palette.text.t2};
    margin-bottom: 0px;
    font-size: 16px;
  }
`;
export const CheckActivation = styled.div<{ disabled?: boolean }>`
  ${(props) => (props.disabled ? '' : 'cursor: pointer;')}
  color: ${(props) =>
    props.disabled
      ? props.theme.palette.text.disabled
      : props.theme.palette.text.primary};
  font: 0.875rem/1.125rem Onest-Medium;
  width: fit-content;
  transition: 0.3s ease-in-out;
  font-smoothing: antialiased !important;
  span {
    color: ${(props) =>
      props.disabled
        ? props.theme.palette.text.disabled
        : props.theme.palette.text.primary};
  }
  &:active {
    ${(props) => (props.disabled ? '' : `transform: scale(0.95)`)}
  }
  :hover {
    text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    margin-top: 8px;
  }
`;

export const CadFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0 20px 0px;
  @media (max-width: 1006px) {
    padding: 20px 10px;
  }
`;

export const CardContentTileSubTitleText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: black;
  margin-bottom: 0px;
  flex-grow: 1;
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-grow: 0;
    color: ${(props) => props.theme.palette.text.t3};
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
  cursor: pointer;
`;
