import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { WIDTH } from '../../../constants/screenResolution';
import { useAnalytics } from '../../../contexts/AnalyticsProvider';
import { useLocale } from '../../../contexts/LocalizationContext';
import { usePdpContext } from '../../../contexts/PdpPageContext';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ProductOfferInterface } from '../../../Interface/ProductDetailsInterface';
import { addToCart } from '../../../redux/cartSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { saveEventV3 } from '../../../utils/eventTracking';
import { getNumFromResolutionConstant } from '../../../utils/getNumberFromResolutionConstant';
import { StartVibration } from '../../../utils/vibrate';
import {
  WE_USER_EVENT_ADDED_TO_CART,
  WE_USER_EVENT_BUY_NOW_CLICKED,
} from '../../../utils/we';
import { MaskIcon } from '../../Icons/Icons';
import LoaderCircle from '../../LoaderDots/LoaderCircle';
import Button from '../../NewButton/Button';
import {
  ButtonContainer,
  ButtonsWrapper,
  MobileButtonsContainer,
} from '../PrimarySellerCardv3Styles';
import { PriceTypeInterface } from './PriceBox';

const MobileBottomSticky = dynamic(
  () => import('../../MobileBottomSticky/MobileBottomSticky'),
  { ssr: false }
);

interface BuyActionsProps {
  discount: number | null;
  cardTopStrip: React.ReactNode;
  handleClickNotifyButton: (action: 'add' | 'remove') => void;
  selectedPriceType: PriceTypeInterface;
  offer: ProductOfferInterface;
}

function BuyActions({
  discount,
  cardTopStrip,
  handleClickNotifyButton,
  selectedPriceType,
  offer,
}: BuyActionsProps) {
  const theme = useTheme();
  const router = useRouter();

  const { cleverTap } = useAnalytics();

  const { messages } = useLocale();
  const {
    add_to_cart_msg,
    buy_now_msg,
    service_charges_mgs_after_method_selection,
  } = messages || {};

  const { locationDetails } = useAppSelector((state) => state.global);
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const user = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const [showBuyStickyFooter, setShowBuyStickyFooter] = useState<boolean>(true);

  const [addToCartLoading, setAddToCartLoading] = useState<boolean>(false);
  const [buyNowLoading, setBuyNowLoading] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  const { width } = useWindowDimensions();
  const {
    product,
    valuesToBeUsed,
    isWishlisted,
    primarySeller: seller,
    handleCartAction,
    selectedRadioOffer,
    toggleSubscriptionOffer,
  } = usePdpContext();

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const cart = useAppSelector((state) => state.cart);

  const isProductInCart = useMemo(() => {
    let offerToUse = selectedRadioOffer;
    if (width && width > getNumFromResolutionConstant(WIDTH.tabletMax)) {
      offerToUse = seller;
    }

    if (offerToUse) {
      return cart.cartItems.some(
        (item) => item?.offerId === offerToUse?.offerId
      );
    }
    return false;
  }, [cart.cartItems, selectedRadioOffer, seller, width]);

  const onBuyNowClick = useCallback(
    (e: React.MouseEvent, position: 'primary_seller_box' | 'mobile_sticky') => {
      e.preventDefault();

      let offerToUse = selectedRadioOffer;
      if (width && width > getNumFromResolutionConstant(WIDTH.tabletMax)) {
        offerToUse = seller;
      }

      if (!offerToUse || addToCartLoading || buyNowLoading) {
        return;
      }

      if (isProductInCart) {
        saveEventV3({
          category: position,
          action: 'click',
          label:
            user.subscription === 'inactive' &&
            (selectedPriceType === 'membership' ||
              toggleSubscriptionOffer === 'membership')
              ? 'plus_go_to_cart'
              : 'go_to_cart',
          properties: router.query.slug as string,
          value: [offerToUse?.offerId.toString()],
          jsonData: {
            price: offerToUse?.price.toString(),
            slug: product.data.slug,
            discount: discount ? (discount > 99 ? 99 : discount) : 0,
            currency: locationDetails.currency ?? '',
            isPresentInCart: true,
            sellerId: offerToUse?.sellerId,
          },
          from: router,
        });
        WE_USER_EVENT_BUY_NOW_CLICKED(
          {
            currency: locationDetails.currency ?? '',
            discount: discount ? (discount > 99 ? 99 : discount) : 0,
            imageUrl: product.data.coverImageOriginal,
            productName: product.data.name,
            pageUrl: product.data.slug,
            platform: product.data.platform,
            productId: product.data.productId.toString(),
            productType: product.data.productTypes.toString(),
            productUrl: product.data.slug,
            basePrice: offerToUse?.price.toString(),
            countries: locationDetails.country_name ?? '',
          },
          cleverTap
        );
        router.push('/cart');

        return;
      }
      setBuyNowLoading(true);

      const cartItems = cart.cartItems;
      dispatch(
        addToCart({
          offerId: offerToUse?.offerId,
          haveSubscriptionInCart:
            (selectedPriceType === 'membership' ||
              toggleSubscriptionOffer === 'membership') &&
            user.subscription === 'inactive',
          cartItems,
          product: product.data,
          offers: valuesToBeUsed.offers,
          locationDetails,
          qty: 1,
          router,
        })
      )
        .then(() => {
          saveEventV3({
            category: position,
            action: 'click',
            label:
              user.subscription === 'inactive' &&
              (selectedPriceType === 'membership' ||
                toggleSubscriptionOffer === 'membership')
                ? 'plus_buy_now'
                : 'buy_now',
            properties: router.query.slug as string,
            value: [offerToUse?.offerId?.toString() ?? ''],
            jsonData: {
              price: offerToUse?.price?.toString() ?? '',
              slug: product.data.slug,
              discount: discount ? (discount > 99 ? 99 : discount) : 0,
              currency: locationDetails.currency ?? '',
              isPresentInCart: false,
              sellerId: offerToUse?.sellerId,
            },
            from: router,
          });
          WE_USER_EVENT_BUY_NOW_CLICKED(
            {
              currency: locationDetails.currency ?? '',
              discount: discount ? (discount > 99 ? 99 : discount) : 0,
              imageUrl: product.data.coverImageOriginal,
              productName: product.data.name,
              pageUrl: product.data.slug,
              platform: product.data.platform,
              productId: product.data.productId.toString(),
              productType: product.data.productTypes.toString(),
              productUrl: product.data.slug,
              basePrice: offerToUse?.price?.toString() ?? '',
              countries: locationDetails.country_name ?? '',
            },
            cleverTap
          );
          router.push('/cart');
          StartVibration(200);
          setBuyNowLoading(false);
        })
        .catch((err: Error) => {
          dispatchSnackbar({
            type: 'failure',
            payload: err.message,
          });
          setBuyNowLoading(false);
        });
    },
    [
      seller,
      width,
      addToCartLoading,
      buyNowLoading,
      isProductInCart,
      cart.cartItems,
      dispatch,
      selectedPriceType,
      toggleSubscriptionOffer,
      user.subscription,
      product.data,
      valuesToBeUsed.offers,
      locationDetails,
      router,
      selectedRadioOffer,
      discount,
      cleverTap,
      dispatchSnackbar,
    ]
  );

  const onAddClick = useCallback(
    (e: React.MouseEvent, position: 'primary_seller_box' | 'mobile_sticky') => {
      e.preventDefault();

      let offerToUse = selectedRadioOffer;
      if (width && width > getNumFromResolutionConstant(WIDTH.tabletMax)) {
        offerToUse = seller;
      }

      if (!offerToUse || addToCartLoading || buyNowLoading) {
        return;
      }
      if (isProductInCart) {
        saveEventV3({
          category: position,
          action: 'click',
          label:
            user.subscription === 'inactive' &&
            (selectedPriceType === 'membership' ||
              toggleSubscriptionOffer === 'membership')
              ? 'plus_go_to_cart'
              : 'go_to_cart',
          properties: router.query.slug as string,
          value: [offerToUse?.offerId.toString()],
          jsonData: {
            price: offerToUse?.price.toString(),
            slug: product.data.slug,
            discount: discount ? (discount > 99 ? 99 : discount) : 0,
            currency: locationDetails.currency ?? '',
          },
          from: router,
        });
        router.push('/cart');
        return;
      }
      setAddToCartLoading(true);

      const cartItems = cart.cartItems;
      dispatch(
        addToCart({
          offerId: offerToUse?.offerId,
          haveSubscriptionInCart:
            (selectedPriceType === 'membership' ||
              toggleSubscriptionOffer === 'membership') &&
            user.subscription === 'inactive',
          cartItems,
          product: product.data,
          locationDetails,
          offers: valuesToBeUsed.offers,
          qty: 1,
          router,
        })
      )
        .then(() => {
          StartVibration(200);
          saveEventV3({
            category: position,
            action: 'click',
            label:
              user.subscription === 'inactive' &&
              (selectedPriceType === 'membership' ||
                toggleSubscriptionOffer === 'membership')
                ? 'plus_add_to_cart'
                : 'add_to_cart',
            properties: router.query.slug as string,
            value: [offerToUse?.offerId?.toString() ?? ''],
            jsonData: {
              price: offerToUse?.price?.toString() ?? '',
              slug: product.data.slug,
              discount: discount ? (discount > 99 ? 99 : discount) : 0,
              currency: locationDetails.currency ?? '',
              sellerId: offerToUse?.sellerId,
            },
            from: router,
          });
          WE_USER_EVENT_ADDED_TO_CART(
            {
              currency: locationDetails.currency ?? '',
              discount: discount ? (discount > 99 ? 99 : discount) : 0,
              imageUrl: product.data.coverImageOriginal,
              productName: product.data.name,
              pageUrl: product.data.slug,
              platform: product.data.platform,
              productId: product.data.productId.toString(),
              productType: product.data.productTypes.toString(),
              productUrl: product.data.slug,
              basePrice: offerToUse?.price?.toString() ?? '',
              countries: locationDetails.country_name ?? '',
            },
            cleverTap
          );

          // let checkIfUserIsLoggedIn = getLocalStorageString('user_data');

          // if (checkIfUserIsLoggedIn) {
          //   WE_ASK_USER_WEB_PUSH_PERMISSION(
          //     {
          //       title: get_notified_save_more_msg,
          //       description: sign_up_to_get_notified_about_special_deals_msg,
          //       router,
          //     },
          //     cleverTap
          //   );
          // }

          handleOpen();
          setAddToCartLoading(false);
        })
        .catch((err: Error) => {
          dispatchSnackbar({
            type: 'failure',
            payload: err.message,
          });
          setAddToCartLoading(false);
        });
    },
    [
      seller,
      width,
      addToCartLoading,
      buyNowLoading,
      isProductInCart,
      cart.cartItems,
      dispatch,
      selectedPriceType,
      toggleSubscriptionOffer,
      user.subscription,
      product.data,
      locationDetails,
      valuesToBeUsed.offers,
      router,
      selectedRadioOffer,
      discount,
      cleverTap,
      handleOpen,
      dispatchSnackbar,
    ]
  );

  return (
    <ButtonsWrapper>
      {/* <ButtonsContainer style={{ flexDirection: 'column-reverse' }}>
        {cart.isFetching && cart.isFetching === 'fetched' ? (
          <ButtonContainer>
            <Button
              onClick={(e) =>
                handleCartAction(
                  () => onAddClick(e, 'primary_seller_box'),
                  'add_to_cart',
                  seller?.refundPolicy ?? 1,
                  isProductInCart
                )
              }
              aria-label={add_to_cart_msg}
              disabled={addToCartLoading}
              buttonTheme="grey"
              width="100%"
              height="48px"
            >
              {addToCartLoading ? (
                <LoaderCircle />
              ) : (
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  {isProductInCart ? (
                    <>
                      <MaskIcon
                        url="/icons/go-to-cart-24.svg"
                        width={'24px'}
                        height="24px"
                        color={theme.palette.text.t1}
                        selected={true}
                        margin="0"
                      />{' '}
                      {go_to_cart_msg}
                    </>
                  ) : (
                    <>
                      <MaskIcon
                        url="/icons/add-to-cart-24.svg"
                        width={'24px'}
                        height="24px"
                        color={theme.palette.text.t1}
                        selected={true}
                        margin="0"
                      />{' '}
                      {add_to_cart_msg}
                    </>
                  )}
                </p>
              )}
            </Button>
          </ButtonContainer>
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{
              width: '100%',
              borderRadius: '4px',
              height: '48px',
              backgroundColor: theme.palette.background.bg3,
            }}
          />
        )}
        <ButtonContainer selectedPriceType={selectedPriceType}>
          <Button
            buttonTheme={'primary'}
            width="100%"
            height="48px"
            onClick={(e) =>
              handleCartAction(
                () => onBuyNowClick(e, 'primary_seller_box'),
                'buy_now',
                seller?.refundPolicy ?? 1,
                isProductInCart
              )
            }
            ariaLabel="buy now"
            disabled={buyNowLoading}
          >
            {buyNowLoading ? (
              <LoaderCircle />
            ) : (
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                {typeof product.data.isPreReleased === 'number' &&
                product.data.isPreReleased === 1 ? (
                  <>
                    <MaskIcon
                      url="/icons/event_upcoming.svg"
                      width={'24px'}
                      height="24px"
                      color={'#fff'}
                      selected={true}
                      margin="0"
                    />
                    Pre-order
                  </>
                ) : (
                  <>
                    <MaskIcon
                      url="/icons/cart-24.svg"
                      width={'24px'}
                      height="24px"
                      color={'#fff'}
                      selected={true}
                      margin="0"
                    />
                    {buy_now_msg}
                  </>
                )}
              </p>
            )}
          </Button>
        </ButtonContainer>
      </ButtonsContainer> */}
      <MobileButtonsContainer
        active={
          selectedRadioOffer?.offerId === offer?.offerId ||
          user.subscription === 'no' ||
          user.subscription === 'active'
        }
      >
        <ButtonContainer selectedPriceType={selectedPriceType}>
          <Button
            height="48px"
            width="100%"
            onClick={(e) =>
              handleCartAction(
                () => onBuyNowClick(e, 'primary_seller_box'),
                'buy_now',
                seller?.refundPolicy ?? 1,
                isProductInCart
              )
            }
            ariaLabel="buy now"
            buttonTheme={'primary'}
            disabled={buyNowLoading}
          >
            {buyNowLoading ? (
              <LoaderCircle />
            ) : (
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  fontFamily: 'Onest-Bold',
                }}
              >
                {typeof product.data.isPreReleased === 'number' &&
                product.data.isPreReleased === 1 ? (
                  <>
                    <MaskIcon
                      url="/icons/event_upcoming.svg"
                      width={'24px'}
                      height="24px"
                      color={'#fff'}
                      selected={true}
                      margin="0"
                    />
                    Pre-order
                  </>
                ) : (
                  <>
                    <MaskIcon
                      url="/icons/cart-24.svg"
                      width={'24px'}
                      height="24px"
                      color={'#fff'}
                      selected={true}
                      margin="0"
                    />
                    {buy_now_msg}
                  </>
                )}
              </p>
            )}
          </Button>
        </ButtonContainer>

        <Button
          onClick={(e) =>
            handleCartAction(
              () => onAddClick(e, 'primary_seller_box'),
              'add_to_cart',
              seller?.refundPolicy ?? 1,
              isProductInCart
            )
          }
          ariaLabel={add_to_cart_msg}
          disabled={addToCartLoading}
          height="48px"
          width="48px"
          buttonTheme="grey"
        >
          {addToCartLoading ? (
            <LoaderCircle />
          ) : (
            <p
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
              }}
            >
              {isProductInCart ? (
                <MaskIcon
                  url="/icons/go-to-cart-24.svg"
                  width={'24px'}
                  height="24px"
                  color={theme.palette.text.t1}
                  selected={true}
                  margin="0"
                />
              ) : (
                <MaskIcon
                  url="/icons/add-to-cart-24.svg"
                  width={'24px'}
                  height="24px"
                  color={theme.palette.text.t1}
                  selected={true}
                  margin="0"
                />
              )}
            </p>
          )}
        </Button>
      </MobileButtonsContainer>
      {/* <ServiceFeeText>
        Note: Service Fee will be charged after selection of payment method.
      </ServiceFeeText> */}
      {showBuyStickyFooter && selectedRadioOffer?.offerId && (
        <MobileBottomSticky
          seller={selectedRadioOffer}
          addToCartClick={onAddClick}
          buyNowClick={onBuyNowClick}
          notifyMeClick={handleClickNotifyButton}
          addToCartLoading={addToCartLoading}
          buyNowLoading={buyNowLoading}
          isWishlisted={isWishlisted}
          valuesToBeUsed={valuesToBeUsed}
          isProductInCart={isProductInCart}
          cardTopStrip={
            selectedRadioOffer.offerId === seller?.offerId ? cardTopStrip : null
          }
        />
      )}
    </ButtonsWrapper>
  );
}

export default memo(BuyActions);
