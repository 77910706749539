import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';
import { saveEventV3 } from '../../../utils/eventTracking';
import { MaskIcon } from '../../Icons/Icons';
import NewMobileCard from '../../MobileHomePageSection/NewMobileCard';
import { IconCircle, MobileCardContainer } from '../PdpFBTStyles';

interface FBTCardPropsI {
  index: number;
  product: {
    image: string;
    mrp: number | null;
    offerId: number;
    platform: string;
    price: number;
    productId: number;
    regionId: number;
    regionName: string;
    slug: string;
    title: string;
  };
  priceLength?: number;
}

const FBTCard: React.FC<FBTCardPropsI> = ({ index, product, priceLength }) => {
  const router = useRouter();
  const theme = useTheme();
  return (
    <>
      <MobileCardContainer
        onClick={() => {
          saveEventV3({
            category: 'pdp_fbt',
            action: 'click',
            label: 'product_card',
            properties: 'successful',
            value: [product.slug],
            from: router,
          });
        }}
        style={{ position: 'relative' }}
      >
        <NewMobileCard
          fullWidth
          product={product}
          regionName={product.regionName}
          page="pdp"
        />
        {index === 0 ? (
          <IconCircle>
            <MaskIcon
              url="/icons/add-24.svg"
              width="20px"
              height="20px"
              margin="0 0 0 0"
              color={theme.palette.text.t1}
              selected
            />
          </IconCircle>
        ) : null}
      </MobileCardContainer>
    </>
  );
};

export default FBTCard;
