import { useRouter } from 'next/router';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { WIDTH } from '../../../../constants/screenResolution';
import { useLocale } from '../../../../contexts/LocalizationContext';
import { usePdpContext } from '../../../../contexts/PdpPageContext';
import { formatFilterTitle } from '../../../../helper/helper';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { saveEventV3 } from '../../../../utils/eventTracking';
import { getNumFromResolutionConstant } from '../../../../utils/getNumberFromResolutionConstant';
import { MaskIcon } from '../../../Icons/Icons';
import {
  CheckTextButton,
  IconContainer,
  ImportantDetailCard,
  ImportantDetailCardInfo,
} from '../../PDPPageStyles';

export interface RequirementsI {
  minimum?: RequirementDataI;
  recommended?: RequirementDataI;
}
interface RequirementDataI {
  os?: string;
  memory?: string;
  graphics?: string;
  processor?: string;
  storage?: string;
  additional?: string;
  sound?: string;
}

const OSDetail: React.FC = () => {
  const router = useRouter();
  const theme = useTheme();
  const { product } = usePdpContext();
  const { width } = useWindowDimensions();

  const [tabs, setTabs] = useState<string[]>([]);
  const [currentRequirements, setCurrentRequirements] =
    useState<RequirementsI | null>(null);

  const { messages } = useLocale();
  const { works_on_msg, system_requirements_msg } = messages || {};

  const hasWorkOn = useCallback(() => {
    return (
      product.data.systemRequirements &&
      Object.keys(product.data.systemRequirements).length > 0
    );
  }, [product.data.systemRequirements]);

  const hasNoRequirements = useCallback(() => {
    const isObjectEmpty = (obj: Object) => {
      if (!obj) {
        return true;
      }

      return !(
        typeof obj === 'object' &&
        Object.keys(obj).length > 0 &&
        Object.values(obj).some((cur) => !!cur)
      );
    };

    return (
      tabs.length === 0 ||
      !currentRequirements ||
      ((!currentRequirements.minimum ||
        isObjectEmpty(currentRequirements.minimum)) &&
        (!currentRequirements.recommended ||
          isObjectEmpty(currentRequirements.recommended)))
    );
  }, [tabs, currentRequirements]);

  const scrollToSystemReq = useCallback(() => {
    saveEventV3({
      category: 'pdp_info',
      action: 'click',
      label: 'system_requirements_pill',
      properties: 'scroll to system requirements',
      value: [product.data.slug],
      from: router,
    });

    if (!width) {
      return;
    }

    const systemReqEl =
      width && width > getNumFromResolutionConstant(WIDTH.mobileMax)
        ? document.getElementById('product-system-requirements')
        : document.getElementById('product-system-requirements-mobile');

    if (systemReqEl) {
      const rect = systemReqEl.getBoundingClientRect();

      window.scroll(
        0,
        width <= getNumFromResolutionConstant(WIDTH.mobileMax)
          ? window.scrollY + rect.top - 79
          : window.scrollY + rect.top - 93
      );
    }
  }, [product.data.slug, router, width]);

  useEffect(() => {
    if (product.data.systemRequirements) {
      const keys = Object.keys(product.data.systemRequirements);
      const systems = keys.filter(
        (key) => key !== 'system' && key !== 'requirement'
      );
      setTabs(systems);
    }
  }, [product.data.systemRequirements]);

  useEffect(() => {
    if (product.data.systemRequirements) {
      if (tabs.length > 0) {
        setCurrentRequirements(product.data.systemRequirements['windows']);
      }
    }
  }, [tabs, product.data.systemRequirements]);

  return !hasWorkOn() ? null : (
    <ImportantDetailCard>
      <IconContainer>
        <MaskIcon
          url="/icons/sync_saved_locally.svg"
          width="32px"
          height="32px"
          color={theme.palette.text.primary}
          style={{ minWidth: '32px' }}
          margin="0"
          selected
        />
      </IconContainer>
      <ImportantDetailCardInfo>
        <div>
          <p>
            {works_on_msg}:{' '}
            <span>
              {product.data.systemRequirements &&
              Object.keys(product.data.systemRequirements).filter(
                (cur) => cur !== 'system' && cur !== 'requirement'
              ).length > 0
                ? Object.keys(product.data.systemRequirements)
                    .filter((cur) => cur !== 'system' && cur !== 'requirement')
                    .map((el, index) =>
                      index !== 0
                        ? ', ' + formatFilterTitle(el)
                        : formatFilterTitle(el)
                    )
                : '-'}
            </span>
          </p>
        </div>
        {hasNoRequirements() ? null : (
          <CheckTextButton
            style={
              hasNoRequirements()
                ? { color: theme.palette.text.disabled, cursor: 'default' }
                : {}
            }
            onClick={scrollToSystemReq}
          >
            {system_requirements_msg}
          </CheckTextButton>
        )}
      </ImportantDetailCardInfo>
    </ImportantDetailCard>
  );
};

export default memo(OSDetail);
