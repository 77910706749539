import DOMPurify from 'isomorphic-dompurify';
import { memo } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';
import { MaskIcon } from '../Icons/Icons';

interface Props {
  notice: string;
}

const ProductImportantNoticeBanner = ({ notice }: Props) => {
  return (
    <Wrapper>
      <MaskIcon
        url="/icons/i-tooltip.svg"
        color={COLORS.orange}
        selected={true}
        height="20px"
        width="20px"
        style={{ minWidth: '20px' }}
        margin="0 10px 0 0px"
      />
      <WrapperChild
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            '<span> <b>Important notice: </b></span>' + notice
          ),
        }}
      />
    </Wrapper>
  );
};

export default memo(ProductImportantNoticeBanner);

const Wrapper = styled.div`
  padding: 18px;
  background: ${(props) => props.theme.palette.background.bg1};
  color: #ff8d07 !important;
  font: 0.875rem/1.125rem Onest-Medium;
  margin: auto;
  width: 100%;
  margin-bottom: 16px;
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  box-shadow: ${(props) => props.theme.shadows.s1};
  border: 1px solid ${(props) => props.theme.palette.border.b1};
  display: flex;
  align-items: center;
  & > div {
    & > p {
      display: inline;
    }
  }

  @media (max-width: ${WIDTH.laptopMin}) {
    margin: 0 16px 12px 16px;
    max-width: calc(100% - 32px);
  }

  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 12px 14px;
    font: 0.875rem/1rem Onest-Medium;
    margin: 0 16px 20px 16px;
    // width: fit-content;
    align-items: start;
  }
`;

const WrapperChild = styled.div`
  color: #ff8d07 !important;
  & p > span {
    color: #ff8d07 !important;
    background-color: transparent !important;
  }
`;
