import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

interface Props {
  message: string;
  showFull: boolean;
}

export const Title = styled.h1<Props>`
  margin: 0;
  text-overflow: ellipsis;
  font: 1.25rem/1.75rem Onest-SemiBold;
  overflow: hidden;
  margin-bottom: 12px;
  color: ${(props) => props.theme.palette.text.t1};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
  ${(props) => (props.showFull ? 'display: block;' : '')}

  @media (max-width: ${WIDTH.tabletMax}) {
    font: 1rem/1.25rem Onest-SemiBold;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 0.875rem/1.125rem Onest-SemiBold;

    margin-bottom: 4px;
    -webkit-line-clamp: 10;
  }
`;

export const ProductTitleContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  height: fit-content;
  & > h1 {
    flex-grow: 1;
    gap: 16px;
  }
`;

export const ProductPillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-top: 8px;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`;

export const ProductPills = styled.div`
  background: #f2f2f2;
  border: none;
  border-radius: 16px;
  padding: 8px 12px;
  color: ${COLORS.greyDark};
  font-size: 14px;
  line-height: 14px;
  font-family: Onest-Medium;
  width: fit-content;
  margin-right: 10px;
  max-height: 30px;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  transition: 0.3s;
  &:hover {
    background: ${COLORS.grey};
  }
  &:active {
    transform: scale(0.95);
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 14px;
    line-height: 14px;
    font-family: Onest-Regular;
    height: 30px;
    min-width: fit-content;
  }
`;

export const ProductTypeDescription = styled.div`
  background: ${COLORS.greyLight2};
  padding: 4px;
  padding-right: 16px;
  margin-bottom: 16px;
  margin-top: 6px;
  border-radius: 8px;
  border: none;
  color: ${COLORS.textPrimary};
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const IconContainer = styled.div`
  background: ${COLORS.white};
  border-radius: 8px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

export const ProductType = styled.p`
  color: ${COLORS.textPrimary};
  font: Onest-Bold;
  font-size: 16px;
  line-height: 19px;
`;

interface WishlistProps {
  wishlisted: boolean;
  wishlistLoading?: boolean;
}

export const LikeButtonContainer = styled.button<WishlistProps>`
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;

  background: ${(props) =>
    props.wishlisted
      ? `${props.theme.mode === 'dark' ? '#732525' : COLORS.red2}`
      : props.theme.palette.background.bg1};
  display: flex;
  place-content: center;
  place-items: center;
  transition: 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
`;

export const PreRelease = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  & p {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${(props) => props.theme.palette.text.t3};
    & > span {
      color: ${(props) => props.theme.palette.warning.main};
      font-family: Onest-SemiBold;
    }
  }

  @media (max-width: ${WIDTH.mobileMax}) {
    gap: 4px;
    & p {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }
  @media (max-width: 470px) {
  }
`;

export const Header2Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
    margin-top: 6px;
  }
`;

export const VerticalDivider = styled.div`
  height: 20px;
  width: 1px;
  background: ${(props) => props.theme.palette.divider.d1};
`;
