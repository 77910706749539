import { memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackComponent from '../ErrorFallbackComponent/ErrorFallbackComponent';
import ProductTitle from '../ProductTitle/ProductTitle';
import { CardContainer, CardHead } from './ProductDescriptionCardStyle';

interface Props {
  type: string;
}

const ProductDescriptionCard = (props: Props) => {
  return (
    <CardContainer>
      <ErrorBoundary
        FallbackComponent={ErrorFallbackComponent}
        // onError={(error, errorInfo) => errorService.log({ error, errorInfo })}
        onReset={() => {
          // reset the state of your app
        }}
      >
        <CardHead>
          <ProductTitle />
        </CardHead>
      </ErrorBoundary>
    </CardContainer>
  );
};

export default memo(ProductDescriptionCard);
