import { useMemo } from 'react';
import { ProductOfferInterface } from '../../Interface/ProductDetailsInterface';
import { getRoundValue } from '../../utils/getRoundValue';

interface Props {
  offer: ProductOfferInterface | null;
  mrp?: number;
}

function useSubscriptionDiscount({ offer, mrp }: Props) {
  const subscriptionDiscount = useMemo(() => {
    let result = {
      plusDiscount: offer?.plusDiscount ?? 0,
      plusPrice: 0,
      plusSaving: 0,
      plusExtraSaving: 0,
      totalPlusDiscount: 0,
    };

    if (offer && Number(offer.plusDiscount ?? 0) > 0 && Number(offer.price)) {
      result.plusPrice = getRoundValue(
        Number(offer.price) -
          Number(offer.price * Number(offer.plusDiscount ?? 0))
      );

      result.plusSaving = getRoundValue(Number(mrp) - result.plusPrice);

      result.totalPlusDiscount = Number(
        getRoundValue(
          ((Number(mrp) - Number(result.plusPrice)) * 100) / Number(mrp)
        ).toFixed(0)
      );

      result.totalPlusDiscount = getRoundValue(
        Math.min(Math.max(result.totalPlusDiscount, 1), 99)
      ); // Ensure totalPlusDiscount is between 1% and 99%

      if (Number(offer.price) > 0) {
        result.plusExtraSaving = getRoundValue(
          Number(offer.price) - result.plusPrice
        );
        return result;
      }
    } else {
      result.plusPrice = offer?.price ?? 0;
      result.plusSaving = 0;
      result.plusExtraSaving = 0;
      result.totalPlusDiscount = 0;
    }

    return result;
  }, [mrp, offer]);

  return { subscriptionDiscount };
}

export default useSubscriptionDiscount;
