import { memo, useState } from 'react';
import { useLocale } from '../../contexts/LocalizationContext';
import { MaskIcon } from '../Icons/Icons';
import Button from '../NewButton/Button';

import dynamic from 'next/dynamic';
import {
  NewsletterPdpContainer,
  NewsLetterPdpText,
  NewsletterPdpTextContainer,
  NewsletterPdpWrapper,
} from './NewsletterPdpStyles';

const NewsletterPdpPopUp = dynamic(() => import('./NewsletterPdpPopUp'), {
  ssr: false,
});

function NewsletterPdp() {
  const [open, setOpen] = useState<boolean>(false);
  let { messages } = useLocale();
  const { get_steal_deals_delivered_to_msg, subscribe_now_msg } =
    messages || {};

  return (
    <>
      <NewsletterPdpContainer>
        <NewsletterPdpWrapper>
          <div>
            <MaskIcon
              url="/icons/sell-tag.svg"
              width={'20px'}
              height="20px"
              color={'#4885FF'}
              selected={true}
              margin="0"
            />
          </div>

          <NewsletterPdpTextContainer>
            <NewsLetterPdpText>
              <p>{get_steal_deals_delivered_to_msg}</p>
            </NewsLetterPdpText>
            <Button
              aria-label={subscribe_now_msg}
              buttonTheme="grey"
              width="fit-content"
              height="fit-content"
              onClick={(e) => {
                e.stopPropagation();
                setOpen(true);
              }}
              style={{
                padding: '6px 10px',
                borderRadius: '20px',
                font: '.75rem/1rem Onest-SemiBold',
              }}
            >
              {subscribe_now_msg}
            </Button>
          </NewsletterPdpTextContainer>
        </NewsletterPdpWrapper>
      </NewsletterPdpContainer>
      {open && <NewsletterPdpPopUp open={open} setOpen={setOpen} />}
    </>
  );
}

export default memo(NewsletterPdp);
