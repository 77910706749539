import ProductDescriptionCard from '../ProductDescriptionCard/ProductDescriptionCard';
import ProductImage from '../ProductImage/ProductImage';
import {
  DescriptionContentContainer,
  DescriptionHeadWrapper,
} from './ProductDescriptionHeadStyle';

import { memo } from 'react';
import { usePdpContext } from '../../contexts/PdpPageContext';
import PrimarySellerCardV3 from '../PrimarySellerCardV3/PrimarySellerCardV3';
import { DescriptionSellerContainer } from '../ProductDescriptionCard/ProductDescriptionCardStyle';
import ProductImportantDetailsV2 from '../ProductImportantDetails/ProductImportantDetailsV2';
import SpecialInfoCardsPdp from '../SpecialInfoCardsPdp/SpecialInfoCardsPdp';

interface Props {
  productImageModal: boolean;
  setProductImageModal: (x: boolean) => void;
}

const ProductDescriptionHead = ({ ...props }: Props) => {
  const { product } = usePdpContext();
  return (
    <>
      <DescriptionHeadWrapper>
        {/* <GWWebWrapper>
          <DescriptionHeadContainer>
            <ProductDescriptionCard type="PDP" />
          </DescriptionHeadContainer>
        </GWWebWrapper> */}
        <DescriptionContentContainer>
          <ProductImage
            image={product.data.coverImage || product.data.coverImageOriginal}
            alt={product.data.name}
            productImageModal={props.productImageModal}
            setProductImageModal={props.setProductImageModal}
          />
          {/* <GWMobileWrapper> */}
          <ProductDescriptionCard type="PDP" />
          {/* </GWMobileWrapper> */}
          {/* <GWWebWrapper>
            <ProductImportantDetails />
          </GWWebWrapper> */}
        </DescriptionContentContainer>
      </DescriptionHeadWrapper>
      <DescriptionSellerContainer>
        <PrimarySellerCardV3 />
      </DescriptionSellerContainer>

      {/* <GWMobileWrapper> */}
      <div style={{ padding: '4px 16px 0', margin: '0 0 16px' }}>
        <ProductImportantDetailsV2 />
        <div style={{ margin: '16px 0 0' }} />
        <SpecialInfoCardsPdp />
      </div>
      {/* </GWMobileWrapper> */}
    </>
  );
};

export default memo(ProductDescriptionHead);
