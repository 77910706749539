import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
  background: ${(props) => props.theme.palette.background.bg1};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.border.b1};
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;

  > div {
    border-right: 1px solid ${(props) => props.theme.palette.border.b1};
    :last-child {
      border-right: none;
    }
  }
`;

export const SpecialInfoCard = styled.div`
  display: flex;
  cursor: pointer;
  background: ${(props) => props.theme.palette.background.bg1};

  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 12px;
  min-height: 56px;
  height: 100%;
  @media (max-width: ${WIDTH.tabletMax}) {
    min-height: 32px;
  }
`;

export const SpecialInfoCardTitle = styled.div`
  display: flex;
  font: 0.75rem/1rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t2};
  white-space: wrap;
`;

export const SpecialInfoCardIcon = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

export const SpecialInfoCardTooltip = styled.div`
  display: flex;
  padding: 8px 4px;
  text-align: center;
  font: 0.875rem/1.125rem Onest-Medium;
`;

export const DividerLine = styled.div`
  width: 1px;
  height: 100%;
  background: ${(props) => props.theme.palette.border.b1};
`;
