import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo } from 'react';
import { useLocale } from '../../../contexts/LocalizationContext';
import { usePdpContext } from '../../../contexts/PdpPageContext';
import { saveEventV3 } from '../../../utils/eventTracking';
import { ellipseString } from '../../../utils/sellerStatsUtils';
import { BackgroundIcon, MaskIcon } from '../../Icons/Icons';
import SellerDetailsCardV3 from '../../SellerDetailsCardV3/SellerDetailsCardV3';
import TooltipWithArrow from '../../ToolTipWithArrow/TooltipWithArrow';
import {
  SellerInfo,
  SellerNameComponent,
  SellerRatingComponent,
} from '../PrimarySellerCardv3Styles';

function SellerInfoDetails() {
  const router = useRouter();
  const { messages } = useLocale();
  const { sold_by_msg } = messages || {};

  const {
    product,
    valuesToBeUsed,
    isWishlisted,
    primarySeller: seller,
    handleCartAction,
  } = usePdpContext();

  return (
    seller && (
      <SellerInfo>
        <SellerNameComponent>
          <p style={{ display: 'flex', alignItems: 'center' }}>
            {sold_by_msg}{' '}
            {/* Ref 1 means to show virtual store details in vendor page */}
            <Link
              href={`/vendor/${seller.sellerId}${
                seller.isFeatured ? `?ref=1` : ''
              }`}
              passHref
              prefetch={false}
            >
              <a>
                <TooltipWithArrow
                  title={<SellerDetailsCardV3 offerDetails={seller} />}
                  noPadding
                  showArrow={false}
                  placement="right-start"
                >
                  <span
                    onClick={() => {
                      saveEventV3({
                        category: 'primary_seller_box',
                        action: 'click',
                        label: 'seller_name',
                        properties: seller.storeName ?? '',
                        value: [seller.sellerId.toString()],
                        from: router,
                      });
                    }}
                    className="mx-1"
                  >
                    {seller.storeName
                      ? ellipseString(seller.storeName, 15)
                      : '-'}
                  </span>
                </TooltipWithArrow>
              </a>
            </Link>
            <BackgroundIcon
              url={`/icons/${
                seller?.verificationStatus === 1 ? 'verified' : 'unverified'
              }.svg`}
              width="18px"
              height="18px"
              margin="0"
            />
          </p>
        </SellerNameComponent>

        {!!seller.sellerRatings && (
          <SellerRatingComponent>
            <MaskIcon
              width={'16px'}
              height={'16px'}
              color={'linear-gradient(95.83deg, #FFCC00 3.89%, #DD8600 97.43%)'}
              url="/icons/yellow-star.svg"
              selected={true}
              margin="0"
            />
            <p>{Number(seller.sellerRatings).toFixed(1)}</p>
          </SellerRatingComponent>
        )}
      </SellerInfo>
    )
  );
}

export default memo(SellerInfoDetails);
