import styled from 'styled-components';

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: ${(props) => props.theme.palette.background.bg1};
  border: 1px solid ${(props) => props.theme.palette.border.b1};
  > div {
    border-bottom: 1px solid ${(props) => props.theme.palette.border.b1};
    :last-child {
      border-bottom: none;
    }
  }
`;

export const DetailWrapperRow = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
`;

export const DetailTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  > h6 {
    font: 0.75rem/1rem Onest-Medium;
    color: ${(props) => props.theme.palette.text.t3};
  }
`;

export const DetailTitleValue = styled.div`
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
`;
