import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const NewPdpVariationsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

export const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.palette.border.b1};
`;

export const SectionHeading = styled.div`
  width: fit-content;
  font: 0.875rem/1.25rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t2};
  text-transform: capitalize;
`;

export const AllVariationText = styled.div`
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.primary};
  text-wrap: nowrap;
  cursor: pointer;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SectionBodyContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const VariationBoxWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
  grid-gap: 16px;
  justify-content: start;
`;

export const ShowAllButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  button {
    height: 40px;
    font: 0.875rem/1.125rem Onest-SemiBold;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    > button {
      width: 100%;
      @media (max-width: ${WIDTH.mobileMax}) {
        height: 32px;
        font: 0.75rem/1rem Onest-SemiBold;
      }
    }
  }
`;

export const VariationBoxContainer = styled.div<{ selected: boolean }>`
  border: ${(props) =>
    props.selected
      ? '2px solid' + props.theme.palette.primary.main
      : '1px solid' + props.theme.palette.border.b1};
  overflow: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  min-width: 172px;
  width: 100%;
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.background.bg1};
  position: relative;
`;

export const VariationBoxContainerBgShade = styled.div`
  display: flex;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? 'linear-gradient(90deg, rgba(12, 12, 12, 0) 0%, #0c0c0c 100%)'
      : 'transparent'};
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const VariationBoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.background.bg3};
  gap: 16px;
  padding: 10px;
  width: 100%;
  height: 40px;
  width: 100%;
`;

export const VariationBoxInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
`;

export const VariationBoxTitle = styled.p`
  flex: 1;
  margin: 0;
  padding: 0;
  width: 100%;
  font: 0.875rem/1.25rem Onest-Semibold;
  color: ${(props) => props.theme.palette.text.t1};
  text-wrap: nowrap;
`;

export const VariationBoxInput = styled.input`
  height: 20px;
  width: 20px;
  appearance: none;
  border: 2px solid
    ${(props) => (props.theme.mode === 'dark' ? '#444444' : '#d9d9d9')};
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;

  &:checked {
    border-color: ${(props) => props.theme.palette.primary.main};
  }

  &:before {
    content: '';
    height: 12px; /* Adjust the size for the inner white circle */
    width: 12px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.checked ? props.theme.palette.primary.main : 'transparent'};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.2s ease-in-out;
  }
  &:checked:before {
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const VariationBoxBody = styled.div`
  min-height: 40px;
  background-color: ${(props) => props.theme.palette.background.bg1};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

export const VariationBodyPrice = styled.div`
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
  span {
    font: 0.75rem/1rem Onest-Medium;
    color: ${(props) => props.theme.palette.text.t3};
  }
`;

export const NewPdpVariationsMobileBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  background: ${(props) => props.theme.palette.background.bg1};
  border: 1px solid ${(props) => props.theme.palette.border.b1};
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const NewPdpVariationValue = styled.div`
  display: flex;
  border-radius: 20px;
  width: 100%;
  padding: 10px 16px;
  justify-content: space-between;
  height: 42px;
  border: 1px solid ${(props) => props.theme.palette.border.b2};
  p {
    font: 0.875rem/1.25rem Onest-Medium;
    color: ${(props) => props.theme.palette.text.t1};
    margin: 0;
    padding: 0;
  }
`;

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  > p {
    font: 1rem/1.25rem Onest-SemiBold;
    color: ${(props) => props.theme.palette.text.t1};
    margin: 0;
    padding: 0;
  }
  border-bottom: 1px solid ${(props) => props.theme.palette.border.b1};
`;

export const DrawerBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 80vh;
`;

export const DrawerActions = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  width: 100%;
`;

export const MobileVariationListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.palette.border.b1};
`;

export const MobileVariationListTitle = styled.div`
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const MobileVariationListAction = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const MobileVariationListActionPrice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-end;
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
  span {
    font: 0.675rem/0.675rem Onest-Medium;
    color: ${(props) => props.theme.palette.text.t3};
  }
`;
