import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const MainWrapper = styled.div``;

export const BodyContainer = styled.div`
  padding: 0 32px;
  max-width: 1000px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 32px;
  ol {
    list-style-type: auto;
    li {
      margin: 8px 0;
    }
  }
  ul {
    list-style-type: disc;
    li {
      margin: 6px 0;
    }
  }
  // strong {
  //   color: ${(props) => props.theme.palette.text.t1};
  // }
  p {
    margin: 0 0 6px;
  }
  color: ${(props) => props.theme.palette.text.t2};

  table,
  th,
  td {
    border: 1px solid ${(props) => props.theme.palette.border.b2};
  }
  td {
    padding: 8px;
  }

  @media (min-width: 1440px) {
    margin: auto;
    margin-bottom: 64px;
  }
  min-height: 70vh;
`;

export const BodyContainerDescription = styled.div`
  padding: 0 16px;
  overflow: hidden;
  margin: 32px 0px 64px 0px;
  padding: 16px;
  border-radius: 8px;
  a {
    color: ${(props) => props.theme.palette.primary.main};
  }
  color: ${(props) => props.theme.palette.text.t2};
  background: ${(props) => props.theme.palette.background.bg1};
  @media (min-width: 1440px) {
    margin: auto;
    margin: 32px 0px 64px 0px;
    padding: 24px;
    width: 100%;
  }
  min-height: 70vh;
`;

export const BodyTopContainerDescription = styled.div`
  padding: 0 16px;
  overflow: hidden;
  margin: 16px 0px 16px 0px;
  padding: 16px;
  border-radius: 8px;
  a {
    color: ${(props) => props.theme.palette.primary.main};
  }
  color: ${(props) => props.theme.palette.text.t2};
  background: ${(props) => props.theme.palette.background.bg1};
  @media (min-width: 1440px) {
    margin: auto;
    padding: 24px;
    width: 100%;
  }
  min-height: 70vh;
`;

export const PageWrapper = styled.div`
  article {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;

    a {
      color: ${(props) => props.theme.palette.text.primary};
    }

    h3 {
      font: 1.75rem/2rem Onest-Bold;
      color: ${(props) => props.theme.palette.text.t1};
      margin: 16px 0 0 0;
      @media (max-width: ${WIDTH.tabletMax}) {
        font: 1.25rem/1.5rem Onest-Bold;
      }
    }
    > ul > li {
      background: ${(props) => props.theme.palette.background.bg1};
      padding: 16px 16px;
      border-radius: 8px;
      box-shadow: ${(props) => props.theme.shadows.s4};
      margin: 16px 0 0 0;
    }
    > ul > li > p {
      font: 1.25rem/1.5rem Onest-SemiBold;
      color: ${(props) => props.theme.palette.text.t1};
      @media (max-width: ${WIDTH.tabletMax}) {
        font: 1rem/1.25rem Onest-Bold;
      }
    }
    > ul > li > ul {
      display: none;
      transition: all 200ms ease;
      padding: 0 16px 0 0;
    }
    > ul > li > ul.active {
      display: block;
      height: fit-content;
      padding: 8px 16px 16px 32px;
      border-radius: 8px;
      background: ${(props) => props.theme.palette.background.bg3};
      opacity: 1;
      display: block;
      margin: 16px 0 0;
    }
    > ul > li > ul > li {
      list-style: initial !important;
      font: 1rem/1.25rem Onest-Medium;
      color: ${(props) => props.theme.palette.text.t2};
      padding: 8px 0 0;
      @media (max-width: ${WIDTH.tabletMax}) {
        font: 0.875rem/1.125rem Onest-Medium;
      }
    }

    > ul > li > ul > li > ul > li {
      list-style: initial !important;
      font: 1rem/1.25rem Onest-Medium;
      color: ${(props) => props.theme.palette.text.t2};
      margin: 8px 0 0 16px;
      @media (max-width: ${WIDTH.tabletMax}) {
        font: 0.875rem/1.125rem Onest-Medium;
      }
    }
  }
`;

export const PageWrapperFAQ = styled.div`
  article {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;

    a {
      color: ${(props) => props.theme.palette.text.primary};
    }

    h3 {
      font: 1.75rem/2rem Onest-Bold;
      color: ${(props) => props.theme.palette.text.t1};
      margin: 16px 0 0 0;
      @media (max-width: ${WIDTH.tabletMax}) {
        font: 1.25rem/1.5rem Onest-Bold;
      }
    }
    > ul > li:not([class]) {
      background: ${(props) => props.theme.palette.background.bg1};
      padding: 16px 16px;
      border-radius: 8px;
      box-shadow: ${(props) => props.theme.shadows.s4};
      margin: 16px 0 0 0;
    }
    > ul > li:not([class]) > p {
      font: 1.25rem/1.5rem Onest-SemiBold;
      color: ${(props) => props.theme.palette.text.t1};
      @media (max-width: ${WIDTH.tabletMax}) {
        font: 1rem/1.25rem Onest-Bold;
      }
    }

    > ul > ul > li.ql-indent-1 {
      display: list-item;
      transition: all 200ms ease;
      height: fit-content;
      border-radius: 8px;
      background: ${(props) => props.theme.palette.background.bg3};
      opacity: 1;
      padding: 3px 0px 3px 12px;
      margin-left: 24px;
    }

    ul > ul {
      transition: all 200ms ease;
      list-style: initial;
      border-radius: 12px;
      background: ${(props) => props.theme.palette.background.bg3};
      margin-top: 8px;
      padding: 8px;
    }

    > ul > ul > li.ql-indent-2 {
      display: block;
      display: list-item;
      transition: all 200ms ease;
      height: fit-content;
      border-radius: 8px;
      background: ${(props) => props.theme.palette.background.bg3};
      opacity: 1;
      padding: 3px 0px 3px 12px;
      margin: 0px 0px 0px 52px;
    }

    > ul > li > ul > li {
      list-style: initial !important;
      font: 1rem/1.25rem Onest-Medium;
      color: ${(props) => props.theme.palette.text.t2};
      padding: 8px 0 0;
      @media (max-width: ${WIDTH.tabletMax}) {
        font: 0.875rem/1.125rem Onest-Medium;
      }
    }

    > ul > li > ul > li > ul > li {
      list-style: initial !important;
      font: 1rem/1.25rem Onest-Medium;
      color: ${(props) => props.theme.palette.text.t2};
      margin: 8px 0 0 16px;
      @media (max-width: ${WIDTH.tabletMax}) {
        font: 0.875rem/1.125rem Onest-Medium;
      }
    }
  }
`;
export const H1Title = styled.h1`
  font: normal normal 32px/36px Onest-Bold;
  margin: 20px 0;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const H2Title = styled.h2`
  font: normal normal 24px/30px Onest-Bold;
  margin: 15px 0;
  color: ${(props) => props.theme.palette.text.t1};
  text-transform: capitalize;
`;

export const H3Title = styled.h3`
  font: normal normal 0.875rem/1.375rem Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  margin: 10px 0;
`;

export const H4Title = styled.h4`
  font: normal normal 16px/20px Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  margin: 8px 0;
`;

export const H5Title = styled.h5`
  font: normal normal 13.28px/18px Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  margin: 5px 0;
`;
